<template>
  <div>
    <v-dialog :value="open" @keydown.esc="close" max-width="600px" persistent>
      <v-card v-if="onTop">

        <v-card-title>
          <span>{{ $t(title) }}</span>
        </v-card-title>

        <v-divider class="mb-4"/>

        <v-card-text>
          <t-form
              v-if="input"
              :input-fields="inputFields"
              ref="form"
              :form-data="inputData"
          />

          <div
              class="d-flex flex-column align-center"
              v-if="!input"
              style="min-height: 175px"
          >
            <h3
                v-if="processTitle"
                v-text="processTitle"
                class="mb-4"
            />

            <div style="min-height: 150px">
              <v-progress-circular
                  v-if="!icon"
                  :size="125"
                  :width="2"
                  indeterminate
                  color="primary"
                  class="mb-4"
              />
              <t-icon
                  v-if="icon"
                  :image="icon.image"
                  :color="icon.color"
                  size="150"
              />
            </div>

            <div class="mt-4">{{ message }}</div>
          </div>
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>

          <div v-show="input">
            <t-button
                @click="handleStartClick"
                v-text="$t(startButtonLabel)"
                primary
                class="ml-2"
            />
            <t-button
                @click="close"
                v-text="$t('label.close')"
                class="ml-2"
            />
          </div>

          <div v-show="!input">
            <t-button
                v-for="action in actions"
                :key="action.label"
                @click="handleActionClick(action)"
                v-text="$t(action.label)"
                :disabled="actionRunning"
                class="ml-2"
            />

            <t-button
                v-show="error"
                @click="handleRetryClick"
                v-text="$t('label.retry')"
                :disabled="actionRunning"
                class="ml-2"
            />

            <t-button
                v-show="success || error || !cancelHandler"
                @click="close"
                v-text="$t('label.close')"
                primary
                :disabled="actionRunning"
                class="ml-2"
            />

            <t-button
                v-show="(pending || action) && cancelHandler"
                @click="handleCancelClick"
                v-text="$t('label.cancel')"
                error
                :disabled="actionRunning"
                class="ml-2"
            />
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dialog from '../../mixins/dialog'

export default {
  mixins: [
    dialog
  ],

  props: {
    title: {
      type: String,
      required: true
    },
    processTitle: {
      type: String
    },
    statusCode: {
      type: String
    },
    statuses: {
      type: Array,
      required: true
    },
    startHandler: {
      type: Function
    },
    cancelHandler: {
      type: Function
    },
    successHandler: {
      type: Function
    },
    inputFields: {
      type: Array,
      default: () => []
    },
    defaultInputData: {
      type: Object
    },
    startButtonLabel: {
      type: String,
      default: 'label.confirm'
    }
  },

  data () {
    return {
      buttonsDisabled: false,
      inputData: null
    }
  },

  computed: {
    input () {
      return !this.statusCode
    },

    success () {
      const status = this.statuses.find(status => status.code === this.statusCode)
      return status && status.success
    },

    error () {
      const status = this.statuses.find(status => status.code === this.statusCode)
      return status && status.error
    },

    action () {
      const status = this.statuses.find(status => status.code === this.statusCode)
      return status && status.action
    },

    pending () {
      return !this.success && !this.error && !this.action && !this.input
    },

    message () {
      const status = this.statuses.find(status => status.code === this.statusCode)
      if (status && status.message) {
        return this.$t(status.message)
      }
      if (status && this.statusCode) {
        return this.$t('status.' + this.statusCode.toLowerCase())
      }
      return ''
    },

    actions () {
      const status = this.statuses.find(status => status.code === this.statusCode)
      return status ? status.actions : []
    },

    icon () {
      const status = this.statuses.find(status => status.code === this.statusCode)

      if (status.icon) {
        return status.icon
      }

      if (status.image) {
        const statusImage = this.$items.extractItem(status.image)
        if (typeof statusImage === 'string') {
          return {
            image: statusImage,
            color: 'primary'
          }
        }
        return statusImage
      }

      if (this.success) {
        return {
          image: 'mdi-checkbox-marked-circle',
          color: 'success'
        }
      }
      if (this.error) {
        return {
          image: 'mdi-close-circle',
          color: 'error'
        }
      }
      if (this.action) {
        return {
          image: 'mdi-account',
          color: 'primary'
        }
      }
      return null
    }
  },

  watch: {
    defaultInputData () {
      this.inputData = this.defaultInputData
    },

    success () {
      if (this.success && this.successHandler) {
        this.successHandler()
      }
    }
  },

  methods: {
    onOpen () {
      this.$super(dialog).onOpen()
      this.inputData = this.defaultInputData
    },

    async handleStartClick () {
      if (this.input) {
        const validForm = await this.$refs.form.validate()
        if (!validForm) {
          return
        } else {
          this.inputData = this.$refs.form.getEditFormData()
        }
      }

      await this.runAction(() => this.startHandler(this.inputData))
    },

    handleCancelClick () {
      this.runAction(() => this.cancelHandler())
    },

    handleActionClick (action) {
      this.runAction(() => action.handler())
    },

    handleRetryClick () {
      this.runAction(() => this.startHandler(this.inputData))
    }
  }
}
</script>
