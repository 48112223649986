<template>
  <t-edit-dialog
    id="card"
    :input-fields="[
          {
            key: 'id',
            type: 'uuid',
            label: 'label.cardNumber',
            readOnly: true
          },
          {
            key: 'userId',
            type: 'auto-complete-select',
            label: 'label.user',
            items: userSearch.items,
            itemKey: 'id',
            itemText: 'fullName',
            itemFilteringHandler: userSearch.search,
            itemByValueHandler: userSearch.findById
          },
          {
            key: 'disabled',
            type: 'switch',
            label: 'label.disabled',
            required: true
          }
        ]"
    :item="card"
    :open-handler="onOpen"
    :save-handler="card.save"
    :uuid-item="true"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useCard from '@/app/composables/card/useCard'
import useUserSearch from '@/app/composables/user/useUserSearch'

export default {
  data () {
    return {
      card: useCard(),
      userSearch: useUserSearch()
    }
  },

  created () {
    useEvent().registerListener('card-dialog', 'card-save', () => this.$dialog.closeDialog('card'))
  },

  destroyed () {
    useEvent().unregisterListener('card-dialog')
  },

  methods: {
    onOpen () {
      this.userSearch = useUserSearch()
      this.card.setData(this.$dialog.getDialogData('card'))
    }
  }
}
</script>
