<template>
  <v-text-field
    :label="label"
    :value="internalValue"
    @input="handleOnInput"
    @blur="handleOnBlur"
    @keypress.enter="onEnter($event)"
    ref="textField"
    :disabled="disabled"
    autocomplete="off"
    :error-messages="errorMessages"
    :autofocus="autofocus"
  />
</template>

<script>
import inputField from '../../mixins/inputField'

export default {
  mixins: [
    inputField
  ],

  methods: {
    correctValue (value) {
      if (!value) {
        return value
      }

      let correctedValue = `${value}`.replace(/[^0-9]/g, '')
      if (correctedValue !== '0') {
        correctedValue = correctedValue.replace(/\b0+/g, '')
      }
      return correctedValue ? Number(correctedValue).toString() : null
    },

    handleOnInput (value) {
      this.internalValue = value
      this.emitValue()
    },

    handleOnBlur () {
      this.emitValue() // for backwards compatibility
      this.emitValue('blur')
    },

    onEnter (event) {
      this.internalValue = event.target.value
      this.emitValue()
      this.$emit('keypress', event)
    },

    emitValue (eventName = 'input') {
      const correctedValue = this.correctValue(this.internalValue)
      this.$emit(eventName, correctedValue)
      this.$nextTick(() => {
        this.$refs.textField.lazyValue = correctedValue
      })
    }
  }
}
</script>
