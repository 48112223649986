<template>
  <v-switch
      :disabled="disabled"
      :label="$t(switchLabel)"
      :input-value="internalValue"
      @click.stop="handleOnClick"
      :error-messages="errorMessages"
  />
</template>

<script>
import inputField from '../../mixins/inputField'

export default {
  mixins: [
    inputField
  ],

  props: {
    offLabel: {
      type: String
    }
  },

  computed: {
    switchLabel () {
      if (this.offLabel && !this.internalValue) {
        return this.offLabel
      }
      return this.label
    }
  },

  methods: {
    initInternalValue () {
      this.internalValue = !!this.value
      if (typeof this.value !== 'boolean') {
        this.emitValue()
      }
    },

    handleOnClick () {
      this.internalValue = !this.internalValue
      this.emitValue()
    }
  }
}
</script>
