var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"invoice","action-chips":[
      {
        icon: {
          image: 'mdi-credit-card-outline',
          color: 'primary'
        },
        label: 'label.pay',
        hideHandler: () => _vm.invoice.paid,
        handler: () => _vm.$dialog.openDialog('invoice-payment', _vm.invoice),
      },
      {
        icon: 'mdi-file-download-outline',
        label: 'label.downloadDocument',
        handler: _vm.invoice.downloadDocument
      }
    ],"input-fields":[
      {
        key: 'description',
        type: 'text'
      },
      {
        key: 'date',
        type: 'date'
      },
      {
        key: 'number',
        type: 'text'
      },
      {
        key: 'lines',
        type: 'list',
        uuidItem: true,
        itemKey: 'id',
        inputFields: [
          {
            key: 'description',
            type: 'text'
          },
          {
            key: 'price',
            type: 'amount'
          },
          {
            key: 'quantity',
            type: 'number'
          },
          {
            key: 'amountExcludingVat',
            type: 'amount'
          },
          {
            key: 'amountIncludingVat',
            type: 'amount'
          }
        ],
        itemText: line => `${line.description} (€${line.price}) X ${line.quantity}`
      },
      {
        key: 'amountExcludingVat',
        type: 'amount'
      },
      {
        key: 'amountIncludingVat',
        type: 'amount'
      }
    ],"item":_vm.invoice,"uuid-item":true,"open-handler":_vm.handleOnOpen,"read-only-for-edit":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }