<template>
  <v-app v-if="$authorization.user.authenticated">
    <div class="fill-height" v-if="!$authorization.user.authorized && !hide && !loading">
      <v-flex class="fill-height d-flex justify-center align-center">
        <v-card class="d-flex flex-column justify-center align-center pa-4">
          <h1 class="pa-4">{{ $t('message.unauthorized') }}</h1>
          <v-btn @click="$authorization.logout">{{ $t('label.logout') }}</v-btn>
        </v-card>
      </v-flex>
    </div>

    <div class="fill-height" v-if="$authorization.user.authorized && !hide && !loading">
      <v-navigation-drawer app clipped v-model="drawer">
        <v-list dense>
          <v-list-item
              :key="menuItem.label"
              :to="menuItem.route"
              v-for="menuItem in menuItems"
              :disabled="menuItem.disabled"
              :class="{
                'color-primary': $route.name === menuItem.route
              }"
          >

            <v-list-item-action>
              <v-icon :disabled="menuItem.disabled">{{ menuItem.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <div class="d-flex justify-space-between" >
                  {{ $t(menuItem.label) }}
                  <v-badge
                      v-if="menuItem.badge"
                      :content="menuItem.badge"
                      color="primary"
                      :inline="true"
                  />
                </div>
              </v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app clipped-left>
        <v-app-bar-nav-icon
            class="color-primary"
            @click.stop="drawer = !drawer"
        />

        <v-toolbar-title
            class="color-primary font-weight-bold"
        >
          {{ $t(title) }}
        </v-toolbar-title>

        <div class="flex-grow-1"></div>

        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-avatar @click.prevent color="primary">
                <span class="black--text headline">{{ $authorization.user.initials }}</span>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <strong>{{ $authorization.user.name }}</strong>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="$authorization.account">
              <v-list-item-title>
                <v-icon>mdi-account-circle</v-icon>
                {{ $t('label.profile') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
                v-for="(userMenuItem, index) in userMenuItems"
                :key="index"
                @click="userMenuItem.handler">
              <v-list-item-title>
                <v-icon v-if="userMenuItem.icon">{{ userMenuItem.icon }}</v-icon>
                {{ $t(userMenuItem.label) }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item @click="$authorization.logout">
              <v-list-item-title>
                <v-icon>mdi-logout-variant</v-icon>
                {{ $t('label.logout') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-main class="fill-height">
        <v-container class="fill-height" fluid>
          <router-view class="flex-grow-1"/>
        </v-container>
      </v-main>

      <v-footer app>
        <img class="mr-2" :src="require('../../assets/logo.png')" height="24"/>
        <span>&copy; {{ year }}</span>
      </v-footer>
    </div>

    <v-container
        class="d-flex flex-column align-center justify-center"
        fill-height
        v-if="loading"
    >
      <v-progress-circular
          :size="250"
          :width="3"
          indeterminate color="primary"
      >
        <img :src="require('../../assets/logo_circle.png')" height="250" class="mr-1"/>
      </v-progress-circular>
    </v-container>

    <v-container
        class="d-flex flex-column align-center justify-center"
        fill-height
        v-if="loadingError"
    >
      <span>{{ $t('message.loadingError') }}</span>
      <v-btn @click="handleLoadingErrorRetry">{{ $t('label.retry') }}</v-btn>
    </v-container>

    <t-notification-stack/>
  </v-app>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    menuItems: {
      type: Array,
      default: () => []
    },
    userMenuItems: {
      type: Array,
      default: () => []
    },
    hide: {
      type: Boolean,
      default: false
    },
    loadingHandler: {
      type: Function
    },
    loadingSuccessHandler: {
      type: Function
    }
  },

  data () {
    return {
      drawer: null,
      year: moment().format('YYYY'),
      loading: false,
      loadingError: false
    }
  },

  created () {
    if (this.loadingHandler) {
      const loadingHandlerReturn = this.loadingHandler()
      if (loadingHandlerReturn instanceof Promise) {
        const vm = this
        vm.loading = true
        loadingHandlerReturn.then(success => {
          vm.loading = false
          vm.loadingError = !success
          if (success && vm.loadingSuccessHandler) {
            vm.loadingSuccessHandler()
          }
        })
      }
    }
  },

  methods: {
    handleLoadingErrorRetry () {
      window.location.reload()
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap');

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px #424242 !important;
    background-color: #424242 !important;
  }
  ::-webkit-scrollbar {
    width: 4px !important;
    height: 6px !important;
    background-color: #424242 !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #9E9E9E !important;
  }

  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    -webkit-text-fill-color: #FFFFFF;
    box-shadow: 0 50px #1E1E1E inset;
  }

  div.v-dialog:not(.v-dialog--fullscreen) div.v-sheet,
  div.v-dialog:not(.v-dialog--fullscreen) div.v-card,
  div.v-dialog:not(.v-dialog--fullscreen) div.v-card > div.v-list {
    background-color: #424242 !important;
  }

  .v-application {
    font-family: Ubuntu, sans-serif;
  }

  :root {
    --color-primary: #FFC045;
    --color-primary-transparent-1: rgba(255, 192, 69, 0.8);
    --color-primary-transparent-2: rgba(255, 192, 69, 0.6);
    --color-primary-transparent-3: rgba(255, 192, 69, 0.4);
    --color-primary-transparent-4: rgba(255, 192, 69, 0.2);
    --color-warning: #FB8C00;
    --color-error: #FF5252;
    --color-grey: #9E9E9E;
    --color-grey-lighten-5: #FAFAFA;
    --color-grey-lighten-4: #F5F5F5;
    --color-grey-lighten-3: #EEEEEE;
    --color-grey-lighten-2: #E0E0E0;
    --color-grey-lighten-1: #BDBDBD;
    --color-grey-darken-1: #757575;
    --color-grey-darken-2: #616161;
    --color-grey-darken-3: #424242;
    --color-grey-darken-4: #212121;
    --color-grey-darken-5: #1E1E1E;
    --color-grey-darken-6: #121212;
  }

  .color-grey,
  .color-grey * {
    color: #9E9E9E !important;
  }
  .color-grey-lighten-5 {
    color: #FAFAFA
  }
  .color-grey-lighten-4 {
    color: #F5F5F5
  }
  .color-grey-lighten-3 {
    color: #EEEEEE
  }
  .color-grey-lighten-2 {
    color: #E0E0E0
  }
  .color-grey-lighten-1 {
    color: #BDBDBD
  }
  .color-grey-darken-1,
  .color-grey-darken-1 * {
    color: #757575
  }
  .color-grey-darken-2,
  .color-grey-darken-2 * {
    color: #616161 !important;
  }
  .color-grey-darken-3,
  .color-grey-darken-3 * {
    color: #424242 !important;
  }
  .color-grey-darken-4,
  .color-grey-darken-4 * {
    color: #212121 !important;
  }
  .color-primary,
  .color-primary * {
    color: #FFC045 !important;
  }
  .color-primary-lighten-1,
  .color-primary-lighten-1 * {
    color: #ffc657 !important;
  }
  .color-primary-lighten-2,
  .color-primary-lighten-2 * {
    color: #ffcc6a !important;
  }
  .color-primary-lighten-3,
  .color-primary-lighten-3 * {
    color: #ffd27c !important;
  }
  .color-primary-lighten-4,
  .color-primary-lighten-4 * {
    color: #ffd98f !important;
  }
  .color-primary-lighten-5,
  .color-primary-lighten-5 * {
    color: #ffdfa2 !important;
  }
  .color-primary-lighten-6,
  .color-primary-lighten-6 * {
    color: #fee5b4 !important;
  }
  .color-primary-lighten-7,
  .color-primary-lighten-7 * {
    color: #ffecc7 !important;
  }
  .color-primary-lighten-8,
  .color-primary-lighten-8 * {
    color: #fff2d9 !important;
  }
  .color-primary-lighten-9,
  .color-primary-lighten-9 * {
    color: #fff8ec !important;
  }
  .color-error,
  .color-error * {
    color: #FF5252 !important;
  }
  .fill-primary * {
    fill: #FFC045
  }
  .color-success,
  .color-success * {
    color: #4CAF50 !important;
  }

  .background-color-grey {
    background-color: #9E9E9E
  }
  .background-color-grey-lighten-5 {
    background-color: #FAFAFA
  }
  .background-color-grey-lighten-4 {
    background-color: #F5F5F5
  }
  .background-color-grey-lighten-3 {
    background-color: #EEEEEE
  }
  .background-color-grey-lighten-2 {
    background-color: #E0E0E0
  }
  .background-color-grey-lighten-1 {
    background-color: #BDBDBD
  }
  .background-color-grey-darken-1 {
    background-color: #757575
  }
  .background-color-grey-darken-2 {
    background-color: #616161
  }
  .background-color-grey-darken-3 {
    background-color: #424242
  }
  .background-color-grey-darken-4 {
    background-color: #212121
  }
  .background-color-grey-darken-4 {
    background-color: #121212
  }
  .background-color-primary-dark {
    background-color: #4d422c !important;
  }
  .background-color-error,
  .background-color-error * {
    background-color: #FF5252 !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .draggable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  /* (Optional) Apply a "closed-hand" cursor during drag operation. */
  .draggable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }

  .v-navigation-drawer {
    background-color: var(--color-grey-darken-4) !important;
  }

  .v-navigation-drawer__border {
    background-color: var(--color-grey-darken-4) !important;
  }

  .v-badge__badge.primary {
    color: var(--color-grey-darken-4) !important;
  }
</style>
