var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"item-properties":[
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'amount',
          type: 'amount',
          sortable: true
        },
        {
          header: 'label.assign',
          icon: 'mdi-gift',
          iconClickHandler: rewardDefinition => _vm.$dialog.openDialog('reward', {
            reason: rewardDefinition.name,
            amount: rewardDefinition.amount,
          })
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: rewardDefinition => _vm.$dialog.openDialog('reward-definition', rewardDefinition)
        }
      ],"items":_vm.rewardDefinitionList.fetch,"default-sort-property-key":"name","item-key":"id","no-items-label":"message.noRewardDefinitions"}})
}
var staticRenderFns = []

export { render, staticRenderFns }