<template>
  <t-chips
    :items="productCategoryList.productCategories"
    item-key="id"
    :item-text="category => !category.favorites ? category.name : null"
    :item-icon="category => category.favorites ? 'mdi-heart' : null"
    :enforce-selection="true"
    :selection-handler="category => event.fire('product-category-select', category)"
    :actions="[
          {
            icon: 'mdi-lead-pencil',
            handler: category => $dialog.openDialog('product-category', category),
            visibleHandler: category => !category.favorites
          }
        ]"
    :drag-data-type="category => !category.favorites ? 'category' : null"
    :drop-data-types="category => !category.favorites ? ['category', 'product'] : null"
    :drop-handler="categoryDropHandler"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      event: useEvent()
    }
  },

  props: {
    productCategoryList: {
      required: true
    }
  },

  methods: {
    async categoryDropHandler (sourceItem, targetCategory) {
      if (this.$dragger.dataType === 'category') {
        await sourceItem.move(targetCategory.sequenceNumber)
      }

      if (this.$dragger.dataType === 'product') {
        const editProduct = this.$lodash.cloneDeep(sourceItem)
        editProduct.categoryId = targetCategory.id
        sourceItem.save(editProduct)
      }
    }
  }
}
</script>
