<template>
  <t-dialog
    id="invoicing-settings"
    title="label.invoicingSettings"
    :loader="loader"
    :open-handler="onOpen"
  >
    <v-card-text>
      <invoicing-settings-form ref="invoicingSettings" :settings="settings" />
    </v-card-text>

    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn text color="warning" @click="$dialog.closeDialog('invoicing-settings')">{{ $t('label.cancel') }}</v-btn>
      <v-btn text color="primary" @click="handleSettingsSaveClick">{{ $t('label.confirm') }}</v-btn>
    </v-card-actions>
  </t-dialog>
</template>

<script>
import useTenantSettings from '@/app/composables/tenant/useTenantSettings'
import useLoader from '@/lib/composables/useLoader'

export default {
  data () {
    return {
      loader: useLoader(),
      settings: useTenantSettings()
    }
  },

  methods: {
    async handleSettingsSaveClick () {
      const invoicingSettingsValid = await this.$refs.invoicingSettings.validate()
      if (!invoicingSettingsValid) {
        return
      }

      await this.settings.save({
        generalSettings: this.settings.generalSettings,
        invoicingSettings: this.$refs.invoicingSettings.editFormData,
        onlinePaymentSettings: this.settings.onlinePaymentSettings,
        onlineOrderSettings: this.settings.onlineOrderSettings
      })

      this.$dialog.closeDialog('invoicing-settings')
    },

    async onOpen () {
      await this.loader.load(async () => {
        return this.settings.find()
      })
      this.$refs.invoicingSettings.reset()
    }
  }
}
</script>
