<template>
  <div>
    <t-line-chart
      :labels="[
        'label.monday',
        'label.tuesday',
        'label.wednesday',
        'label.thursday',
        'label.friday',
        'label.saturday',
        'label.sunday',
      ]"
      :values="[
        report.mondaySales,
        report.tuesdaySales,
        report.wednesdaySales,
        report.thursdaySales,
        report.fridaySales,
        report.saturdaySales,
        report.sundaySales,
      ]"
    />

    <t-table
      class="mt-8"
      :items="[
          {
            day: 'monday',
            dayNumber: 1,
            sales: report.mondaySales
          },
          {
            day: 'tuesday',
            dayNumber: 2,
            sales: report.tuesdaySales
          },
          {
            day: 'wednesday',
            dayNumber: 3,
            sales: report.wednesdaySales
          },
          {
            day: 'thursday',
            dayNumber: 4,
            sales: report.thursdaySales
          },
          {
            day: 'friday',
            dayNumber: 5,
            sales: report.fridaySales
          },
          {
            day: 'saturday',
            dayNumber: 6,
            sales: report.saturdaySales
          },
          {
            day: 'sunday',
            dayNumber: 7,
            sales: report.sundaySales
          },
        ]"
      item-key="key"
      :item-properties="[
          {
            key: 'day',
            text: item => $t('label.' + item.day),
            value: item => item.dayNumber,
            sortable: true
          },
          {
            key: 'sales',
            type: 'number',
            header: 'label.count',
            sortable: true
          }
        ]"
      default-sort-property-key="day"
    />

    <t-table
      class="mt-8"
      :items="report.products"
      item-key="productName"
      :item-properties="[
          {
            key: 'productName',
            header: 'label.product',
            sortable: true
          },
          {
            key: 'mondaySales',
            header: 'label.monday',
            type: 'number',
            sortable: true
          },
          {
            key: 'tuesdaySales',
            header: 'label.tuesday',
            type: 'number',
            sortable: true
          },
          {
            key: 'wednesdaySales',
            header: 'label.wednesday',
            type: 'number',
            sortable: true
          },
          {
            key: 'thursdaySales',
            header: 'label.thursday',
            type: 'number',
            sortable: true
          },
          {
            key: 'fridaySales',
            header: 'label.friday',
            type: 'number',
            sortable: true
          },
          {
            key: 'saturdaySales',
            header: 'label.saturday',
            type: 'number',
            sortable: true
          },
          {
            key: 'sundaySales',
            header: 'label.sunday',
            type: 'number',
            sortable: true
          },
        ]"
      default-sort-property-key="productName"
    />
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Object
    }
  }
}
</script>
