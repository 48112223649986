import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useEntity from '@/lib/composables/useEntity'

const tenantSelection = useTenantSelection()

export default function () {
  const rewardDefinitionTemplate = {
    id: null,
    name: null,
    amount: null
  }

  const rewardDefinition = useEntity(rewardDefinitionTemplate)

  rewardDefinition.save = async editRewardDefinition => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/reward-definitions/${editRewardDefinition.id}`, {
      body: {
        name: editRewardDefinition.name,
        amount: editRewardDefinition.amount
      }
    })
    if (await fetch.promise) {
      rewardDefinition.setData(fetch.data)
      useEvent().fire('reward-definition-save', editRewardDefinition)
    }
  }

  rewardDefinition.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/reward-definitions/${rewardDefinition.id}`)
    if (await fetch.promise) {
      useEvent().fire('reward-definition-delete', rewardDefinition)
    }
  }

  return rewardDefinition
}
