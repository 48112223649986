<template>
  <div class="t-checkbox-field">
    <v-input
        :label="label"
        :error-messages="errorMessages"
        :disabled="disabled"
    >
      <div
          v-if="!containerItems.length && noItemsLabel"
          v-text="$t(noItemsLabel)"
          class="ml-2 mt-1 color-grey"
          style="font-size: 0.9rem"
      />

      <v-checkbox
          :key="containerItem.key"
          :label="containerItem.text"
          :value="internalValue.some(v => v === containerItem.value)"
          @click.stop="handleContainerItemClick(containerItem)"
          v-for="containerItem in containerItems"
          class="ml-2"
      />
    </v-input>
  </div>
</template>

<script>
import inputField from '../../mixins/inputField'
import itemContainer from '../../mixins/itemContainer'

export default {
  mixins: [
    inputField,
    itemContainer
  ],

  props: {
    value: {
      type: Array
    },
    noItemsLabel: {
      type: String
    }
  },

  methods: {
    initInternalValue () {
      if (this.value) {
        this.internalValue = this.value
      } else {
        this.internalValue = []
      }
    },

    handleContainerItemClick (containerItem) {
      const newValue = this.internalValue.slice()
      if (this.internalValue.some(v => v === containerItem.value)) {
        this.$lodash.remove(newValue, i => i === containerItem.value)
        this.$emit('input', newValue)
      } else {
        newValue.push(containerItem.value)
        this.$emit('input', newValue)
      }
    }
  }
}
</script>

<style>
  .t-checkbox-field .v-input--checkbox .v-messages {
    display: none;
  }

  .t-checkbox-field .v-input__slot {
    display: block;
    margin: 0;
  }

  .t-checkbox-field .v-input--checkbox {
    margin: 0;
  }

  .t-checkbox-field .v-input--checkbox .v-input__slot {
    display: flex;
    margin: 0;
  }

  .t-checkbox-field .mdi-checkbox-marked {
    color: var(--color-primary);
  }

  .t-checkbox-field .v-messages__wrapper {
    margin-top: 8px;
  }

</style>
