import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useInvoice from '@/app/composables/invoice/useInvoice'
import useLoader from '@/lib/composables/useLoader'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'

const tenantSelection = useTenantSelection()

export default function () {
  const invoiceList = Vue.observable({
    loader: useLoader(),
    invoices: [],
    fetch: null
  })

  invoiceList.load = async () => {
    await invoiceList.loader.load(async background => {
      if (background) {
        await invoiceList.fetch.executeFetch()
      } else {
        invoiceList.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/invoices`, {
          afterSuccess: data => {
            invoiceList.invoices = data.map(invoiceData => {
              const invoice = useInvoice()
              invoice.setData(invoiceData)
              return invoice
            })
          }
        })
      }
    })
    await invoiceList.loader.init()
  }

  return invoiceList
}
