import Vue from 'vue'

const refresh = Vue.observable({
  listeners: []
})

refresh.registerListener = (listenerId, listener) => {
  refresh.listeners.push({
    listenerId,
    listener
  })
}

refresh.unregisterListener = listenerId => {
  refresh.listeners = refresh.listeners.filter(listener => listener.listenerId !== listenerId)
}

refresh.fire = () => {
  refresh.listeners.forEach(listener => listener.listener())
}

setInterval(() => refresh.fire(), 5000)

export default function () {
  return refresh
}
