import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const $router = {
  create (routes) {
    return new Router({
      mode: 'history',
      routes: [
        ...routes,
        {
          path: '*',
          redirect: {
            name: routes[0].name
          }
        }
      ]
    })
  }
}

export default $router
