<template>
  <t-table
    :default-sort-ascending="false"
    :item-properties="[
          {
            key: 'date',
            type: 'date',
            sortable: true
          },
          {
            key: 'description',
            sortable: true
          },
          {
            key: 'number',
            sortable: true
          },
          {
            key: 'amountExcludingVat',
            type: 'amount',
            sortable: true
          },
          {
            key: 'amountIncludingVat',
            type: 'amount',
            sortable: true
          },
          {
            key: 'document',
            type: 'icon',
            icon: 'mdi-file-download-outline',
            iconClickHandler: invoice => invoice.downloadDocument(),
            sortable: true
          },
          {
            key: 'paid',
            type: 'icon',
            icon: invoice => invoice.paid ? { image: 'mdi-check-circle', color: 'primary' } : null,
            sortable: true
          },
          {
            key: 'pay',
            type: 'icon',
            icon: invoice => invoice.paid ? null : { image: 'mdi-credit-card-outline', color: 'primary' },
            iconClickHandler: invoice => $dialog.openDialog('invoice-payment', invoice),
            sortable: true
          },
          {
            key: 'view',
            type: 'icon',
            icon: 'mdi-eye',
            iconClickHandler: invoice => $dialog.openDialog('invoice', invoice),
          }
        ]"
    :items="invoiceList.invoices"
    default-sort-property-key="date"
    item-key="id"
    :search="true"
  />
</template>

<script>
export default {
  props: {
    invoiceList: {
      required: true
    }
  }
}
</script>
