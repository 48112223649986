<template>
  <div>
    <v-autocomplete
        ref="autocomplete"
        :value="internalValue ? internalValue.key : null"
        :items="autocompleteItems"
        item-text="text"
        item-value="key"
        :label="$t(label)"
        @change="handleOnChange"
        :error-messages="errorMessages"
        :loading="loading"
        @keyup="handleOnKeyUp"
        :return-object="true"
        no-data-text=""
        :disabled="disabled"
        :clearable="true"
        :open-on-clear="false"
        :autofocus="autofocus"
        :filter="(item, searchText, itemText) => $items.filterSearchText(itemText, searchText)"
    />
  </div>
</template>

<script>
import itemContainer from '../../mixins/itemContainer'
import inputField from '../../mixins/inputField'
import { Fetch } from '../../fetcher'

export default {
  mixins: [
    itemContainer,
    inputField
  ],

  props: {
    itemByValueHandler: {
      type: Function
    }
  },

  computed: {
    autocompleteItems () {
      const autocompleteItems = []
      if (this.containerItems) {
        autocompleteItems.push(...this.containerItems)
      }
      if (this.internalValue && (!this.containerItems || !this.containerItems.some(containerItem => containerItem.key === this.internalValue.key))) {
        autocompleteItems.push(this.internalValue)
      }
      return autocompleteItems
    }
  },

  watch: {
    containerItems () {
      if (!this.itemByValueHandler) {
        this.initInternalValue()
      }
    }
  },

  methods: {
    async initInternalValue () {
      if (this.value && this.internalValue && this.internalValue.value === this.value) {
        return
      }

      if (!this.value) {
        this.internalValue = null
        this.searchText = null
        if (this.$refs.autocomplete) {
          this.$refs.autocomplete.internalSearch = undefined
        }
      }

      if (this.value && this.itemByValueHandler) {
        let itemByValueHandlerResponse = this.itemByValueHandler(this.value)
        if (itemByValueHandlerResponse instanceof Promise) {
          itemByValueHandlerResponse = await itemByValueHandlerResponse
        }

        let item
        if (itemByValueHandlerResponse instanceof Fetch) {
          await itemByValueHandlerResponse.promise
          if (itemByValueHandlerResponse.isSuccess()) {
            item = itemByValueHandlerResponse.data
          }
        } else {
          item = itemByValueHandlerResponse
        }

        this.internalValue = {
          key: this.getItemKey(item),
          value: this.getItemValue(item),
          text: this.getItemText(item)
        }
      } else if (this.value && this.containerItems) {
        this.internalValue = this.containerItems.find(containerItem => containerItem.key === this.value)
      }
    },

    emitValue () {
      this.$emit('input', this.internalValue ? this.internalValue.value : null)
    },

    handleOnKeyUp (event) {
      this.searchText = event.target.value
    },

    handleOnChange (containerItem) {
      if (containerItem) {
        this.internalValue = containerItem
      } else {
        this.internalValue = null
        this.searchText = null
        if (this.$refs.autocomplete) {
          this.$refs.autocomplete.internalSearch = undefined
        }
      }
      this.emitValue()
    }
  }
}
</script>
