var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('t-table',{staticStyle:{"display":"inline-block","width":"30%","vertical-align":"top","min-width":"350px"},attrs:{"items":[
      {
        numberOfCancellations: _vm.report.numberOfCancellations,
        totalCancelledBalance: _vm.report.totalCancelledBalance
      }
    ],"item-properties":[
      {
        key: 'numberOfCancellations',
        type: 'number'
      },
      {
        key: 'totalCancelledBalance',
        header: 'label.cancelledBalance',
        type: 'amount'
      }
    ]}}),_c('t-table',{staticClass:"mt-8",attrs:{"items":_vm.report.cancellations,"item-properties":[
      {
        key: 'dateTime',
        type: 'date-time',
        sortable: true
      },
      {
        key: 'userId',
        sortable: true
      },
      {
        key: 'cancelledBalance',
        type: 'amount',
        sortable: true
      }
    ],"default-sort-property-key":"dateTime","default-sort-ascending":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }