<template>
  <div>
    <t-table
      :item-properties="[
          {
            key: 'sum'
          },
          {
            key: 'amount',
            type: 'amount'
          },
          {
            key: 'numberOfTopups',
            type: 'number'
          }
        ]"
      :items="[
          {
            sum: $t('label.cashPayment'),
            amount: report.cashPaymentSummary.amount,
            numberOfTopups: report.cashPaymentSummary.numberOfTopups
          },
          {
            sum: $t('label.bancontactPayment'),
            amount: report.bancontactPaymentSummary.amount,
            numberOfTopups: report.bancontactPaymentSummary.numberOfTopups
          },
          {
            sum: $t('label.total'),
            amount: report.totalSummary.amount,
            numberOfTopups: report.totalSummary.numberOfTopups
          }
        ]"
    />
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Object
    }
  }
}
</script>
