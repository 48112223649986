import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useEntity from '@/lib/composables/useEntity'

const tenantSelection = useTenantSelection()

export default function () {
  const rewardTemplate = {
    loader: null,
    id: null,
    reason: null,
    amount: null,
    grantees: [],
    userIds: [],
    assigned: null,
    numberOfGrantees: null,
    grantedByUserId: null
  }

  const reward = useEntity(rewardTemplate, reward => {
    reward.numberOfGrantees = reward.grantees.length
    reward.userIds = reward.grantees.map(grantee => grantee.userId)
  })

  reward.save = async editReward => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/rewards/${editReward.id}`, {
      body: {
        reason: editReward.reason,
        amount: editReward.amount,
        grantees: editReward.userIds.map(userId => {
          return {
            userId
          }
        })
      }
    })
    if (await fetch.promise) {
      reward.setData(fetch.data)
      useEvent().fire('reward-save', reward)
    }
  }

  reward.assign = async () => {
    const fetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/rewards/${reward.id}/assign`)
    if (await fetch.promise) {
      useEvent().fire('reward-assign', reward)
    }
  }

  reward.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/rewards/${reward.id}`)
    if (await fetch.promise) {
      useEvent().fire('reward-delete', reward)
    }
  }

  return reward
}
