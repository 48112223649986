var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"device-link","input-fields":[
        {
          key: 'type',
          type: 'text',
          label: 'label.type',
          readOnly: true
        },
        {
          key: 'model',
          type: 'text',
          label: 'label.model',
          readOnly: true
        },
        {
          key: 'serial',
          type: 'text',
          label: 'label.serialNumber',
          required: true
        },
        {
          key: 'name',
          type: 'text',
          label: 'label.name',
          required: true
        },
        {
          key: 'pincode',
          type: 'text',
          label: 'label.pincode',
          required: true,
          numeric: true
        },
        {
          key: 'tenantShortName',
          type: 'text',
          label: 'label.shortenedClubName',
          required: true
        },
        {
          key: 'locale',
          type: 'select',
          label: 'label.locale',
          items: [
            {
              name: 'NL',
              locale: 'nl-BE'
            },
            {
              name: 'EN',
              locale: 'en-US'
            }
          ],
          itemValue: 'locale',
          itemText: 'name',
          required: true
        }
      ],"item":_vm.device,"open-handler":_vm.onOpen,"save-handler":_vm.handleStartLink,"action-button-label":"label.start","edit-title":"label.linkDevice","new-title":"label.linkDevice"}})
}
var staticRenderFns = []

export { render, staticRenderFns }