<template>
  <t-table
    :item-properties="[
          {
            key: 'name',
            sortable: true,
          },
          {
            key: 'amount',
            type: 'amount',
            sortable: true
          },
          {
            header: 'label.assign',
            icon: 'mdi-gift',
            iconClickHandler: rewardDefinition => $dialog.openDialog('reward', {
              reason: rewardDefinition.name,
              amount: rewardDefinition.amount,
            })
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: rewardDefinition => $dialog.openDialog('reward-definition', rewardDefinition)
          }
        ]"
    :items="rewardDefinitionList.fetch"
    default-sort-property-key="name"
    item-key="id"
    no-items-label="message.noRewardDefinitions"
  />
</template>

<script>
export default {
  props: {
    rewardDefinitionList: {
      required: true
    }
  }
}
</script>
