<template>
  <v-input
      :error-messages="errorMessages"
  >
    <div class="flex-grow-1">
      <v-list>
        <v-subheader
            style="padding-left: 0; height: 16px"
            :class="{
                'color-error' : error,
                'mb-2': disabled
            }"
        >
          <span
              style="font-size: 16px;"
              :class="{
                'color-grey' : disabled
              }"
              v-text="$t(label)"
          />
        </v-subheader>

        <v-list-item v-if="!disabled">
          <v-list-item-content>
            <v-autocomplete
                ref="autocomplete"
                v-model="autocompleteValue"
                :items="autocompleteItems"
                item-text="text"
                @change="handleOnChange"
                :loading="loading"
                @keyup="handleOnKeyUp"
                :return-object="true"
                no-data-text=""
                dense
                :error="error"
                :autofocus="autofocus"
                :filter="(item, searchText, itemText) => $items.filterSearchText(itemText, searchText)"
            />
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            v-for="listItem in listItems"
            :key="listItem.key"
            :disabled="disabled"
            dense
        >
          <v-list-item-content>
            <v-list-item-title v-text="listItem.text"></v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="!disabled">
            <v-btn
                @click.stop="handleListItemRemoveClick(listItem)"
                @mousedown.stop
                icon
                x-small>
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>
  </v-input>
</template>

<script>
import itemContainer from '../../mixins/itemContainer'
import inputField from '../../mixins/inputField'
import { Fetch } from '../../fetcher'

export default {
  mixins: [
    itemContainer,
    inputField
  ],

  props: {
    itemByValueHandler: {
      type: Function
    }
  },

  data () {
    return {
      autocompleteValue: null
    }
  },

  computed: {
    autocompleteItems () {
      if (!this.containerItems) {
        return []
      }
      const autocompleteItems = this.containerItems.slice()
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$items.sortItems(autocompleteItems, 'text')
      return autocompleteItems
    },

    listItems () {
      if (!this.internalValue) {
        return []
      }
      const checkboxItems = this.internalValue.slice()
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$items.sortItems(checkboxItems, 'text')
      return checkboxItems
    }
  },

  watch: {
    containerItems () {
      if (!this.itemByValueHandler) {
        this.initInternalValue()
      }
    }
  },

  methods: {
    async initInternalValue () {
      if (this.value && this.itemByValueHandler) {
        const items = []
        for (const handlerValue of this.value) {
          let itemByValueHandlerResponse = this.itemByValueHandler(handlerValue)
          if (itemByValueHandlerResponse instanceof Promise) {
            itemByValueHandlerResponse = await itemByValueHandlerResponse
          }

          if (itemByValueHandlerResponse instanceof Fetch) {
            await itemByValueHandlerResponse.promise
            if (itemByValueHandlerResponse.isSuccess()) {
              items.push(itemByValueHandlerResponse.data)
            }
          } else {
            items.push(itemByValueHandlerResponse)
          }
        }

        this.internalValue = items.map(item => {
          return {
            key: this.getItemKey(item),
            value: this.getItemValue(item),
            text: this.getItemText(item)
          }
        })
      } else if (this.value) {
        const allContainerItems = this.itemList.map(item => this.mapContainerItem(item))
        this.$items.sortItems(allContainerItems, 'text')
        this.internalValue = allContainerItems.filter(containerItem => this.value.includes(containerItem.key))
      } else {
        this.internalValue = []
      }
    },

    emitValue () {
      if (this.internalValue) {
        const emitValue = this.internalValue.map(item => item.value)
        this.$emit('input', emitValue)
      } else {
        this.$emit('input', null)
      }
    },

    handleOnKeyUp (event) {
      this.searchText = event.target.value
    },

    handleOnChange (containerItem) {
      if (containerItem) {
        if (!this.internalValue) {
          this.internalValue = []
        }

        const existingItem = this.internalValue.find(item => item.key === containerItem.key)
        if (!existingItem) {
          this.internalValue.push(containerItem)
        }

        this.searchText = null
        this.$nextTick(() => {
          this.autocompleteValue = null
        })
        if (this.$refs.autocomplete) {
          this.$refs.autocomplete.internalSearch = undefined
        }

        this.emitValue()
      }
    },

    handleListItemRemoveClick (listItem) {
      this.internalValue = this.internalValue.filter(item => item.key !== listItem.key)
      this.emitValue()
    }
  }
}
</script>
