<template>
  <t-edit-dialog
    id="invoice"
    :action-chips="[
        {
          icon: {
            image: 'mdi-credit-card-outline',
            color: 'primary'
          },
          label: 'label.pay',
          hideHandler: () => invoice.paid,
          handler: () => $dialog.openDialog('invoice-payment', invoice),
        },
        {
          icon: 'mdi-file-download-outline',
          label: 'label.downloadDocument',
          handler: invoice.downloadDocument
        }
      ]"
    :input-fields="[
        {
          key: 'description',
          type: 'text'
        },
        {
          key: 'date',
          type: 'date'
        },
        {
          key: 'number',
          type: 'text'
        },
        {
          key: 'lines',
          type: 'list',
          uuidItem: true,
          itemKey: 'id',
          inputFields: [
            {
              key: 'description',
              type: 'text'
            },
            {
              key: 'price',
              type: 'amount'
            },
            {
              key: 'quantity',
              type: 'number'
            },
            {
              key: 'amountExcludingVat',
              type: 'amount'
            },
            {
              key: 'amountIncludingVat',
              type: 'amount'
            }
          ],
          itemText: line => `${line.description} (€${line.price}) X ${line.quantity}`
        },
        {
          key: 'amountExcludingVat',
          type: 'amount'
        },
        {
          key: 'amountIncludingVat',
          type: 'amount'
        }
      ]"
    :item="invoice"
    :uuid-item="true"
    :open-handler="handleOnOpen"
    :read-only-for-edit="true"
  />

</template>

<script>
import useInvoice from '@/app/composables/invoice/useInvoice'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      invoice: useInvoice()
    }
  },

  created () {
    useEvent().registerListener('invoice-dialog', 'invoice-paid', () => this.invoice.refresh())
  },

  destroyed () {
    useEvent().unregisterListener('invoice-dialog')
  },

  methods: {
    handleOnOpen () {
      this.invoice.setData(this.$dialog.getDialogData('invoice'))
    }
  }
}
</script>
