<template>
  <t-edit-dialog
    id="reward"
    :action-chips="[
        {
          label: 'label.userGroup',
          icon: 'mdi-plus-circle',
          handler: () => event.fire('user-group-select', addUserGroup),
          hideHandler: reward => reward.assigned,
          disabledHandler: () => userGroupList.userGroups.length === 0
        },
        {
          label: 'label.assignNow',
          icon: 'mdi-gift',
          handler: reward.assign,
          hideForNew: true,
          hideHandler: reward => reward.assigned,
          closeDialog: true
        }
      ]"
    :copy-properties="['assigned']"
    :delete-handler="!reward.assigned ? reward.delete : undefined"
    :input-fields="[
        {
          key: 'reason',
          type: 'text',
          label: 'label.reason',
          required: true
        },
        {
          key: 'amount',
          type: 'amount',
          label: 'label.amount',
          required: true
        },
        {
          key: 'userIds',
          type: 'auto-complete-list',
          label: 'label.users',
          items: userSearch.items,
          itemKey: 'id',
          itemFilteringHandler: userSearch.search,
          itemByValueHandler: userSearch.findByIdWithDeletionPlaceholder,
          itemText: 'fullName',
          required: true
        }
      ]"
    :item="reward"
    :open-handler="onOpen"
    :read-only-for-edit="reward.assigned"
    :save-handler="reward.save"
    :uuid-item="true"
    edit-subtitle="message.editReward"
    new-subtitle="message.newReward"
    view-subtitle="message.viewReward"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useReward from '@/app/composables/reward/useReward'
import useUserGroupList from '@/app/composables/user/useUserGroupList'
import useUserSearch from '@/app/composables/user/useUserSearch'

export default {
  data () {
    return {
      reward: useReward(),
      userGroupList: useUserGroupList(),
      userSearch: useUserSearch(),
      event: useEvent()
    }
  },

  created () {
    useEvent().registerListener(this, ['reward-save', 'reward-delete', 'reward-assign'], () => {
      this.$dialog.closeDialog('reward')
    })
  },

  destroyed () {
    useEvent().unregisterListener(this)
  },

  methods: {
    onOpen () {
      this.userGroupList.load()
      this.userSearch = useUserSearch()
      this.reward.setData(this.$dialog.getDialogData('reward'))
    },

    addUserGroup (userGroup) {
      userGroup.userIds.forEach(userId => {
        if (!this.reward.userIds.some(id => id === userId)) {
          this.reward.userIds.push(userId)
        }
      })
    }
  }
}
</script>
