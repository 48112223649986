<template>
  <t-page>
    <t-content-card
      title="label.devices"
      :fetch="deviceList.fetch"
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => $dialog.openDialog('device-qr-link')
        }
      ]">
      <device-table :device-list="deviceList" />
    </t-content-card>
  </t-page>
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useRefresh from '@/lib/composables/useRefresh'
import useDeviceList from '@/app/composables/device/useDeviceList'

export default {
  data () {
    return {
      deviceList: useDeviceList()
    }
  },

  created () {
    this.deviceList.load()
    useRefresh().registerListener('device-page', () => this.deviceList.loader.backgroundRefresh())
    useEvent().registerListener('device-page', ['device-save', 'device-delete', 'device-linked'], () => this.deviceList.loader.refresh())
  },

  destroyed () {
    useRefresh().unregisterListener('device-page')
    useEvent().unregisterListener('device-page')
  }
}
</script>
