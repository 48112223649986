import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const $vuetify = {
  create () {
    return new Vuetify({
      icons: {
        iconfont: 'mdi'
      },
      theme: {
        dark: true,
        themes: {
          dark: {
            primary: '#FFC045',
            accent: '#FFD545',
            secondary: '#0a5471',
            success: '#4CAF50',
            info: '#99CA39',
            warning: '#FB8C00',
            error: '#FF5252'
          }
        }
      }
    })
  }
}

export default $vuetify
