<template>
  <t-edit-dialog
    id="product"
    :delete-handler="product.delete"
    :input-fields="[
        {
          key: 'name',
          type: 'auto-complete-text-field',
          label: 'label.name',
          items: defaultProductList.defaultProducts,
          itemKey: 'name',
          changeHandler: name => editProductName = name,
          required: true
        },
        {
          key: 'categoryId',
          type: 'select',
          label: 'label.category',
          items: productCategoryList.productCategories,
          itemKey: 'id',
          itemValue: 'id',
          itemText: 'name',
          required: true
        },
        {
          key: 'price',
          type: 'amount',
          label: 'label.price',
          required: true
        },
        {
          key: 'logo',
          type: 'logo-choice',
          items: editProductLogos,
          required: true
        },
        {
          key: 'favorite',
          type: 'switch'
        },
        {
          key: 'disabled',
          type: 'switch'
        }
      ]"
    :item="product"
    :save-handler="product.save"
    :uuid-item="true"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useDefaultProductList from '@/app/composables/product/useDefaultProductList'
import useProductCategoryList from '@/app/composables/product/useProductCategoryList'
import useProduct from '@/app/composables/product/useProduct'

export default {
  data () {
    return {
      product: useProduct(),
      defaultProductList: useDefaultProductList(),
      editProductName: null,
      productCategoryList: useProductCategoryList()
    }
  },

  computed: {
    editProductLogos () {
      const editProductLogos = []

      if (this.product.logo) {
        editProductLogos.push(this.product.logo)
      }

      if (this.editProductName) {
        const defaultProducts = this.defaultProductList.defaultProducts.filter(defaultProduct => {
          const defaultProductNameSearch = defaultProduct.name.toLowerCase().replace(/[^a-z0-9]/gi, '')
          const editProductNameSearch = this.editProductName.toLowerCase().replace(/[^a-z0-9]/gi, '')
          return defaultProductNameSearch.startsWith(editProductNameSearch)
        })

        if (defaultProducts.length && defaultProducts.length <= 3) {
          defaultProducts.forEach(defaultProduct => {
            if (!editProductLogos.includes(defaultProduct.logo)) {
              editProductLogos.push(defaultProduct.logo)
            }
          })
        }

        const textLogo = this.$canvas.createTextLogo(this.editProductName)
        if (this.product.logo !== textLogo) {
          editProductLogos.push(textLogo)
        }
      }

      return editProductLogos
    }
  },

  created () {
    useEvent().registerListener(this, ['tenant-select'], () => {
      this.defaultProductList.load()
    })

    useEvent().registerListener(this, ['tenant-select', 'product-category-save', 'product-category-delete'], () => {
      this.productCategoryList.load()
    })

    useEvent().registerListener(this, ['product-add', 'product-edit'], product => {
      this.product.setData(product)
      this.editProductName = this.product.name
      this.$dialog.openDialog('product')
    })

    useEvent().registerListener(this, ['product-save', 'product-delete'], () => {
      this.$dialog.closeDialog('product')
    })
  },

  destroyed () {
    useEvent().unregisterListener(this)
  }
}
</script>
