var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"card","input-fields":[
        {
          key: 'id',
          type: 'uuid',
          label: 'label.cardNumber',
          readOnly: true
        },
        {
          key: 'userId',
          type: 'auto-complete-select',
          label: 'label.user',
          items: _vm.userSearch.items,
          itemKey: 'id',
          itemText: 'fullName',
          itemFilteringHandler: _vm.userSearch.search,
          itemByValueHandler: _vm.userSearch.findById
        },
        {
          key: 'disabled',
          type: 'switch',
          label: 'label.disabled',
          required: true
        }
      ],"item":_vm.card,"open-handler":_vm.onOpen,"save-handler":_vm.card.save,"uuid-item":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }