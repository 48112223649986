<template>
  <t-confirm-dialog
    id="user-membership-cancel"
    :confirm-danger="true"
    :confirm-handler="user.cancelMembership"
    :input-fields="[
        {
          key: 'disableCardIds',
          label: 'label.disableCards',
          type: 'checkboxes',
          items: userCards.items,
          itemKey: 'id',
          itemText: 'formattedId',
          noItemsLabel: 'message.noCards'
        }
      ]"
    :open-handler="onOpen"
    message="message.cancelMembership"
    title="label.cancelMembership"
  />
</template>

<script>
import useUser from '@/app/composables/user/useUser'
import useEvent from '@/lib/composables/useEvent'
import useCardSearch from '@/app/composables/card/useCardSearch'

export default {
  data () {
    return {
      user: useUser(),
      userCards: useCardSearch()
    }
  },

  created () {
    useEvent().registerListener('user-membership-cancel-dialog', 'user-membership-cancel', () => this.$dialog.closeDialog('user-membership-cancel'))
  },

  destroyed () {
    useEvent().unregisterListener('user-membership-cancel-dialog')
  },

  methods: {
    onOpen () {
      this.user.setData(this.$dialog.getDialogData('user-membership-cancel'))
      this.userCards = useCardSearch(this.user.id)
      this.userCards.search()
    }
  }
}
</script>
