<template>
  <div>
    <t-table
      :items="[
          {
            sum: $t('label.orders'),
            numberOfCorrections: report.numberOfOrderCorrections,
            correctiveAmount: report.correctiveOrderAmount
          },
          {
            sum: $t('label.topups'),
            numberOfCorrections: report.numberOfTopupCorrections,
            correctiveAmount: report.correctiveTopupAmount
          },
          {
            sum: $t('label.total'),
            numberOfCorrections: report.numberOfCorrections,
            correctiveAmount: report.correctiveAmount
          }
        ]"
      item-key="key"
      :item-properties="[
          {
            key: 'sum'
          },
          {
            key: 'numberOfCorrections',
            type: 'number'
          },
          {
            key: 'correctiveAmount',
            type: 'amount'
          }
        ]"
      style="display: inline-block; width: 60%; vertical-align: top; min-width: 350px; margin-right: 16px"
    />

    <t-table
      class="mt-8"
      :items="report.corrections"
      item-key="id"
      :item-properties="[
          {
            key: 'dateTime',
            type: 'date-time',
            sortable: true
          },
          {
            key: 'type',
            type: 'icon',
            icon: correction => {
              switch (correction.type) {
                case 'ORDER': return {
                  image: 'mdi-shopping',
                  tooltip: 'label.order'
                }
                case 'TOPUP': return {
                  image: 'mdi-credit-card-plus',
                  tooltip: 'label.topup'
                }
              }
            },
            sortable: true
          },
          {
            key: 'amount',
            type: 'amount',
            sortable: true
          }
        ]"
      default-sort-property-key="dateTime"
      :default-sort-ascending="false"
    />
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Object
    }
  }
}
</script>
