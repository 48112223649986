import $fetcher from '@/lib/fetcher'
import $authorization from '@/lib/authorization'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'

export default function () {
  const tenantTemplate = {
    id: null,
    userId: null,
    name: null,
    owner: null
  }

  const tenant = useEntity(tenantTemplate, tenant => {
    tenant.owner = tenant.userId === $authorization.user.userId
  })

  tenant.refresh = async () => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenant.id}`)
    if (await fetch.promise) {
      tenant.setData(fetch.data)
    }
  }

  tenant.save = async editTenant => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/tenants/${editTenant.id}`, {
      body: {
        name: editTenant.name
      }
    })
    if (await fetch.promise) {
      useEvent().fire('tenant-save', tenant)
    }
  }

  tenant.getTenantIdAvailability = async id => {
    const availabilityFetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/public/tenants/check-availability?id=${id}`)
    await availabilityFetch.promise
    return availabilityFetch.data ? availabilityFetch.data.available : false
  }

  return tenant
}
