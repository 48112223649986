<template>
  <t-table
    :item-properties="[
          {
            key: 'userEmail',
            header: 'label.email',
            sortable: true
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: membershipRequest => $dialog.openDialog('user-membership-request', membershipRequest)
          }
        ]"
    :items="membershipRequestList.membershipRequests"
    default-sort-property-key="userEmail"
    item-key="id"
    no-items-label="message.noDataFound"
    :search="true"
  />
</template>

<script>
export default {
  props: {
    membershipRequestList: {
      required: true
    }
  }
}
</script>
