<template>
  <div>
    <t-line-chart
      :labels="[
        'label.january',
        'label.february',
        'label.march',
        'label.april',
        'label.may',
        'label.june',
        'label.july',
        'label.august',
        'label.september',
        'label.october',
        'label.november',
        'label.december',
      ]"
      :values="[
        report.januaryRevenue,
        report.februaryRevenue,
        report.marchRevenue,
        report.aprilRevenue,
        report.mayRevenue,
        report.juneRevenue,
        report.julyRevenue,
        report.augustRevenue,
        report.septemberRevenue,
        report.octoberRevenue,
        report.novemberRevenue,
        report.decemberRevenue,
      ]"
      value-type="amount"
    />

    <t-table
      class="mt-8"
      :items="[
          {
            month: 'january',
            monthNumber: 1,
            revenue: report.januaryRevenue
          },
          {
            month: 'february',
            monthNumber: 2,
            revenue: report.februaryRevenue
          },
          {
            month: 'march',
            monthNumber: 3,
            revenue: report.marchRevenue
          },
          {
            month: 'april',
            monthNumber: 4,
            revenue: report.aprilRevenue
          },
          {
            month: 'may',
            monthNumber: 5,
            revenue: report.mayRevenue
          },
          {
            month: 'june',
            monthNumber: 6,
            revenue: report.juneRevenue
          },
          {
            month: 'july',
            monthNumber: 7,
            revenue: report.julyRevenue
          },
          {
            month: 'august',
            monthNumber: 8,
            revenue: report.augustRevenue
          },
          {
            month: 'september',
            monthNumber: 9,
            revenue: report.septemberRevenue
          },
          {
            month: 'october',
            monthNumber: 10,
            revenue: report.octoberRevenue
          },
          {
            month: 'november',
            monthNumber: 11,
            revenue: report.novemberRevenue
          },
          {
            month: 'december',
            monthNumber: 12,
            revenue: report.decemberRevenue
          }
        ]"
      item-key="key"
      :item-properties="[
          {
            key: 'month',
            text: item => $t('label.' + item.month),
            value: item => item.monthNumber,
            sortable: true
          },
          {
            key: 'revenue',
            type: 'amount',
            header: 'label.amount',
            sortable: true
          }
        ]"
      default-sort-property-key="month"
    />

    <t-table
      class="mt-8"
      :items="report.products"
      item-key="productName"
      :item-properties="[
          {
            key: 'productName',
            header: 'label.product',
            sortable: true
          },
          {
            key: 'januaryRevenue',
            header: 'label.january',
            type: 'amount',
            sortable: true
          },
          {
            key: 'februaryRevenue',
            header: 'label.february',
            type: 'amount',
            sortable: true
          },
          {
            key: 'marchRevenue',
            header: 'label.march',
            type: 'amount',
            sortable: true
          },
          {
            key: 'aprilRevenue',
            header: 'label.april',
            type: 'amount',
            sortable: true
          },
          {
            key: 'mayRevenue',
            header: 'label.may',
            type: 'amount',
            sortable: true
          },
          {
            key: 'juneRevenue',
            header: 'label.june',
            type: 'amount',
            sortable: true
          },
          {
            key: 'julyRevenue',
            header: 'label.july',
            type: 'amount',
            sortable: true
          },
          {
            key: 'augustRevenue',
            header: 'label.august',
            type: 'amount',
            sortable: true
          },
          {
            key: 'septemberRevenue',
            header: 'label.september',
            type: 'amount',
            sortable: true
          },
          {
            key: 'octoberRevenue',
            header: 'label.october',
            type: 'amount',
            sortable: true
          },
          {
            key: 'novemberRevenue',
            header: 'label.november',
            type: 'amount',
            sortable: true
          },
          {
            key: 'decemberRevenue',
            header: 'label.december',
            type: 'amount',
            sortable: true
          },
        ]"
      default-sort-property-key="productName"
    />
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Object
    }
  }
}
</script>
