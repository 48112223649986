<template>
  <v-autocomplete
      :value="internalValue"
      :items="autocompleteItems"
      item-text="text"
      item-value="text"
      :label="$t(label)"
      @change="handleOnChange"
      @keyup="handleOnKeyUp"
      no-data-text=""
      :disabled="disabled"
      :error-messages="errorMessages"
      :loading="loading"
      :autofocus="autofocus"
      :filter="(item, searchText, itemText) => $items.filterSearchText(itemText, searchText)"
  />
</template>

<script>
import inputField from '../../mixins/inputField'
import itemContainer from '../../mixins/itemContainer'

export default {
  mixins: [
    inputField,
    itemContainer
  ],

  computed: {
    autocompleteItems () {
      const autocompleteItems = []
      if (this.containerItems && this.searchText) {
        autocompleteItems.push(...this.containerItems)
      }
      if (this.internalValue && !autocompleteItems.includes(this.internalValue)) {
        autocompleteItems.unshift(this.internalValue)
      }
      return autocompleteItems
    }
  },

  methods: {
    handleOnKeyUp (event) {
      this.searchText = event.target.value
      this.internalValue = event.target.value
      this.emitValue()
    },

    handleOnChange (value) {
      if (value) {
        this.internalValue = value
      } else {
        this.internalValue = null
        if (this.$refs.autocomplete) {
          this.$refs.autocomplete.internalSearch = undefined
        }
      }
      this.emitValue()
    }
  }
}
</script>
