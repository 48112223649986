var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"product","delete-handler":_vm.product.delete,"input-fields":[
      {
        key: 'name',
        type: 'auto-complete-text-field',
        label: 'label.name',
        items: _vm.defaultProductList.defaultProducts,
        itemKey: 'name',
        changeHandler: name => _vm.editProductName = name,
        required: true
      },
      {
        key: 'categoryId',
        type: 'select',
        label: 'label.category',
        items: _vm.productCategoryList.productCategories,
        itemKey: 'id',
        itemValue: 'id',
        itemText: 'name',
        required: true
      },
      {
        key: 'price',
        type: 'amount',
        label: 'label.price',
        required: true
      },
      {
        key: 'logo',
        type: 'logo-choice',
        items: _vm.editProductLogos,
        required: true
      },
      {
        key: 'favorite',
        type: 'switch'
      },
      {
        key: 'disabled',
        type: 'switch'
      }
    ],"item":_vm.product,"save-handler":_vm.product.save,"uuid-item":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }