<template>
  <t-table
    :items="[subscription]"
    item-key="tenantId"
    :item-properties="[
          {
            key: 'plan',
            value: subscription => $t('label.subscriptionPlan_' + subscription.plan)
          },
          {
            key: 'subscriptionDate',
            type: 'date'
          },
          {
            key: 'currentInvoicingBeginDate',
            type: 'date',
            visible: () => subscription.plan !== 'FREE'
          },
          {
            key: 'currentInvoicingEndDate',
            type: 'date',
            visible: () => subscription.plan !== 'FREE'
          },
          {
            key: 'currentInvoicingOrders',
            type: 'number',
            visible: () => subscription.plan !== 'FREE'
          },
          {
            key: 'remainingFreeOrders',
            type: 'number',
            visible: () => subscription.plan === 'FREE'
          }
        ]"
    :horizontal="true"
  />
</template>

<script>
export default {
  props: {
    subscription: {
      required: true
    }
  }
}
</script>
