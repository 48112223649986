import Vue from 'vue'
import useTranslate from '@/lib/composables/useTranslate'
import useLoader from '@/lib/composables/useLoader'
import useTenantSettings from '@/app/composables/tenant/useTenantSettings'
import useSubscription from '@/app/composables/subscription/useSubscription'

const translate = useTranslate()
const tenantSettings = useTenantSettings()
const subscription = useSubscription()

export default function () {
  const subscriptionPlanList = Vue.observable({
    loader: useLoader(),
    subscriptionPlans: [],
    invoicingSettingsNeeded: null
  })

  subscriptionPlanList.load = async () => {
    await subscriptionPlanList.loader.load(async () => {
      subscriptionPlanList.subscriptionPlans = []
      subscriptionPlanList.invoicingSettingsNeeded = null

      await tenantSettings.find()
      await subscription.load()

      const freePlan = {
        id: 'FREE',
        label: translate.label.subscriptionPlan_FREE,
        description: translate.message.subscriptionPlan_FREE,
        disabled: false
      }
      const basicPlan = {
        id: 'BASIC',
        label: translate.label.subscriptionPlan_BASIC,
        description: translate.message.subscriptionPlan_BASIC,
        disabled: !tenantSettings.invoicingSettings.completed
      }
      const customPlan = {
        id: 'CUSTOM',
        label: translate.label.subscriptionPlan_CUSTOM,
        description: translate.message.subscriptionPlan_CUSTOM
      }

      if (subscription.plan === 'CUSTOM') {
        subscriptionPlanList.subscriptionPlans = [freePlan, basicPlan, customPlan]
      } else {
        subscriptionPlanList.subscriptionPlans = [freePlan, basicPlan]
      }

      subscriptionPlanList.invoicingSettingsNeeded = !tenantSettings.invoicingSettings.completed
    })
    return subscriptionPlanList.loader.init
  }

  return subscriptionPlanList
}
