<template>
  <div class="t-date-field">
    <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        min-width="290px"
        offset-y
        transition="scale-transition"
        v-model="dateMenu"
        :disabled="disabled"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
            :label="$t(label)"
            :value="internalValue"
            readonly
            ref="dateField"
            v-bind="$attrs"
            v-on="on"
            :disabled="disabled"
            :error-messages="errorMessages"
        />
      </template>

      <v-date-picker
          :max="maxDate"
          :min="minDate"
          :value="internalValue"
          @change="onChange"
          ref="datePicker"
          :disabled="disabled"
          :locale="$authorization.user.locale"
          :first-day-of-week="1"
      />
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment'
import inputField from '../../mixins/inputField'

export default {
  mixins: [
    inputField
  ],

  props: {
    minDate: {
      type: String,
      default: '2000-01-01'
    },
    maxDate: {
      type: String,
      default: moment().endOf('year').format('YYYY-MM-DD')
    },
    picker: {
      type: String,
      default: 'DATE'
    }
  },

  data () {
    return {
      dateMenu: false
    }
  },

  watch: {
    dateMenu (dateMenu) {
      dateMenu && setTimeout(() => (this.$refs.datePicker.activePicker = this.picker))
    }
  },

  methods: {
    onChange (date) {
      this.internalValue = date
      this.emitValue()
      this.dateMenu = false
    }
  }
}
</script>

<style>
.t-date-field .v-date-picker-table .v-btn.v-btn--active {
  color: #212121;
  font-weight: bold;
}

.t-date-field .v-picker__title {
  background: #212121;
}

/*.t-date-field .v-text-field > .v-input__control > .v-input__slot:after {*/
/*  border: none;*/
/*}*/
</style>
