import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useLoader from '@/lib/composables/useLoader'
import useUserGroup from '@/app/composables/user/useUserGroup'

const tenantSelection = useTenantSelection()

export default function () {
  const userGroupList = Vue.observable({
    loader: useLoader(),
    userGroups: [],
    fetch: null
  })

  userGroupList.setData = data => {
    userGroupList.userGroups = data.map(cardData => {
      const userGroup = useUserGroup()
      userGroup.setData(cardData)
      return userGroup
    })
  }

  userGroupList.load = async () => {
    await userGroupList.loader.load(async background => {
      if (background) {
        await userGroupList.fetch.executeFetch()
      } else {
        userGroupList.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/user-groups`)
        await userGroupList.fetch.promise
      }
      userGroupList.setData(userGroupList.fetch.data)
    })
  }

  return userGroupList
}
