<template>
  <t-page>
    <t-content-card
      :actions="[
        {
          icon: 'mdi-lead-pencil',
          handler: () => $dialog.openDialog('subscription')
        }
      ]"
      :fetch="subscription.fetch"
      :subtitle="$t('message.subscriptionPlan_' + subscription.plan)"
      title="label.subscription"
    >
      <t-message
        v-if="subscription.nextPlan"
        :message="$t('message.nextPlan', {
          nextPlan: $t('label.subscriptionPlan_' + subscription.nextPlan),
          nextPlanDate: subscription.currentInvoicingEndDate
        })"
        class="mb-6"
      />

      <subscription-table :subscription="subscription"/>

    </t-content-card>

    <t-content-card
      :fetch="subscriptionPeriodList.fetch"
      title="label.periods"
    >
      <subscription-period-table :subscriptionPeriodList="subscriptionPeriodList"/>
    </t-content-card>
  </t-page>
</template>

<script>
import useSubscription from '@/app/composables/subscription/useSubscription'
import useSubscriptionPeriodList from '@/app/composables/subscription/useSubscriptionPeriodList'
import useEvent from '@/lib/composables/useEvent'
import useRefresh from '@/lib/composables/useRefresh'

export default {
  data () {
    return {
      subscription: useSubscription(),
      subscriptionPeriodList: useSubscriptionPeriodList()
    }
  },

  created () {
    this.subscription.load()
    this.subscriptionPeriodList.load()

    useEvent().registerListener('subscription-page', 'subscription-save', () => {
      this.subscription.loader.refresh()
      this.subscriptionPeriodList.loader.refresh()
    })

    useRefresh().registerListener('subscription-page', () => {
      this.subscription.loader.backgroundRefresh()
      this.subscriptionPeriodList.loader.backgroundRefresh()
    })
  },

  destroyed () {
    useEvent().unregisterListener('subscription-page')
    useRefresh().unregisterListener('subscription-page')
  }
}
</script>
