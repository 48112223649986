<template>
  <div>
    <v-dialog :value="open" max-width="600px" persistent @keydown.esc="closeDialog">
      <v-card v-show="onTop">

        <v-card-title>
          <span>{{ $t(title) }}</span>
        </v-card-title>

        <v-card-subtitle v-if="subtitle" style="margin-top: -8px; padding-bottom: 0.5rem">
          <span>{{ subtitleText }}</span>
        </v-card-subtitle>

        <div style="height: 4px">
          <v-divider v-if="!waitingForAction"/>
          <v-progress-linear :height="2" v-else indeterminate color="primary"/>
        </div>

        <v-card-text class="mt-2">
          <div
              v-for="message in messages"
              :key="message.message"
          >
            <t-message
                v-if="message.show"
                :message="message.message"
                :color="message.color"
                :image="message.image"
                class="pl-0 pb-4"
            />
          </div>

          <t-form
              ref="form"
              :form-data="item"
              :input-fields="formInputFields"
              :edit-handler="editHandler"
          />

          <v-chip-group v-if="visibleActionChips.length > 0" class="mt-1" column>
            <v-chip
                v-for="(actionChip, index) in visibleActionChips"
                :key="index"
                @click="handleActionChipClick(actionChip)"
                :disabled="waitingForAction || (actionChip.disabledHandler ? actionChip.disabledHandler(item) : false)"
            >

              <t-icon
                  :image="actionChip.icon && actionChip.icon.image ? actionChip.icon.image : actionChip.icon"
                  :class="actionChip.icon && actionChip.icon.color ? 'color-' + actionChip.icon.color : ''"
                  class="mr-2"
              />

              <span>{{ $t(actionChip.label) }}</span>

            </v-chip>
          </v-chip-group>
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1"></div>

          <v-btn
              v-if="!isNew && deleteHandler"
              @click="deleteButtonClicked"
              color="error"
              text
              :disabled="waitingForAction"
              v-text="$t('label.delete')"
          />

          <v-btn
              @click="closeDialog"
              :color="isView ? 'primary' : 'warning'"
              text
              :disabled="waitingForAction"
              v-text="$t(isView ? 'label.close' : 'label.cancel')"
          />

          <v-btn
              v-if="(isNew || isEdit) && saveHandler"
              @click="saveButtonClicked"
              color="primary"
              text
              :disabled="waitingForAction"
          >
            {{ saveButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <t-confirm-dialog
        :confirm-danger="true"
        :confirm-handler="confirmDelete"
        :id="deleteConfirmDialogId"
        title="label.delete"
        message="message.confirmDelete"
    />
  </div>
</template>

<script>
import { v4 as uuid } from 'uuid'
import dialog from '../../mixins/dialog'

export default {
  mixins: [
    dialog
  ],

  props: {
    viewTitle: {
      type: String,
      default: 'label.details'
    },
    editTitle: {
      type: String,
      default: 'label.edit'
    },
    newTitle: {
      type: String,
      default: 'label.new'
    },
    subtitle: {
      type: String
    },
    viewSubtitle: {
      type: String
    },
    editSubtitle: {
      type: String
    },
    newSubtitle: {
      type: String
    },
    saveNewButtonLabel: {
      type: String
    },
    saveEditButtonLabel: {
      type: String
    },
    item: {
      type: Object
    },
    uuidItem: {
      type: Boolean,
      default: false
    },
    copyProperties: {
      type: Array,
      default: () => []
    },
    inputFields: {
      type: Array,
      required: true
    },
    saveHandler: {
      type: Function
    },
    deleteHandler: {
      type: Function
    },
    closeHandler: {
      type: Function,
      default: () => {}
    },
    editHandler: {
      type: Function
    },
    actionChips: {
      type: Array
    },
    readOnlyForEdit: {
      type: Boolean,
      default: false
    },
    messages: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      waitingForAction: false,
      deleteConfirmDialogId: uuid().toString()
    }
  },

  computed: {
    visibleActionChips () {
      if (!this.actionChips) {
        return []
      }

      return this.actionChips.filter(actionChip => {
        if (this.isNew && actionChip.hideForNew) {
          return false
        }
        if (this.isEdit && actionChip.hideForEdit) {
          return false
        }
        if (this.isView && actionChip.hideForView) {
          return false
        }
        if (actionChip.hideHandler && actionChip.hideHandler(this.item)) {
          return false
        }
        return true
      })
    },

    isNew () {
      return (!this.item || (this.uuidItem && !this.item.id)) && this.saveHandler
    },

    isEdit () {
      return !this.isNew && !this.readOnlyForEdit && (this.saveHandler || this.deleteHandler)
    },

    isView () {
      return !this.isNew && this.readOnlyForEdit
    },

    title () {
      if (this.isNew) {
        return this.newTitle
      }
      if (this.isView) {
        return this.viewTitle
      }
      return this.editTitle
    },

    subtitleText () {
      if (this.isNew && this.newSubtitle) {
        return this.$t(this.newSubtitle)
      }
      if (this.isView && this.viewSubtitle) {
        return this.$t(this.viewSubtitle)
      }
      if (this.isEdit && this.editSubtitle) {
        return this.$t(this.editSubtitle)
      }
      if (this.subtitle) {
        return this.$t(this.subtitle)
      }
      return null
    },

    formInputFields () {
      return this.inputFields.map(inputField => {
        const formInputField = {
          ...inputField
        }
        formInputField.key = formInputField.key || inputField.itemProperty
        formInputField.disabledHandler = () => {
          if (this.isView) {
            return true
          }
          if (this.isEdit && (this.readOnlyForEdit || inputField.readOnlyForEdit)) {
            return true
          }
          if (this.isEdit && inputField.disabledHandler) {
            return inputField.disabledHandler(this.item)
          }
          return false
        }
        return formInputField
      })
    },

    saveButtonText () {
      if (this.isNew && this.saveNewButtonLabel) {
        return this.$t(this.saveNewButtonLabel)
      }
      if (this.isEdit && this.saveEditButtonLabel) {
        return this.$t(this.saveEditButtonLabel)
      }
      return this.$t('label.save')
    }
  },

  methods: {
    onOpen () {
      this.$refs.form && this.$refs.form.reset()
      this.openHandler && this.openHandler()
    },

    async saveButtonClicked () {
      const validForm = await this.$refs.form.validate()
      if (validForm) {
        const saveItem = {}
        this.inputFields.forEach(inputField => {
          saveItem[inputField.key] = this.$refs.form.editFormData[inputField.key]
        })

        if (this.uuidItem && this.item && this.item.id) {
          saveItem.id = this.item.id
        } else if (this.uuidItem) {
          saveItem.id = uuid()
        }

        if (this.item && this.copyProperties) {
          this.copyProperties.forEach(copyProperty => {
            saveItem[copyProperty] = this.item[copyProperty]
          })
        }

        const saveHandlerResult = this.saveHandler(saveItem)
        if (saveHandlerResult instanceof Promise) {
          this.waitingForAction = true
          const success = await saveHandlerResult
          this.waitingForAction = false
          if (success) {
            this.closeDialog()
          }
        } else if (saveHandlerResult) {
          this.closeDialog()
        }
      }
    },

    deleteButtonClicked () {
      this.$dialog.openDialog(this.deleteConfirmDialogId)
    },

    async confirmDelete () {
      const deleteHandlerResult = this.deleteHandler(this.item)
      if (deleteHandlerResult instanceof Promise) {
        this.waitingForAction = true
        const success = await deleteHandlerResult
        this.waitingForAction = false
        if (success) {
          this.closeDialog()
        }
      } else {
        this.closeDialog()
      }
    },

    async handleActionChipClick (actionChip) {
      const actionHandlerResult = actionChip.handler(this.item)
      if (actionHandlerResult instanceof Promise) {
        this.waitingForAction = true
        const success = await actionHandlerResult
        this.waitingForAction = false
        if (success && actionChip.closeDialog) {
          this.closeDialog()
        }
      } else if (actionChip.closeDialog) {
        this.closeDialog()
      }
    },

    closeDialog () {
      this.$refs.form && this.$refs.form.resetValidation()
      this.$dialog.closeDialog(this.id, this.closeHandler)
    }
  }
}
</script>
