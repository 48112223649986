<template>
  <t-page>
    <div class="dashboard-container">
      <running-order-dashboard :dashboard="dashboard"/>
      <order-dashboard :dashboard="dashboard"/>
      <topup-dashboard :dashboard="dashboard"/>
      <user-dashboard :dashboard="dashboard"/>
      <device-dashboard :dashboard="dashboard"/>
      <card-dashboard :dashboard="dashboard"/>
    </div>
  </t-page>
</template>

<script>
import useDashboard from '@/app/composables/dashboard/useDashboard'
import useRefresh from '@/lib/composables/useRefresh'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      dashboard: useDashboard()
    }
  },

  created () {
    useRefresh().registerListener('dashboard-page', () => this.dashboard.loader.backgroundRefresh())
    useEvent().registerListener('dashboard-page', 'invoice-paid', () => this.dashboard.loader.refresh())
  },

  destroyed () {
    useRefresh().unregisterListener('dashboard-page')
    useEvent().unregisterListener('dashboard-page')
  }
}
</script>

<style>
.dashboard-container {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  column-gap: 24px;
}

.dashboard-container > * {
  /*margin-right: 0;*/
}

@media (min-width: 768px) {
  .dashboard-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .dashboard-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (min-width: 1600px) {
  .dashboard-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
</style>
