<template>
  <div>
    <div
        v-if="containerItems"
        class="d-flex ml-5"
    >
      <div class="flex-grow-1"></div>
      <v-text-field
          v-if="search"
          v-model="searchText"
          :label="$t('label.search')"
          append-icon="mdi-magnify"
          autocomplete="off"
          class="flex-grow-0 mr-1"
          single-line
      />
    </div>

    <div
        v-if="noItemsLabel && containerItems && !containerItems.length"
        class="color-grey-lighten-1"
    >{{ $t(noItemsLabel) }}
    </div>

    <v-simple-table
        v-if="containerItems && containerItems.length"
        :fixed-header="true"
        class="t-table"
    >
      <tbody v-if="!horizontal">
      <tr>
        <th class="pa-0" style="width: 4px"/>

        <th
            v-for="containerItemProperty in containerItemProperties"
            :key="containerItemProperty.key"
            :class="{ pointer: containerItemProperty.sortable }"
            @click="handleContainerItemPropertyClick(containerItemProperty)"
        >
          <div
              :ref="'header.' + containerItemProperty.key"
              style="display: inline-block; height: 24px">

            <div class="d-inline-flex align-center">
              <span>{{ $t(containerItemProperty.header) }}</span>
              <div v-if="!containerItemProperty.sortAscending && !containerItemProperty.sortDescending" style="height: 24px"/>
              <v-icon v-if="containerItemProperty.sortable && containerItemProperty.sortAscending">mdi-chevron-up</v-icon>
              <v-icon v-if="containerItemProperty.sortable && containerItemProperty.sortDescending">mdi-chevron-down</v-icon>
            </div>
          </div>
        </th>
      </tr>
      <tr
          v-for="containerItem in containerItems"
          :key="containerItem.key"
          :class="{
                'background-color-grey-darken-3': containerItem.selected,
                'pointer': selectable,
                'draggable': containerItem.draggable
               }"
          :draggable="containerItem.draggable"
          @click="handleContainerItemClick(containerItem)"
          @dragover="handleContainerItemDragOver($event, containerItem)"
          @dragstart="handleContainerItemDragStart($event, containerItem)"
          @drop="handleContainerItemDrop(containerItem)"
      >

        <td :class="{ primary: containerItem.selected }" class="pa-0" style="width: 4px"/>

        <td
            v-for="itemProperty in containerItem.itemProperties"
            :key="itemProperty.key"
            :style="itemProperty.logo ? 'padding-left: 12px' : ''"
        >
          <div :ref="'cell.' + itemProperty.key" style="display: inline-block; vertical-align: middle">
            <span :class="{ 'mr-2' : !!itemProperty.icon}">{{ itemProperty.text }}</span>

            <t-icon
                v-if="itemProperty.icon"
                :click-handler="itemProperty.icon.clickHandler ? () => itemProperty.icon.clickHandler(containerItem.item) : null"
                :color="itemProperty.icon.color"
                :disabled="itemProperty.icon.disabledHandler ? itemProperty.icon.disabledHandler(containerItem.item) : false"
                :image="itemProperty.icon.image"
                :tooltip="itemProperty.icon.tooltip"
            />

            <t-logo v-if="itemProperty.logo" :image="itemProperty.logo" class="my-1"/>

            <v-progress-linear
                v-if="itemProperty.progress"
                :buffer-value="itemProperty.progress.stale ? 100 : 0"
                :color="itemProperty.progress.stale ? itemProperty.progress.staleColor ? itemProperty.progress.staleColor : 'grey darken-1' : itemProperty.progress.color"
                :height="itemProperty.progress.height ? itemProperty.progress.height : itemProperty.progress.showLabel ? '25' : undefined"
                :stream="!itemProperty.progress.stale"
                :value="itemProperty.progress.value"
            >
              <template v-if="itemProperty.progress.showLabel" v-slot="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </div>
        </td>
      </tr>
      </tbody>

      <tbody v-if="horizontal">
      <tr v-show="selectionHandler">
        <td style="height: 4px; max-width: 150px;"/>
        <td
            v-for="containerItem in containerItems"
            :key="'selection-' + containerItem.key"
            :class="{ primary: containerItem.selected }"
            style="height: 4px"
        />
      </tr>

      <tr
          v-for="horizontalRow in horizontalRows"
          :key="horizontalRow.key"
      >
        <th
            :class="{ pointer: horizontalRow.containerItemProperty.sortable }"
            @click="handleContainerItemPropertyClick(horizontalRow.containerItemProperty)"
        >
          <div class="d-flex flex-row align-center">
            <span>{{ $t(horizontalRow.containerItemProperty.header) }}</span>
            <div v-if="!horizontalRow.containerItemProperty.sortAscending && !horizontalRow.containerItemProperty.sortDescending" style="height: 24px"/>
            <v-icon v-if="horizontalRow.containerItemProperty.sortable && horizontalRow.containerItemProperty.sortAscending">mdi-chevron-left</v-icon>
            <v-icon v-if="horizontalRow.containerItemProperty.sortable && horizontalRow.containerItemProperty.sortDescending">mdi-chevron-right</v-icon>
          </div>
        </th>

        <td
            v-for="horizontalRowCell in horizontalRow.horizontalRowCells"
            :key="horizontalRowCell.key"
            :class="{
                pointer: selectable,
                draggable: horizontalRowCell.containerItem.draggable
               }"
            :draggable="horizontalRowCell.containerItem.draggable"
            :style="horizontalRowCell.containerItem.itemProperties.find(itemProperty => itemProperty.key === horizontalRow.containerItemProperty.key).logo ? 'padding-left: 12px' : ''"
            @click="handleContainerItemClick(horizontalRowCell.containerItem)"
            @dragover="handleContainerItemDragOver($event, horizontalRowCell.containerItem)"
            @dragstart="handleContainerItemDragStart($event, horizontalRowCell.containerItem)"
            @drop="handleContainerItemDrop(horizontalRowCell.containerItem)"
        >

          <div class="d-flex flex-row align-center">
            <span :class="{ 'mr-2' : !!horizontalRowCell.itemProperty.icon}">{{ horizontalRowCell.itemProperty.text }}</span>

            <t-icon
                v-if="horizontalRowCell.itemProperty.icon"
                :click-handler="horizontalRowCell.itemProperty.icon.clickHandler ? () => horizontalRowCell.itemProperty.icon.clickHandler(horizontalRowCell.containerItem.item) : null"
                :color="horizontalRowCell.itemProperty.icon.color"
                :disabled="horizontalRowCell.itemProperty.icon.disabledHandler ? horizontalRowCell.itemProperty.icon.disabledHandler(horizontalRowCell.containerItem.item) : false"
                :image="horizontalRowCell.itemProperty.icon.image"
                :tooltip="horizontalRowCell.itemProperty.icon.tooltip"
            />

            <t-logo v-if="horizontalRowCell.itemProperty.logo" :image="horizontalRowCell.itemProperty.logo" class="my-1"/>

            <v-progress-linear
                v-if="horizontalRowCell.itemProperty.progress"
                :buffer-value="horizontalRowCell.itemProperty.progress.stale ? 100 : 0"
                :color="horizontalRowCell.itemProperty.progress.stale ? horizontalRowCell.itemProperty.progress.staleColor ? horizontalRowCell.itemProperty.progress.staleColor : 'grey darken-1' : horizontalRowCell.itemProperty.progress.color"
                :height="horizontalRowCell.itemProperty.progress.height ? horizontalRowCell.itemProperty.progress.height : horizontalRowCell.itemProperty.progress.showLabel ? '25' : undefined"
                :stream="!horizontalRowCell.itemProperty.progress.stale"
                :value="horizontalRowCell.itemProperty.progress.value"
            >
              <template v-if="horizontalRowCell.itemProperty.progress.showLabel" v-slot="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </div>
        </td>
      </tr>
      </tbody>
    </v-simple-table>

    <v-pagination
        v-if="paged && pageCount && pageCount > 1"
        v-model="page"
        :length="pageCount"
        :total-visible="10"
    ></v-pagination>
  </div>
</template>

<script>
import itemContainer from '../../mixins/itemContainer'

export default {
  mixins: [itemContainer],

  props: {
    horizontal: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    horizontalRows () {
      if (!this.horizontal) {
        return null
      }
      return this.containerItemProperties.map(containerItemProperty => {
        const horizontalRowCells = this.containerItems.map(containerItem => {
          const itemProperty = containerItem.itemProperties.find(itemProperty => itemProperty.key === containerItemProperty.key)
          return {
            key: containerItem.key,
            containerItem,
            itemProperty
          }
        })

        return {
          key: containerItemProperty.key,
          containerItemProperty,
          horizontalRowCells
        }
      })
    }
  },

  created () {
    this.updateColumnAlignment()
  },

  watch: {
    containerItemProperties () {
      this.updateColumnAlignment()
    },

    containerItems () {
      this.updateColumnAlignment()
    }
  },

  methods: {
    updateColumnAlignment () {
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$nextTick(() => {
            if (!this.containerItemProperties || !this.containerItemProperties.length) {
              return
            }

            this.containerItemProperties
              .filter(containerItemProperty => Array.isArray(this.$refs['header.' + containerItemProperty.key]))
              .filter(containerItemProperty => this.$refs['header.' + containerItemProperty.key].length > 0)
              .forEach(containerItemProperty => {
                const headerElement = this.$refs['header.' + containerItemProperty.key][0]
                const cellElements = this.$refs['cell.' + containerItemProperty.key] || []

                if (!headerElement.tOriginalSize) {
                  headerElement.tOriginalSize = headerElement.clientWidth
                }
                cellElements.forEach(cellElement => {
                  if (!cellElement.tOriginalSize) {
                    cellElement.tOriginalSize = cellElement.clientWidth
                  }
                })

                let headerWidth = headerElement.tOriginalSize
                let maxCellWidth = Math.max(...cellElements.map(cellElement => cellElement.tOriginalSize))

                // add 25px for sorting icon
                headerWidth += 25

                const itemProperty = containerItemProperty.itemProperty
                const itemPropertyType = itemProperty.type
                const itemPropertyIcon = itemProperty.icon

                if (itemPropertyIcon) {
                  maxCellWidth += 25
                }

                const columnWidth = Math.max(headerWidth, maxCellWidth) + 1
                const widthStyle = columnWidth + 'px'
                let alignment = null
                if (itemPropertyType === 'number' || itemPropertyType === 'amount' || itemPropertyType === 'percentage' || itemPropertyIcon) {
                  alignment = 'right'
                }

                headerElement.style.minWidth = widthStyle
                headerElement.style.textAlign = alignment
                cellElements.forEach(cellElement => {
                  cellElement.style.minWidth = widthStyle
                  cellElement.style.textAlign = alignment
                })
              })
          })
        })
      })
    }
  }
}
</script>

<style>
.t-table * .pointer {
  cursor: pointer;
}

.t-table > .v-data-table__wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 4px #424242 !important;
  background-color: #424242 !important;
}

.t-table > .v-data-table__wrapper::-webkit-scrollbar {
  width: 4px !important;
  height: 6px !important;
  background-color: #424242 !important;
}

.t-table > .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background-color: #9E9E9E !important;
}

.v-pagination__item--active {
  color: black !important;
}

.disabled * {
  pointer-events: none;
}
</style>
