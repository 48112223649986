<template>
  <div>
    <t-line-chart
      :labels="[
        'label.january',
        'label.february',
        'label.march',
        'label.april',
        'label.may',
        'label.june',
        'label.july',
        'label.august',
        'label.september',
        'label.october',
        'label.november',
        'label.december',
      ]"
      :values="[
        report.januarySales,
        report.februarySales,
        report.marchSales,
        report.aprilSales,
        report.maySales,
        report.juneSales,
        report.julySales,
        report.augustSales,
        report.septemberSales,
        report.octoberSales,
        report.novemberSales,
        report.decemberSales,
      ]"
    />

    <t-table
      class="mt-8"
      :items="[
          {
            month: 'january',
            monthNumber: 1,
            sales:report.januarySales
          },
          {
            month: 'february',
            monthNumber: 2,
            sales:report.februarySales
          },
          {
            month: 'march',
            monthNumber: 3,
            sales:report.marchSales
          },
          {
            month: 'april',
            monthNumber: 4,
            sales:report.aprilSales
          },
          {
            month: 'may',
            monthNumber: 5,
            sales:report.maySales
          },
          {
            month: 'june',
            monthNumber: 6,
            sales:report.juneSales
          },
          {
            month: 'july',
            monthNumber: 7,
            sales:report.julySales
          },
          {
            month: 'august',
            monthNumber: 8,
            sales:report.augustSales
          },
          {
            month: 'september',
            monthNumber: 9,
            sales:report.septemberSales
          },
          {
            month: 'october',
            monthNumber: 10,
            sales:report.octoberSales
          },
          {
            month: 'november',
            monthNumber: 11,
            sales:report.novemberSales
          },
          {
            month: 'december',
            monthNumber: 12,
            sales:report.decemberSales
          }
        ]"
      item-key="key"
      :item-properties="[
          {
            key: 'month',
            text: item => $t('label.' + item.month),
            value: item => item.monthNumber,
            sortable: true
          },
          {
            key: 'sales',
            type: 'number',
            header: 'label.count',
            sortable: true
          }
        ]"
      default-sort-property-key="month"
    />

    <t-table
      class="mt-8"
      :items="report.products"
      item-key="productName"
      :item-properties="[
          {
            key: 'productName',
            header: 'label.product',
            sortable: true
          },
          {
            key: 'januarySales',
            header: 'label.january',
            type: 'number',
            sortable: true
          },
          {
            key: 'februarySales',
            header: 'label.february',
            type: 'number',
            sortable: true
          },
          {
            key: 'marchSales',
            header: 'label.march',
            type: 'number',
            sortable: true
          },
          {
            key: 'aprilSales',
            header: 'label.april',
            type: 'number',
            sortable: true
          },
          {
            key: 'maySales',
            header: 'label.may',
            type: 'number',
            sortable: true
          },
          {
            key: 'juneSales',
            header: 'label.june',
            type: 'number',
            sortable: true
          },
          {
            key: 'julySales',
            header: 'label.july',
            type: 'number',
            sortable: true
          },
          {
            key: 'augustSales',
            header: 'label.august',
            type: 'number',
            sortable: true
          },
          {
            key: 'septemberSales',
            header: 'label.september',
            type: 'number',
            sortable: true
          },
          {
            key: 'octoberSales',
            header: 'label.october',
            type: 'number',
            sortable: true
          },
          {
            key: 'novemberSales',
            header: 'label.november',
            type: 'number',
            sortable: true
          },
          {
            key: 'decemberSales',
            header: 'label.december',
            type: 'number',
            sortable: true
          },
        ]"
      default-sort-property-key="productName"
    />
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Object
    }
  }
}
</script>
