var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"subscription","action-chips":[
    {
      icon: 'mdi-lead-pencil',
      label: 'label.invoicingSettings',
      handler: () => _vm.$dialog.openDialog('invoicing-settings'),
      hideHandler: () =>! this.subscriptionPlanList.invoicingSettingsNeeded
    }
  ],"input-fields":[
      {
        key: 'editPlan',
        label: 'label.plan',
        type: 'list-select',
        items: _vm.subscriptionPlanList.subscriptionPlans,
        itemKey: 'id',
        itemText: 'label',
        itemDescription: 'description',
        itemDisabled: subscriptionPlan => subscriptionPlan.disabled
      }
    ],"item":_vm.subscription,"open-handler":_vm.handleOnOpen,"save-handler":_vm.handleSave,"subtitle":_vm.subtitle}})
}
var staticRenderFns = []

export { render, staticRenderFns }