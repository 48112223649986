import moment from 'moment-timezone'
import $authorization from './authorization'

const $formatter = {
  formatDate (value) {
    if (value) {
      if ($authorization.user.timezone) {
        return moment.utc(value).tz($authorization.user.timezone).format('YYYY-MM-DD')
      }
      return moment.utc(value).local().format('YYYY-MM-DD')
    }
    return ''
  },

  formatTime (value) {
    if (value) {
      if ($authorization.user.timezone) {
        return moment.utc(value).tz($authorization.user.timezone).format('HH:mm:ss')
      }
      return moment.utc(value).local().format('HH:mm:ss')
    }
    return ''
  },

  formatDateTime (value) {
    if (value) {
      if ($authorization.user.timezone) {
        return moment.utc(value).tz($authorization.user.timezone).format('YYYY-MM-DD HH:mm:ss')
      }
      return moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss')
    }
    return ''
  },

  formatTimestamp (value) {
    if (value) {
      if ($authorization.user.timezone) {
        return moment.utc(value).tz($authorization.user.timezone).format('YYYY-MM-DD HH:mm:ss SSS')
      }
      return moment.utc(value).local().format('YYYY-MM-DD HH:mm:ss SSS')
    }
    return ''
  },

  formatAmount (value) {
    if (!isNaN(value)) {
      if (Number.isInteger(value)) {
        return '€ ' + value
      }
      return '€ ' + value.toFixed(2).replace('.', ',')
    }
    return ''
  },

  formatPercentage (value) {
    if (value && !isNaN(value)) {
      return value.toFixed(2) + '%'
    }
    return ''
  },

  formatUuid (value) {
    if (value) {
      let formattedValue = value.toUpperCase()
      formattedValue = `${formattedValue.substring(0, 4)} ${formattedValue.substring(4, 8)} ${formattedValue.substring(9, 13)} ${formattedValue.substring(14, 18)} ${formattedValue.substring(19, 23)} ${formattedValue.substring(24, 28)} ${formattedValue.substring(28, 32)} ${formattedValue.substring(32, 36)}`
      return formattedValue
    }
    return ''
  }
}

export default $formatter
