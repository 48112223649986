<template>
  <v-text-field
    ref="textField"
    :label="label"
    :value="internalValue"
    @input="handleOnInput"
    @blur="handleOnBlur"
    autocomplete="off"
    :disabled="disabled"
    :error-messages="errorMessages"
    :autofocus="autofocus"
  />
</template>

<script>
import inputField from '../../mixins/inputField'

export default {
  mixins: [
    inputField
  ],

  methods: {
    initInternalValue () {
      this.handleOnInput(this.value)
    },

    emitValue () {
      this.$emit('input', this.getInternalValueAsUuid())
    },

    handleOnInput (value) {
      if (!value) {
        this.internalValue = null
        if (this.$refs.textField) {
          this.$refs.textField.lazyValue = null
        }
        return
      }

      value = value.replace(/[^0-9a-zA-Z]/g, '')
      value = value.toUpperCase()

      const uuidGroups = []
      for (let i = 0; i < value.length; i++) {
        const valueChar = value.charAt(i)
        if (i % 4 === 0) {
          uuidGroups.push([valueChar])
        } else {
          uuidGroups[uuidGroups.length - 1].push([valueChar])
        }
      }

      if (uuidGroups.length > 8) {
        uuidGroups.splice(8)
      }

      let formattedValue = ''
      uuidGroups.forEach(uuidGroup => {
        if (formattedValue) {
          formattedValue += ' '
        }
        uuidGroup.forEach(uuidChar => {
          formattedValue += uuidChar
        })
      })

      this.internalValue = formattedValue
      if (this.$refs.textField) {
        this.$refs.textField.lazyValue = formattedValue
      }

      if (formattedValue.length === 39) {
        this.emitValue()
        this.validate()
      } else {
        this.errorMessages = []
      }
    },

    handleOnBlur () {
      this.emitValue()
    },

    validateValue (value) {
      if (!/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(value)) {
        this.errorMessages = [this.$t('validation.invalidUuid')]
        return false
      }
      return true
    },

    getInternalValueAsUuid () {
      if (!this.internalValue) {
        return null
      }

      let value = this.internalValue
      value = value.replace(/[^0-9a-zA-Z]/g, '')
      value = value.toLowerCase()

      let uuidValue = ''
      for (let i = 0; i < value.length; i++) {
        const valueChar = value.charAt(i)
        if (uuidValue.length === 8 || uuidValue.length === 13 || uuidValue.length === 18 || uuidValue.length === 23) {
          uuidValue += '-'
        }
        uuidValue += valueChar
      }

      return uuidValue
    }
  }
}
</script>
