import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useEntity from '@/lib/composables/useEntity'

const tenantSelection = useTenantSelection()

export default function () {
  const membershipRequestTemplate = {
    id: null,
    userEmail: null
  }

  const membershipRequest = useEntity(membershipRequestTemplate)

  membershipRequest.save = async (editMembershipRequest, onError = null) => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/membership-requests/${editMembershipRequest.id}`, {
      body: {
        userEmail: editMembershipRequest.userEmail
      },
      onError: onError
    })
    if (await fetch.promise) {
      membershipRequest.setData(fetch.data)
      useEvent().fire('membership-request-save', membershipRequest)
    }
  }

  membershipRequest.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/membership-requests/${membershipRequest.id}`)
    if (await fetch.promise) {
      useEvent().fire('membership-request-delete', membershipRequest)
    }
  }

  return membershipRequest
}
