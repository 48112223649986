var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"item-properties":[
        {
          key: 'userEmail',
          header: 'label.email',
          sortable: true
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: membershipRequest => _vm.$dialog.openDialog('user-membership-request', membershipRequest)
        }
      ],"items":_vm.membershipRequestList.membershipRequests,"default-sort-property-key":"userEmail","item-key":"id","no-items-label":"message.noDataFound","search":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }