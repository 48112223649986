import Vue from 'vue'

export default function () {
  const loader = Vue.observable({
    loading: false,
    error: false,
    loaded: false,
    handler: null,
    promise: null,
    promiseResolve: null
  })

  loader.init = async () => {
    if (!loader.loaded) {
      await loader.refresh()
    }
  }

  loader.load = async handler => {
    loader.handler = handler
    loader.promise && await loader.promise
    if (loader.handler === handler) {
      await loader.refresh()
    }
  }

  loader.refresh = async () => {
    if (!loader.handler) {
      return
    }

    loader.promise && await loader.promise
    loader.promise = new Promise(resolve => {
      loader.promiseResolve = resolve
    })

    loader.loading = true
    try {
      await loader.handler(false)
      loader.error = false
      loader.loaded = true
    } catch (e) {
      console.error(e)
      loader.error = true
    }
    loader.loading = false

    loader.promiseResolve()
  }

  loader.backgroundRefresh = async () => {
    if (!loader.handler || !loader.loaded) {
      return
    }

    loader.promise && await loader.promise
    loader.promise = new Promise(resolve => {
      loader.promiseResolve = resolve
    })

    try {
      await loader.handler(true)
    } catch (e) {
      console.error(e)
      loader.error = true
    }

    loader.promiseResolve()
  }

  return loader
}
