<template>
  <t-content-card
    title="label.devices"
    :fetch="dashboard.fetch"
  >
    <t-table
      :items="[dashboard.device]"
      item-key="id"
      :item-properties="[
            {
              key: 'devices',
              type: 'number'
            },
            {
              key: 'scanners',
              type: 'number'
            },
            {
              key: 'cashiers',
              type: 'number'
            },
            {
              key: 'onlineDevices',
              type: 'number'
            },
            {
              key: 'onlineScanners',
              type: 'number'
            },
            {
              key: 'onlineCashiers',
              type: 'number'
            }
          ]"
      :horizontal="true"
    />
  </t-content-card>
</template>

<script>
export default {
  props: {
    dashboard: {
      required: true
    }
  }
}
</script>
