var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"user-membership-request","delete-handler":_vm.membershipRequest.delete,"input-fields":[
        {
          key: 'userEmail',
          type: 'text',
          label: 'label.email',
          required: true,
          readOnlyForEdit: true,
          regex: 'email'
        }
      ],"item":_vm.membershipRequest,"save-handler":_vm.onSave,"read-only-for-edit":true,"uuid-item":true,"open-handler":_vm.onOpen,"messages":[
    {
      message: _vm.$t('message.membershipRequestAlreadySent'),
      color: 'warning',
      show: _vm.membershipRequestError === 'EXISTING_REQUEST'
    },
    {
      message: _vm.$t('message.membershipRequestForMember'),
      color: 'warning',
      show: _vm.membershipRequestError === 'EXISTING_MEMBER'
    }
  ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }