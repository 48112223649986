<template>
  <t-edit-dialog
    id="device-link"
    :input-fields="[
          {
            key: 'type',
            type: 'text',
            label: 'label.type',
            readOnly: true
          },
          {
            key: 'model',
            type: 'text',
            label: 'label.model',
            readOnly: true
          },
          {
            key: 'serial',
            type: 'text',
            label: 'label.serialNumber',
            required: true
          },
          {
            key: 'name',
            type: 'text',
            label: 'label.name',
            required: true
          },
          {
            key: 'pincode',
            type: 'text',
            label: 'label.pincode',
            required: true,
            numeric: true
          },
          {
            key: 'tenantShortName',
            type: 'text',
            label: 'label.shortenedClubName',
            required: true
          },
          {
            key: 'locale',
            type: 'select',
            label: 'label.locale',
            items: [
              {
                name: 'NL',
                locale: 'nl-BE'
              },
              {
                name: 'EN',
                locale: 'en-US'
              }
            ],
            itemValue: 'locale',
            itemText: 'name',
            required: true
          }
        ]"
    :item="device"
    :open-handler="onOpen"
    :save-handler="handleStartLink"
    action-button-label="label.start"
    edit-title="label.linkDevice"
    new-title="label.linkDevice"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useDevice from '@/app/composables/device/useDevice'
import useDeviceLink from '@/app/composables/device/useDeviceLink'

export default {
  data () {
    return {
      device: useDevice(),
      deviceLink: useDeviceLink()
    }
  },

  created () {
    useEvent().registerListener('device-link-dialog', 'device-linked', () => this.$dialog.closeDialog('device-link'))
  },

  destroyed () {
    useEvent().unregisterListener('device-link-dialog')
  },

  methods: {
    async handleStartLink (editDevice) {
      await this.deviceLink.startProcess(editDevice)
      this.$dialog.openDialog('device-link-process', this.deviceLink)
    },

    onOpen () {
      this.device.setData(this.$dialog.getDialogData('device-link'))
    }
  }
}
</script>
