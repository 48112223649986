import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useLoader from '@/lib/composables/useLoader'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'

const tenantSelection = useTenantSelection()

export default function () {
  const unpaidInvoiceCount = Vue.observable({
    loader: useLoader(),
    numberOfInvoices: null,
    fetch: null,
    url: null
  })

  unpaidInvoiceCount.load = async () => {
    await unpaidInvoiceCount.loader.load(async background => {
      if (background) {
        await unpaidInvoiceCount.fetch.executeFetch()
        unpaidInvoiceCount.numberOfInvoices = parseInt(unpaidInvoiceCount.fetch.data || 0)
      } else {
        unpaidInvoiceCount.fetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/invoices/count?paid=false`)
        await unpaidInvoiceCount.fetch.promise
        unpaidInvoiceCount.numberOfInvoices = parseInt(unpaidInvoiceCount.fetch.data || 0)
      }
    })
  }

  return unpaidInvoiceCount
}
