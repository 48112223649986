var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"reward-definition","delete-handler":_vm.rewardDefinition.delete,"input-fields":[
      {
        key: 'name',
        type: 'text',
        label: 'label.name',
        required: true
      },
      {
        key: 'amount',
        type: 'amount',
        label: 'label.amount',
        required: true
      },
    ],"item":_vm.rewardDefinition,"save-handler":_vm.rewardDefinition.save,"uuid-item":true,"open-handler":_vm.onOpen}})
}
var staticRenderFns = []

export { render, staticRenderFns }