import Vue from 'vue'
import moment from 'moment'
import { v4 as uuid } from 'uuid'

const $logger = Vue.observable({
  level: 'DEBUG',
  messages: [],

  debug (...messages) {
    if ($logger.level === 'DEBUG') {
      $logger.log('DEBUG', ...messages)
    }
  },

  info (...messages) {
    if ($logger.level === 'DEBUG' || $logger.level === 'INFO') {
      $logger.log('INFO', ...messages)
    }
  },

  error (...messages) {
    if ($logger.level === 'DEBUG' || $logger.level === 'INFO' || $logger.level === 'ERROR') {
      $logger.log('ERROR', ...messages)
    }
  },

  log (level, ...messages) {
    console.log(...messages)

    const message = messages.reduce((previous, current) => previous ? `${previous} ${JSON.stringify(current)}` : JSON.stringify(current))
    $logger.messages.push({
      id: uuid(),
      level: level,
      message: message,
      timestamp: moment()
    })

    if ($logger.messages.length > 100) {
      $logger.messages.shift()
    }
  }
})

export default $logger
