var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"items":[_vm.subscription],"item-key":"tenantId","item-properties":[
        {
          key: 'plan',
          value: subscription => _vm.$t('label.subscriptionPlan_' + subscription.plan)
        },
        {
          key: 'subscriptionDate',
          type: 'date'
        },
        {
          key: 'currentInvoicingBeginDate',
          type: 'date',
          visible: () => _vm.subscription.plan !== 'FREE'
        },
        {
          key: 'currentInvoicingEndDate',
          type: 'date',
          visible: () => _vm.subscription.plan !== 'FREE'
        },
        {
          key: 'currentInvoicingOrders',
          type: 'number',
          visible: () => _vm.subscription.plan !== 'FREE'
        },
        {
          key: 'remainingFreeOrders',
          type: 'number',
          visible: () => _vm.subscription.plan === 'FREE'
        }
      ],"horizontal":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }