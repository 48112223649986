var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"product-category","item":_vm.productCategory,"uuid-item":true,"save-handler":_vm.productCategory.save,"delete-handler":_vm.productCategory.delete,"input-fields":[
      {
        key: 'name',
        type: 'text',
        label: 'label.name',
        required: true
      }
    ],"open-handler":_vm.handleOnOpen}})
}
var staticRenderFns = []

export { render, staticRenderFns }