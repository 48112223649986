var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"items":_vm.productList.products,"item-key":"id","item-properties":[
        {
          key: 'favorite',
          type: 'icon',
          icon: product => product.favorite ? { image: 'mdi-heart', color: 'primary' } : 'mdi-heart-outline',
          iconClickHandler: product => product.toggleFavorite()
        },
        {
          key: 'logo',
          type: 'logo'
        },
        {
          key: 'name'
        },
        {
          key: 'price',
          type: 'amount'
        },
        {
          key: 'disabled',
          type: 'icon',
          icon: product => product.disabled ? { image: 'mdi-food-off', color: 'error' } : null
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: product => _vm.event.fire('product-edit', product)
        }
      ],"drag-data-type":"product","drop-data-types":['product'],"drop-handler":_vm.productDropHandler,"no-items-label":"message.noProducts"}})
}
var staticRenderFns = []

export { render, staticRenderFns }