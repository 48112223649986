export default {
  props: {
    id: {
      type: String,
      required: true
    },
    openHandler: {
      type: Function
    },
    closeHandler: {
      type: Function
    }
  },

  data () {
    return {
      actionRunning: false
    }
  },

  computed: {
    open () {
      return this.$dialog.isDialogOpen(this.id)
    },

    onTop () {
      return this.open && this.$dialog.isDialogOpen(this.id)
    }
  },

  watch: {
    open (newOpen, oldOpen) {
      if (!oldOpen && newOpen) {
        this.onOpen()
      }
    }
  },

  created () {
    if (this.open) {
      this.onOpen()
    }
  },

  methods: {
    onOpen () {
      this.openHandler && this.openHandler()
    },

    close () {
      this.$dialog.closeDialog(this.id, this.closeHandler)
    },

    async runAction (action) {
      this.actionRunning = true
      try {
        return await action()
      } finally {
        this.actionRunning = false
      }
    }
  }
}
