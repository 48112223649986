<template>
  <t-selection-dialog
    :items="userGroupList.userGroups"
    :selection-handler="userGroup => selectionHandler && selectionHandler(userGroup)"
    id="user-group-select"
    item-key="id"
    item-text="name"
    title="message.selectUserGroup"
  />
</template>

<script>
import useUserGroupList from '@/app/composables/user/useUserGroupList'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      userGroupList: useUserGroupList(),
      event: useEvent(),
      selectionHandler: null
    }
  },

  created () {
    useEvent().registerListener(this, 'user-group-select', selectionHandler => {
      this.selectionHandler = selectionHandler
      this.userGroupList.load()
      this.$dialog.openDialog('user-group-select')
    })
  }
}
</script>
