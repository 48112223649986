var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-page',[_c('t-content-card',{attrs:{"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: () => _vm.$dialog.openDialog('reward-definition')
      }
    ],"fetch":_vm.rewardDefinitionList.fetch,"title":"label.commonlyUsedDefinitions"}},[_c('reward-definition-table',{attrs:{"reward-definition-list":_vm.rewardDefinitionList}})],1),_c('t-content-card',{attrs:{"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: reward => _vm.$dialog.openDialog('reward', reward)
      }
    ],"fetch":_vm.rewardSearch.fetch,"title":"label.rewards"}},[_c('reward-table',{attrs:{"reward-search":_vm.rewardSearch}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }