import { render, staticRenderFns } from "./SettingsPage.vue?vue&type=template&id=15d53b49&scoped=true&"
import script from "./SettingsPage.vue?vue&type=script&lang=js&"
export * from "./SettingsPage.vue?vue&type=script&lang=js&"
import style0 from "./SettingsPage.vue?vue&type=style&index=0&id=15d53b49&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d53b49",
  null
  
)

export default component.exports