import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useEntity from '@/lib/composables/useEntity'

const tenantSelection = useTenantSelection()

export default function () {
  const productTemplate = {
    loader: null,
    id: null,
    name: null,
    categoryId: null,
    price: null,
    logoId: null,
    favorite: null,
    favoriteSequenceNumber: null,
    sequenceNumber: null,
    logo: null,
    disabled: null
  }

  const product = useEntity(productTemplate, product => {
    product.logo = product.logoId ? `${process.env.VUE_APP_TOPUPZ_URL}/product-logos/${product.logoId}` : null
  })

  product.save = async editProduct => {
    console.log('save', editProduct)
    let logo
    let logoId
    if (editProduct.logo.startsWith('data:image/png;base64,')) {
      logo = editProduct.logo.replace('data:image/png;base64,', '')
    } else if (editProduct.logo.startsWith(`${process.env.VUE_APP_TOPUPZ_URL}/product-logos/`)) {
      logoId = editProduct.logo.replace(`${process.env.VUE_APP_TOPUPZ_URL}/product-logos/`, '')
    }

    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/products/${editProduct.id}`, {
      body: {
        name: editProduct.name,
        categoryId: editProduct.categoryId,
        price: editProduct.price,
        favorite: editProduct.favorite,
        disabled: editProduct.disabled,
        logoId,
        logo
      }
    })
    if (await fetch.promise) {
      product.setData(fetch.data)
      useEvent().fire('product-save', product)
    }
  }

  product.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/products/${product.id}`)
    if (await fetch.promise) {
      useEvent().fire('product-delete', product)
    }
  }

  product.move = async sequenceNumber => {
    const fetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/products/${product.id}/move`, {
      body: {
        sequenceNumber
      }
    })
    if (await fetch.promise) {
      useEvent().fire('product-save', product)
    }
  }

  product.moveFavorite = async favoriteSequenceNumber => {
    const fetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/products/${product.id}/move-favorite`, {
      body: {
        favoriteSequenceNumber
      }
    })
    if (await fetch.promise) {
      useEvent().fire('product-save', product)
    }
  }

  product.toggleFavorite = async () => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/products/${product.id}`, {
      body: {
        name: product.name,
        categoryId: product.categoryId,
        price: product.price,
        logoId: product.logoId,
        favorite: !product.favorite,
        disabled: product.disabled
      }
    })
    if (await fetch.promise) {
      useEvent().fire('product-save', product)
    }
  }

  return product
}
