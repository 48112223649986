<template>
  <t-edit-dialog
    id="reward-definition"
    :delete-handler="rewardDefinition.delete"
    :input-fields="[
        {
          key: 'name',
          type: 'text',
          label: 'label.name',
          required: true
        },
        {
          key: 'amount',
          type: 'amount',
          label: 'label.amount',
          required: true
        },
      ]"
    :item="rewardDefinition"
    :save-handler="rewardDefinition.save"
    :uuid-item="true"
    :open-handler="onOpen"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useRewardDefinition from '@/app/composables/reward/useRewardDefinition'

export default {
  data () {
    return {
      rewardDefinition: useRewardDefinition()
    }
  },

  created () {
    useEvent().registerListener('reward-definition-dialog', ['reward-definition-save', 'reward-definition-delete'], () => {
      this.$dialog.closeDialog('reward')
    })
  },

  destroyed () {
    useEvent().unregisterListener('reward-definition-dialog')
  },

  methods: {
    onOpen () {
      this.rewardDefinition.setData(this.$dialog.getDialogData('reward-definition'))
    }
  }
}
</script>
