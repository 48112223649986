<template>
  <t-edit-dialog
    id="order"
    :input-fields="[
        {
          key: 'dateTime',
          type: 'date-time'
        },
        {
          key: 'totalAmount',
          type: 'amount'
        },
        {
          key: 'items',
          label: 'label.products',
          type: 'list',
          itemKey: 'productId',
          itemText: item => `${item.quantity} X ${item.productName} (${$formatter.formatAmount(item.unitPrice)})`,
          inputFields: []
        }
      ]"
    :item="order"
    :open-handler="onOpen"
    :read-only-for-edit="true"
  />
</template>

<script>
export default {
  data () {
    return {
      order: null
    }
  },

  methods: {
    onOpen () {
      this.order = this.$dialog.getDialogData('order')
    }
  }
}
</script>
