<template>
  <t-page>
    <t-content-card
      :fetch="invoiceList.fetch"
      title="label.invoices"
    >
      <invoice-table :invoice-list="invoiceList"/>
    </t-content-card>
  </t-page>
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useRefresh from '@/lib/composables/useRefresh'
import useInvoiceList from '@/app/composables/invoice/useInvoiceList'

export default {
  data () {
    return {
      invoiceList: useInvoiceList()
    }
  },

  created () {
    this.invoiceList.load()
    useRefresh().registerListener('invoice-page', () => this.invoiceList.loader.backgroundRefresh())
    useEvent().registerListener('invoice-page', 'invoice-approve', () => this.invoiceList.loader.refresh())
    useEvent().registerListener('invoice-page', 'invoice-paid', () => this.invoiceList.loader.refresh())
    useEvent().registerListener('invoice-page', 'invoice-delete', () => this.invoiceList.loader.refresh())
    useEvent().registerListener('invoice-page', 'invoice-save', () => this.invoiceList.loader.refresh())
  },

  destroyed () {
    useRefresh().unregisterListener('invoice-page')
    useEvent().unregisterListener('invoice-page')
  }
}
</script>
