<template>
  <t-table
    :default-sort-ascending="false"
    :item-filtering-handler="rewardSearch.search"
    :item-properties="[
          {
            key: 'dateTime',
            type: 'date-time',
            sortable: true
          },
          {
            key: 'reason',
            sortable: true
          },
          {
            header: 'label.numberOfGrantees',
            value: (item) => item.grantees.length,
            type: 'number'
          },
          {
            key: 'amount',
            header: 'label.amountEach',
            type: 'amount',
            sortable: true
          },
          {
            key: 'grantedByUserId',
            sortable: true
          },
          {
            key: 'assigned',
            type: 'icon',
            icon: reward => reward.assigned ? { image: 'mdi-check-circle', color: 'primary' } : { image: 'mdi-timer-outline', color: 'primary', tooltip: 'label.waitPeriod' },
            sortable: true
          },
          {
            header: 'label.details',
            icon: reward => reward.assigned === true ? 'mdi-eye' : 'mdi-lead-pencil',
            iconClickHandler: reward => $dialog.openDialog('reward', reward)
          }
        ]"
    :items="rewardSearch"
    :search="true"
    default-sort-property-key="dateTime"
    item-key="id"
    no-items-label="message.noRewards"
  />
</template>

<script>
export default {
  props: {
    rewardSearch: {
      required: true
    }
  }
}
</script>
