<template>
  <t-content-card
    title="label.users"
    :fetch="dashboard.fetch"
  >
    <t-table
      :items="[dashboard.user]"
      item-key="id"
      :item-properties="[
            {
              key: 'users',
              type: 'number'
            },
            {
              key: 'administrators',
              type: 'number'
            },
            {
              key: 'disabled',
              type: 'number'
            }
          ]"
      :horizontal="true"
    />
  </t-content-card>
</template>

<script>
export default {
  props: {
    dashboard: {
      required: true
    }
  }
}
</script>
