<template>
  <div>
    <t-line-chart
      :labels="[
        'label.monday',
        'label.tuesday',
        'label.wednesday',
        'label.thursday',
        'label.friday',
        'label.saturday',
        'label.sunday',
      ]"
      :values="[
        report.mondayRevenue,
        report.tuesdayRevenue,
        report.wednesdayRevenue,
        report.thursdayRevenue,
        report.fridayRevenue,
        report.saturdayRevenue,
        report.sundayRevenue,
      ]"
      value-type="amount"
    />

    <t-table
      class="mt-8"
      :items="[
          {
            day: 'monday',
            dayNumber: 1,
            revenue: report.mondayRevenue
          },
          {
            day: 'tuesday',
            dayNumber: 2,
            revenue: report.tuesdayRevenue
          },
          {
            day: 'wednesday',
            dayNumber: 3,
            revenue: report.wednesdayRevenue
          },
          {
            day: 'thursday',
            dayNumber: 4,
            revenue: report.thursdayRevenue
          },
          {
            day: 'friday',
            dayNumber: 5,
            revenue: report.fridayRevenue
          },
          {
            day: 'saturday',
            dayNumber: 6,
            revenue: report.saturdayRevenue
          },
          {
            day: 'sunday',
            dayNumber: 7,
            revenue: report.sundayRevenue
          },
        ]"
      item-key="key"
      :item-properties="[
          {
            key: 'day',
            text: item => $t('label.' + item.day),
            value: item => item.dayNumber,
            sortable: true
          },
          {
            key: 'revenue',
            type: 'amount',
            header: 'label.amount',
            sortable: true
          }
        ]"
      default-sort-property-key="day"
    />

    <t-table
      class="mt-8"
      :items="report.products"
      item-key="productName"
      :item-properties="[
          {
            key: 'productName',
            header: 'label.product',
            sortable: true
          },
          {
            key: 'mondayRevenue',
            header: 'label.monday',
            type: 'amount',
            sortable: true
          },
          {
            key: 'tuesdayRevenue',
            header: 'label.tuesday',
            type: 'amount',
            sortable: true
          },
          {
            key: 'wednesdayRevenue',
            header: 'label.wednesday',
            type: 'amount',
            sortable: true
          },
          {
            key: 'thursdayRevenue',
            header: 'label.thursday',
            type: 'amount',
            sortable: true
          },
          {
            key: 'fridayRevenue',
            header: 'label.friday',
            type: 'amount',
            sortable: true
          },
          {
            key: 'saturdayRevenue',
            header: 'label.saturday',
            type: 'amount',
            sortable: true
          },
          {
            key: 'sundayRevenue',
            header: 'label.sunday',
            type: 'amount',
            sortable: true
          },
        ]"
      default-sort-property-key="productName"
    />
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Object
    }
  }
}
</script>
