import useTranslate from '@/lib/composables/useTranslate'

const translate = useTranslate()

export default function () {
  return {
    items: [
      {
        key: 'ORDER',
        label: translate.label.order
      },
      {
        key: 'PAYMENT',
        label: translate.label.payment
      }
    ],

    getLabel (key) {
      return this.items.find(item => item.key === key).label
    }
  }
}
