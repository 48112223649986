import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useLoader from '@/lib/composables/useLoader'
import useProductCategory from '@/app/composables/product/useProductCategory'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useProductList from '@/app/composables/product/useProductList'
import $items from '@/lib/items'

const tenantSelection = useTenantSelection()

export default function () {
  const productCategoryList = Vue.observable({
    loader: useLoader(),
    productCategories: [],
    fetch: null
  })

  productCategoryList.setData = data => {
    productCategoryList.productCategories = data.map(productCategoryData => {
      const productCategory = useProductCategory()
      productCategory.setData(productCategoryData)
      return productCategory
    })
  }

  productCategoryList.loadWithFavorites = async () => {
    await productCategoryList.loader.load(async () => {
      productCategoryList.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/product-categories`)
      await productCategoryList.fetch.promise

      const productCategories = productCategoryList.fetch.data.slice()
      $items.sortItems(productCategories, 'sequenceNumber')

      const favoriteProductList = useProductList()
      await favoriteProductList.loadFavoriteProducts()
      if (favoriteProductList.products.length) {
        productCategories.unshift({
          id: 'favorites',
          favorites: true
        })
      }

      productCategoryList.setData(productCategories)
    })
  }

  productCategoryList.load = async () => {
    await productCategoryList.loader.load(async background => {
      if (background) {
        await productCategoryList.fetch.executeFetch()
      } else {
        productCategoryList.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/product-categories`)
        await productCategoryList.fetch.promise
      }

      const productCategories = productCategoryList.fetch.data.slice()
      $items.sortItems(productCategories, 'sequenceNumber')

      productCategoryList.setData(productCategories)
    })
  }

  return productCategoryList
}
