<template>
  <t-form
    ref="form"
    :form-data="settings.onlineOrderSettings"
    :has-changes-handler="hasChanges => $emit('hasChanges', hasChanges)"
    :input-fields="[
      {
        key: 'enabled',
        type: 'switch',
        required: true
      }
    ]"
  />
</template>

<script>
export default {
  props: {
    settings: {
      required: true
    }
  },

  computed: {
    editFormData () {
      return this.$refs.form && this.$refs.form.editFormData
    }
  },

  methods: {
    validate () {
      return this.$refs.form.validate()
    },

    reset () {
      return this.$refs.form.reset()
    }
  }
}
</script>
