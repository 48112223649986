<template>
  <t-page>
    <t-content-card
      :fetch="qrCodeList.loader"
      title="label.qrCodes"
    >
      <qr-code-table :qr-code-list="qrCodeList"/>
    </t-content-card>
  </t-page>
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useQrCodeList from '@/app/composables/qrcode/useQrCodeList'

export default {
  data () {
    return {
      qrCodeList: useQrCodeList(),
      event: useEvent()
    }
  },

  created () {
    this.qrCodeList.list()
    useEvent().registerListener(this, ['qr-code-save'], () => {
      this.qrCodeList.refresh()
    })
  },

  destroyed () {
    useEvent().unregisterListener(this)
  }
}
</script>
