var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"items":_vm.subscriptionPeriodList.subscriptionPeriods,"item-key":"id","item-properties":[
        {
          key: 'beginDate',
          type: 'date'
        },
        {
          key: 'endDate',
          type: 'date'
        },
        {
          key: 'plan',
          value: subscriptionPeriod => _vm.$t('label.subscriptionPlan_' + subscriptionPeriod.plan)
        },
      ],"default-sort-property-key":"beginDate","default-sort-ascending":false}})
}
var staticRenderFns = []

export { render, staticRenderFns }