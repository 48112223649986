import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useEntity from '@/lib/composables/useEntity'

const tenantSelection = useTenantSelection()

export default function () {
  const userTemplate = {
    id: null,
    username: null,
    firstName: null,
    lastName: null,
    disabled: null,
    disabledForTenant: null,
    admin: null,
    fullName: null,
    tenantOwner: null,
    email: null
  }

  const user = useEntity(userTemplate, user => {
    user.fullName = `${user.firstName} ${user.lastName}`
    user.tenantOwner = tenantSelection.selectedTenant.userId === user.id
  })

  user.save = async editUser => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/users/${editUser.id}`, {
      body: {
        admin: editUser.admin,
        disabledForTenant: editUser.disabledForTenant
      }
    })
    if (await fetch.promise) {
      user.setData(fetch.data)
      useEvent().fire('user-save', user)
    }
  }

  user.cancelMembership = async deleteOptions => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/users/${user.id}`, {
      body: deleteOptions
    })
    if (await fetch.promise) {
      useEvent().fire('user-membership-cancel', user)
    }
  }

  return user
}
