<template>
  <v-dialog :value="show" persistent max-width="600px">
    <v-card v-show="$dialog.isDialogOnTop(id)">
      <v-card-title>
        <span>{{ $t(title) }}</span>
      </v-card-title>
      <div class="pa-6 pt-2 ma-0">{{ $t(info) }}</div>
      <vue-qr-reader v-if="!hidden" :key="componentKey" :use-back-camera="!isMobileDevice" class="d-flex justify-center pb-4" line-color="#FFC045" :line-width="4" v-on:code-scanned="executeAction" />
      <div class="pa-6 pt-2 ma-0 error--text">{{ $t(errorMessage) }}</div>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text @click="$dialog.closeDialog(id, closeHandler)">{{ $t('label.cancel') }}</v-btn>
        <v-btn text color="primary" @click="executeAction(undefined)">{{ $t('label.manualEntry') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.common.js'

export default {
  components: {
    VueQrReader
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    info: {
      type: String,
      required: true
    },
    actionHandler: {
      type: Function,
      required: true
    },
    closeHandler: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      errorMessage: '',
      hidden: false,
      componentKey: 0
    }
  },

  methods: {
    isMobileDevice () {
      return (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
    },
    executeAction (data) {
      this.$dialog.closeDialog(this.id, this.closeHandler)
      this.actionHandler(data)
    },
    errorCaptured (error) {
      switch (error.name) {
        case 'NotAllowedError':
          this.errorMessage = 'Camera permission denied.'
          break
        case 'NotFoundError':
          this.errorMessage = 'There is no connected camera.'
          break
        case 'NotSupportedError':
          this.errorMessage = 'Seems like this page is served in non-secure context.'
          break
        case 'NotReadableError':
          this.errorMessage = 'Couldn\'t access your camera. Is it already in use?'
          break
        case 'OverconstrainedError':
          this.errorMessage = 'Constraints don\'t match any installed camera.'
          break
        default:
          this.errorMessage = 'UNKNOWN ERROR: ' + error.message
      }
      this.$logger.error(this.errorMessage)
    }
  },
  computed: {
    show () {
      return this.$dialog.isDialogOpen(this.id)
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.hidden = false
        // rerender component to be able to scan again
        this.componentKey += 1
      } else {
        this.hidden = true
      }
    }
  }
}
</script>

<style scoped>
  .word-wrap {
    word-break: break-word;
  }
</style>
