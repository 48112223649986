import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useLoader from '@/lib/composables/useLoader'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'

const tenantSelection = useTenantSelection()

export default function () {
  const defaultProductList = Vue.observable({
    loader: useLoader(),
    defaultProducts: [],
    fetch: null
  })

  defaultProductList.setData = data => {
    defaultProductList.defaultProducts = data.map(defaultProductData => {
      return {
        ...defaultProductData,
        logo: `${process.env.VUE_APP_TOPUPZ_URL}/product-logos/${defaultProductData.logoId}`
      }
    })
  }

  defaultProductList.load = async () => {
    await defaultProductList.loader.load(async background => {
      if (background) {
        await defaultProductList.fetch.executeFetch()
      } else {
        defaultProductList.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/default-products`)
        await defaultProductList.fetch.promise
      }
      defaultProductList.setData(defaultProductList.fetch.data)
    })
  }

  return defaultProductList
}
