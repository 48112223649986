import Vue from 'vue'
import moment from 'moment'

const $date = Vue.observable({
  today () {
    return moment().format('YYYY-MM-DD')
  }
})

export default $date
