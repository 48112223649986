<template>
  <v-btn
      v-bind="$attrs"
      @click="$emit('click', $event.target.value)"
      :icon="!!icon"
      :class="{
        't-button-selected': !!selected,
        't-button-primary': !!primary,
        't-button-warning': !!warning,
        't-button-error': !!error,
        't-button-align-left': !!alignLeft
      }"
      :disabled="!enabled || !!disabled"
  >
    <span v-if="label">{{ $t(label) }}</span>
    <v-icon v-if="icon" v-text="icon"/>
  </v-btn>
</template>

<script>
export default {
  props: {
    label: {
      type: String
    },
    icon: {
      type: String
    },
    selected: {
      type: Boolean
    },
    primary: {
      type: Boolean
    },
    warning: {
      type: Boolean
    },
    error: {
      type: Boolean
    },
    alignLeft: {
      type: Boolean
    },
    enabled: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
  .t-button-primary {
    color: var(--color-primary) !important;
  }

  .t-button-warning {
    color: var(--color-warning) !important;
  }

  .t-button-error {
    color: var(--color-error) !important;
  }

  .t-button-selected {
    color: var(--color-primary) !important;
    background-color: var(--color-primary-transparent-4) !important;
  }

  .t-button-align-left {
    justify-content: left;
  }
</style>
