import $items from '../items'
import $formatter from '../formatter'
import { Fetch } from '../fetcher'

export default {
  props: {
    items: {
      type: [Array, Function, Fetch, Object]
    },
    itemKey: {
      type: [String, Function]
    },
    itemValue: {
      type: [String, Function]
    },
    itemText: {
      type: [String, Function]
    },
    itemIcon: {
      type: [String, Function]
    },
    itemDisabled: {
      type: [String, Function]
    },
    itemProperties: {
      type: Array,
      default: () => []
    },
    selectionHandler: {
      type: Function
    },
    clickHandler: {
      type: Function
    },
    enforceSelection: {
      type: Boolean,
      default: false
    },
    multipleSelection: {
      type: Boolean,
      default: false
    },
    defaultSelectionKey: {
      type: [String, Object, Array]
    },
    dragDataType: {
      type: [String, Function]
    },
    dropDataTypes: {
      type: [Array, Function]
    },
    dropHandler: {
      type: Function
    },
    defaultSortPropertyKey: {
      type: String
    },
    defaultSortAscending: {
      type: Boolean,
      default: true
    },
    search: {
      type: Boolean,
      default: false
    },
    itemFilteringHandler: {
      type: Function
    },
    noItemsLabel: {
      type: String
    }
  },

  data () {
    return {
      itemsReturnValue: null,
      lastItemsFetch: null,
      selectedItemKeys: [],
      sortPropertyKey: this.defaultSortPropertyKey,
      sortAscending: this.defaultSortAscending,
      searchText: null,
      page: null
    }
  },

  created () {
    this.updateItemsReturnValue()
    this.checkSelectionKeys()
    this.notifyItemFilteringHandler()
  },

  watch: {
    items () {
      this.updateItemsReturnValue()
    },

    containerItems () {
      this.checkSelectionKeys()
    },

    sortPropertyKey () {
      if (this.paged) {
        this.page = 1
      }
      this.notifyItemFilteringHandler()
    },

    sortAscending () {
      if (this.paged) {
        this.page = 1
      }
      this.notifyItemFilteringHandler()
    },

    searchText () {
      if (this.paged) {
        this.page = 1
      }
      this.notifyItemFilteringHandler()
    },

    page () {
      if (this.loadedPage !== this.page) {
        this.notifyItemFilteringHandler()
      }
    },

    pageFetch () {
      if (this.pageFetch) {
        this.page = this.pageFetch.page
      }
    },

    itemsReturnValue (newItemsReturnValue, oldItemsReturnValue) {
      if (oldItemsReturnValue instanceof Fetch && oldItemsReturnValue.hasArrayData()) {
        this.lastItemsFetch = oldItemsReturnValue
      }
    }
  },

  computed: {
    selectable () {
      return !!this.selectionHandler
    },

    paged () {
      return !!this.page
    },

    pageFetch () {
      if (this.itemsReturnValue && this.itemsReturnValue.pageCount) {
        return this.itemsReturnValue
      }
      if (this.itemsReturnValue instanceof Fetch && this.itemsReturnValue.hasPagedData()) {
        return this.itemsReturnValue.data
      }
      if (this.itemsReturnValue instanceof Fetch && this.lastItemsFetch && this.lastItemsFetch.hasPagedData()) {
        return this.lastItemsFetch.data
      }
    },

    loadedPage () {
      if (this.pageFetch) {
        return this.pageFetch.page
      }
    },

    pageCount () {
      if (this.pageFetch) {
        return this.pageFetch.pageCount
      }
    },

    containerItemProperties () {
      if (!this.itemProperties) {
        return []
      }

      return this.itemProperties
        .filter(itemProperty => this.getItemPropertyVisible(itemProperty))
        .map(itemProperty => {
          const itemPropertyKey = this.getItemPropertyKey(itemProperty)
          return {
            itemProperty,
            key: itemPropertyKey,
            header: this.getItemPropertyHeader(itemProperty),
            sortable: itemProperty.sortable,
            sortAscending: this.sortPropertyKey && this.sortPropertyKey === itemPropertyKey && this.sortAscending,
            sortDescending: this.sortPropertyKey && this.sortPropertyKey === itemPropertyKey && !this.sortAscending
          }
        })
    },

    itemList () {
      if (!this.itemsReturnValue) {
        return []
      }

      if (this.itemsReturnValue instanceof Fetch && this.itemsReturnValue.hasArrayData()) {
        return this.itemsReturnValue.getArrayData().slice()
      } else if (this.itemsReturnValue instanceof Fetch && this.lastItemsFetch) {
        return this.lastItemsFetch.getArrayData().slice()
      } else if (this.itemsReturnValue && this.itemsReturnValue.items) {
        return this.itemsReturnValue.items
      } else if (Array.isArray(this.itemsReturnValue)) {
        return this.itemsReturnValue.slice()
      } else {
        return []
      }
    },

    filteredItemList () {
      let filteredItemList = this.itemList

      if (!this.itemFilteringHandler) {
        if (this.searchText) {
          filteredItemList = filteredItemList.filter(item => {
            const itemText = this.getItemText(item)
            if (itemText && this.$items.filterSearchText(itemText, this.searchText)) {
              return true
            }

            return this.containerItemProperties.some(containerItemProperty => {
              const itemPropertyText = this.getItemPropertyText(item, containerItemProperty.itemProperty)
              return itemPropertyText && this.$items.filterSearchText(itemPropertyText, this.searchText)
            })
          })
        }

        if (this.sortPropertyKey) {
          const sortContainerItemProperty = this.containerItemProperties.find(containerItemProperty => containerItemProperty.key === this.sortPropertyKey)
          if (sortContainerItemProperty) {
            const sortItemProperty = sortContainerItemProperty.itemProperty
            const sortItemPropertyValue = sortItemProperty.value ? sortItemProperty.value : sortItemProperty.key
            $items.sortItems(filteredItemList, sortItemPropertyValue, this.sortAscending)
          } else {
            $items.sortItems(filteredItemList, this.sortPropertyKey, this.sortAscending)
          }
        }
      }

      return filteredItemList
    },

    containerItems () {
      return this.filteredItemList.map(item => this.mapContainerItem(item))
    }
  },

  methods: {
    mapContainerItem (item) {
      return {
        item,
        key: this.getItemKey(item),
        value: this.getItemValue(item),
        text: this.getItemText(item),
        icon: this.getItemIcon(item),
        selected: this.isItemSelected(item),
        disabled: this.isItemDisabled(item),
        draggable: this.isItemDraggable(item),
        dragDataType: this.getItemDragDataType(item),
        dropDataTypes: this.getItemDropDataTypes(item),
        itemProperties: this.containerItemProperties.map(containerItemProperty => {
          const itemProperty = containerItemProperty.itemProperty
          return {
            item,
            key: this.getItemPropertyKey(itemProperty),
            text: this.getItemPropertyText(item, itemProperty),
            value: this.getItemPropertyValue(item, itemProperty),
            icon: this.getItemPropertyIcon(item, itemProperty),
            logo: this.getItemPropertyLogo(item, itemProperty),
            progress: this.getItemPropertyProgress(item, itemProperty)
          }
        })
      }
    },

    updateItemsReturnValue () {
      if (this.items && this.items instanceof Function) {
        this.itemsReturnValue = this.items()
      } else {
        this.itemsReturnValue = this.items
      }
    },

    checkSelectionKeys () {
      if (!this.containerItems) {
        return
      }

      if (!this.selectedItemKeys.length && this.defaultSelectionKey) {
        if (Array.isArray(this.defaultSelectionKey)) {
          this.selectedItemKeys = this.defaultSelectionKey.slice()
        } else {
          this.selectedItemKeys = [this.defaultSelectionKey]
        }
      }

      const invalidKeys = this.$lodash.remove(this.selectedItemKeys, selectedItemKey => {
        return !this.containerItems.some(containerItem => containerItem.key === selectedItemKey)
      })

      if (this.enforceSelection && !this.selectedItemKeys.length && this.containerItems.length) {
        this.selectedItemKeys = [this.containerItems[0].key]
        this.notifySelectionHandler()
      } else if (invalidKeys.length) {
        this.notifySelectionHandler()
      }
    },

    handleContainerItemClick (containerItem) {
      if (this.clickHandler) {
        this.clickHandler(containerItem.item)
      }

      if (!this.selectable) {
        return
      }

      const itemKey = containerItem.key
      if (this.enforceSelection && this.selectedItemKeys.length === 1 && this.selectedItemKeys.includes(itemKey)) {
        return
      }

      if (this.selectedItemKeys.includes(itemKey)) {
        const keyIndex = this.selectedItemKeys.indexOf(itemKey)
        this.selectedItemKeys.splice(keyIndex, 1)
      } else if (!this.multipleSelection) {
        this.selectedItemKeys = [itemKey]
      } else {
        this.selectedItemKeys.push(itemKey)
      }

      this.notifySelectionHandler()
    },

    handleContainerItemPropertyClick (containerItemProperty) {
      if (!containerItemProperty.sortable) {
        return
      }

      if (this.sortPropertyKey === containerItemProperty.key) {
        this.sortAscending = !this.sortAscending
      } else {
        this.sortAscending = true
        this.sortPropertyKey = containerItemProperty.key
      }
    },

    notifySelectionHandler () {
      if (this.selectionHandler) {
        const selectedItems = this.containerItems
          .filter(containerItem => this.selectedItemKeys.includes(containerItem.key))
          .map(containerItem => containerItem.item)

        if (this.multipleSelection) {
          this.selectionHandler(selectedItems)
        } else {
          this.selectionHandler(selectedItems.length ? selectedItems[0] : null)
        }
      }
    },

    notifyItemFilteringHandler () {
      if (!this.itemFilteringHandler) {
        return
      }

      const filter = {
        sortPropertyKey: this.sortPropertyKey ? this.sortPropertyKey : '',
        sortAscending: !!this.sortAscending,
        searchText: this.searchText ? this.searchText : '',
        page: this.page || 1
      }

      this.itemFilteringHandler(filter)
    },

    isItemSelected (item) {
      const itemKey = $items.getItemProperty(item, this.itemKey)
      return this.selectedItemKeys.includes(itemKey)
    },

    isItemDisabled (item) {
      if (typeof this.itemDisabled === 'function') {
        return this.itemDisabled(item)
      }
      return false
    },

    isItemDraggable (item) {
      return !!this.getItemDragDataType(item)
    },

    getItemDragDataType (item) {
      if (!this.dragDataType) {
        return false
      }
      if (typeof this.dragDataType === 'function') {
        return this.dragDataType(item)
      }
      return this.dragDataType
    },

    getItemDropDataTypes (item) {
      if (!this.dropDataTypes) {
        return false
      }
      if (typeof this.dropDataTypes === 'function') {
        return this.dropDataTypes(item)
      }
      return this.dropDataTypes
    },

    handleContainerItemDragStart (event, containerItem) {
      this.$dragger.startDrag(event, containerItem.dragDataType, containerItem.item)
    },

    handleContainerItemDragOver (event, containerItem) {
      this.$dragger.dragOver(event, containerItem.dropDataTypes)
    },

    handleContainerItemDrop (containerItem) {
      if (this.dropHandler) {
        this.dropHandler(this.$dragger.data, containerItem.item)
      }
    },

    getItemKey (item) {
      if (this.itemKey) {
        return $items.getItemProperty(item, this.itemKey)
      }
      if (typeof item === 'object') {
        return JSON.stringify(item)
      }
      return item
    },

    getItemValue (item) {
      if (this.itemValue) {
        return $items.getItemProperty(item, this.itemValue)
      }
      return this.getItemKey(item)
    },

    getItemText (item) {
      if (this.itemText) {
        return $items.getItemProperty(item, this.itemText)
      }
      return this.getItemValue(item)
    },

    getItemIcon (item) {
      if (!this.itemIcon) {
        return null
      }

      let icon
      if (typeof this.itemIcon === 'object') {
        icon = this.itemIcon
      } else {
        icon = $items.getItemProperty(item, this.itemIcon)
      }

      if (!icon && typeof this.itemIcon === 'string') {
        icon = this.itemIcon
      }

      if (icon && typeof icon === 'object') {
        return icon
      }

      if (icon && typeof icon === 'string') {
        return {
          image: icon
        }
      }

      return null
    },

    getItemPropertyKey (itemProperty) {
      if (typeof itemProperty.key === 'function') {
        return itemProperty.key()
      }
      if (itemProperty.key) {
        return itemProperty.key
      }
      return this.getItemPropertyHeader(itemProperty)
    },

    getItemPropertyValue (item, itemProperty) {
      if (itemProperty.value) {
        return $items.getItemProperty(item, itemProperty.value)
      }
      const itemPropertyKey = this.getItemPropertyKey(itemProperty)
      return $items.getItemProperty(item, itemPropertyKey)
    },

    getItemPropertyText (item, itemProperty) {
      if (itemProperty.text) {
        return $items.getItemProperty(item, itemProperty.text)
      }

      const propertyType = itemProperty.type
      if (propertyType === 'icon') {
        return
      }

      const propertyValue = this.getItemPropertyValue(item, itemProperty)

      // don't use !value as the number 0 is also contained which is an issue to format amounts
      if (propertyValue === undefined || propertyValue === null || !propertyType) {
        return propertyValue
      }

      if (propertyType === 'amount') {
        return $formatter.formatAmount(propertyValue)
      }

      if (propertyType === 'percentage') {
        return $formatter.formatPercentage(propertyValue)
      }

      if (propertyType === 'number') {
        return propertyValue
      }

      if (propertyType === 'date') {
        return $formatter.formatDate(propertyValue)
      }

      if (propertyType === 'date-time') {
        return $formatter.formatDateTime(propertyValue)
      }

      if (propertyType === 'timestamp') {
        return $formatter.formatTimestamp(propertyValue)
      }

      if (propertyType === 'uuid') {
        return $formatter.formatUuid(propertyValue)
      }
    },

    getItemPropertyIcon (item, itemProperty) {
      if (!itemProperty.icon) {
        return null
      }

      let icon
      if (typeof itemProperty.icon === 'object') {
        icon = itemProperty.icon
      } else {
        icon = $items.getItemProperty(item, itemProperty.icon)
      }

      if (!icon && typeof itemProperty.icon === 'string') {
        icon = itemProperty.icon
      }

      if (icon && typeof icon === 'object') {
        return {
          image: icon.image,
          color: icon.color,
          tooltip: icon.tooltip,
          clickHandler: icon.clickHandler || itemProperty.iconClickHandler,
          disabledHandler: icon.disabledHandler || itemProperty.disabledHandler
        }
      }

      if (icon && typeof icon === 'string') {
        return {
          image: icon,
          clickHandler: itemProperty.iconClickHandler,
          disabledHandler: itemProperty.disabledHandler
        }
      }

      return null
    },

    getItemPropertyLogo (item, itemProperty) {
      if (itemProperty.type === 'logo') {
        return this.getItemPropertyValue(item, itemProperty)
      }
    },

    getItemPropertyProgress (item, itemProperty) {
      if (!itemProperty.progress) {
        return null
      }

      let progress
      if (typeof itemProperty.progress === 'object') {
        progress = itemProperty.progress
      } else {
        progress = $items.getItemProperty(item, itemProperty.progress)
      }

      if (!progress && typeof itemProperty.progress === 'number') {
        progress = itemProperty.progress
      }

      if (progress && typeof progress === 'object') {
        return {
          color: progress.color,
          value: progress.value,
          height: progress.height,
          showLabel: progress.showLabel,
          stale: progress.stale,
          staleColor: progress.staleColor
        }
      }

      if (progress && typeof progress === 'number') {
        return {
          value: progress,
          stale: false
        }
      }

      return null
    },

    getItemPropertyVisible (itemProperty) {
      if (typeof itemProperty.visible === 'function') {
        return !!itemProperty.visible()
      }
      if (typeof itemProperty.visible === 'boolean') {
        return itemProperty.visible
      }
      return true
    },

    getItemPropertyHeader (itemProperty) {
      if (typeof itemProperty.header === 'function') {
        return itemProperty.header()
      }

      if (typeof itemProperty.header === 'string') {
        return this.$t(itemProperty.header)
      }

      if (typeof itemProperty.key === 'string') {
        return this.$t('label.' + itemProperty.key)
      }

      return null
    }
  }
}
