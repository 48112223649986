import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _ from 'lodash'
import messagesEn from './messages/messages.en'
import messagesNl from './messages/messages.nl'
import messagesFr from './messages/messages.fr'
import { useTranslateConfig } from '@/lib/composables/useTranslate'

const defaultMessages = {
  en: messagesEn,
  nl: messagesNl,
  fr: messagesFr
}

Vue.use(VueI18n)

const $i18n = {
  locale: navigator.language,
  vueI18n: null,
  messages: defaultMessages,

  create (messages) {
    $i18n.messages = _.merge(defaultMessages, messages || {})
    $i18n.vueI18n = new VueI18n({
      locale: $i18n.locale,
      messages: $i18n.messages,
      silentTranslationWarn: true
    })
    return $i18n.vueI18n
  },

  setLocale (locale) {
    $i18n.locale = locale
    if ($i18n.vueI18n) {
      $i18n.vueI18n.locale = locale
    }
    useTranslateConfig().setLanguage(locale)
  }
}

export default $i18n
