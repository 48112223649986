var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"item-properties":[
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'numberOfUsers',
          type: 'number',
          sortable: true
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: userGroup => _vm.$dialog.openDialog('user-group', userGroup)
        }
      ],"items":_vm.userGroupList.userGroups,"default-sort-property-key":"name","item-key":"id","no-items-label":"message.noUserGroups","search":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }