<template>
  <t-table
    :items="subscriptionPeriodList.subscriptionPeriods"
    item-key="id"
    :item-properties="[
          {
            key: 'beginDate',
            type: 'date'
          },
          {
            key: 'endDate',
            type: 'date'
          },
          {
            key: 'plan',
            value: subscriptionPeriod => $t('label.subscriptionPlan_' + subscriptionPeriod.plan)
          },
        ]"
    default-sort-property-key="beginDate"
    :default-sort-ascending="false"
  />
</template>

<script>
export default {
  props: {
    subscriptionPeriodList: {
      required: true
    }
  }
}
</script>
