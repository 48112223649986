import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useEntity from '@/lib/composables/useEntity'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'

const tenantSelection = useTenantSelection()

export default function () {
  const qrCodeTemplate = {
    id: null,
    tenantId: null,
    name: null,
    type: null,
    description: null,
    formattedId: null,
    cashierId: null,
    cashierName: null
  }

  const qrCode = useEntity(qrCodeTemplate, qrCode => {
    const part1 = qrCode.id.substring(0, 2)
    const part2 = qrCode.id.substring(2, 4)
    const part3 = qrCode.id.substring(4, 6)
    const part4 = qrCode.id.substring(6, 8)
    qrCode.formattedId = `${part1} ${part2} ${part3} ${part4}`.toUpperCase()
    qrCode.description = qrCode.name ? `${qrCode.formattedId} - ${qrCode.name}` : qrCode.formattedId
  })

  qrCode.save = async (editQrCode) => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/qr-codes/${qrCode.id}`, {
      body: {
        name: editQrCode.name,
        type: editQrCode.type,
        cashierId: editQrCode.type === 'PAYMENT' ? editQrCode.cashierId : ''
      }
    })
    await fetch.promise
    fetch.isSuccess() && useEvent().fire('qr-code-save', qrCode)
  }

  return qrCode
}
