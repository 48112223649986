import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import $formatter from '@/lib/formatter'
import useEntity from '@/lib/composables/useEntity'

const tenantSelection = useTenantSelection()

export default function () {
  const cardTemplate = {
    id: null,
    userId: null,
    name: null,
    disabled: null,
    formattedId: null
  }

  const card = useEntity(cardTemplate, card => {
    card.formattedId = $formatter.formatUuid(card.id)
  })

  card.save = async editCard => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/cards/${editCard.id}`, {
      body: {
        disabled: editCard.disabled,
        userId: editCard.userId
      }
    })
    if (await fetch.promise) {
      card.setData(fetch.data)
      useEvent().fire('card-save', card)
    }
  }

  return card
}
