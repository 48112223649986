import Vue from 'vue'
import messagesEn from '../messages/messages.en'
import messagesNl from '../messages/messages.nl'
import messagesFr from '../messages/messages.fr'

const translateTemplate = {}
Object.assign(translateTemplate, messagesFr)
Object.assign(translateTemplate, messagesNl)
Object.assign(translateTemplate, messagesEn)

const translate = Vue.observable({
  translateTemplate
})

for (const translateKey in translate) {
  translate[translateKey] = null
}

export default function () {
  return translate
}

export function useTranslateConfig () {
  return {
    setLanguage (language) {
      for (const translateKey in translate) {
        translate[translateKey] = null
      }

      if (language === 'fr') {
        Object.assign(translate, messagesFr)
      } else if (language === 'nl') {
        Object.assign(translate, messagesNl)
      } else {
        Object.assign(translate, messagesEn)
      }
    }
  }
}
