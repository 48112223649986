import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useLoader from '@/lib/composables/useLoader'
import useProduct from '@/app/composables/product/useProduct'
import $items from '@/lib/items'

const tenantSelection = useTenantSelection()

export default function () {
  const productList = Vue.observable({
    loader: useLoader(),
    products: [],
    fetch: null,
    favorites: null,
    categoryId: null
  })

  productList.setData = data => {
    productList.products = data.map(productDate => {
      const product = useProduct()
      product.setData(productDate)
      return product
    })

    if (productList.favorites) {
      $items.sortItems(productList.products, 'favoriteSequenceNumber')
    } else {
      $items.sortItems(productList.products, 'sequenceNumber')
    }
  }

  productList.loadCategoryProducts = async (category) => {
    if (category.favorites) {
      await productList.loadFavoriteProducts()
    } else {
      await productList.loader.load(async background => {
        if (background) {
          await productList.fetch.executeFetch()
        } else {
          productList.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/products?category.id=${category.id}`)
          await productList.fetch.promise
        }
        productList.categoryId = category.id
        productList.favorites = false
        productList.setData(productList.fetch.data)
      })
    }
  }

  productList.loadFavoriteProducts = async () => {
    await productList.loader.load(async background => {
      if (background) {
        await productList.fetch.executeFetch()
      } else {
        productList.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/products?favorite=true`)
        await productList.fetch.promise
      }
      productList.categoryId = null
      productList.favorites = true
      productList.setData(productList.fetch.data)
    })
  }

  return productList
}
