<template>
  <t-page>
    <t-content-card
      title="label.categories"
      :fetch="productCategoryList.fetch"
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => $dialog.openDialog('product-category')
        }
      ]"
    >
      <product-category-chips :product-category-list="productCategoryList" />
    </t-content-card>

    <t-content-card
      title="label.products"
      :fetch="productList.fetch"
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => event.fire('product-add', {
            categoryId: productList.categoryId
          })
        }
      ]"
    >
      <product-table :product-list="productList" />
    </t-content-card>
  </t-page>
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useProductCategoryList from '@/app/composables/product/useProductCategoryList'
import useProductList from '@/app/composables/product/useProductList'

export default {
  data () {
    return {
      productCategoryList: useProductCategoryList(),
      productList: useProductList(),
      event: useEvent()
    }
  },

  async beforeCreate () {
    useEvent().registerListener(this, ['product-save', 'product-delete', 'product-category-save', 'product-category-delete'], () => {
      this.productCategoryList.loader.refresh()
      this.productList.loader.refresh()
    })

    useEvent().registerListener(this, 'product-category-select', async productCategory => {
      await this.productList.loadCategoryProducts(productCategory)
    })
  },

  async created () {
    await this.productCategoryList.loadWithFavorites()
  },

  destroyed () {
    useEvent().unregisterListener(this)
  }
}
</script>
