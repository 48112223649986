<template>
  <t-process-status-dialog
    id="device-link-process"
    :close-handler="() => event.fire('device-linked')"
    :process-url="deviceLink.processUrl || ''"
    :status-list-keep-polling="deviceLink.statusListKeepPolling"
    :status-message-mapping="deviceLink.statusMessageMapping"
    title="label.linkDevice"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useDeviceLink from '@/app/composables/device/useDeviceLink'

export default {
  data () {
    return {
      deviceLink: useDeviceLink(),
      event: useEvent()
    }
  },

  created () {
    useEvent().registerListener('device-link-process-dialog', 'device-linked', () => this.$dialog.closeDialog('device-link-process'))
  },

  destroyed () {
    useEvent().unregisterListener('device-link-process-dialog')
  }
}
</script>
