var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-selection-dialog',{attrs:{"id":"tenant-selection","cancel-handler":_vm.tenantSelection.cancelSelection,"input-fields":[
      {
        key: 'id',
        type: 'text',
        label: 'label.tenantId',
        availabilityHandler: _vm.handleTenantIdAvailability,
        required: true,
        readOnlyForEdit: true,
        alphanumeric: true,
        lowercase: true
      },
      {
        key: 'name',
        type: 'text',
        label: 'label.name',
        required: true
      }
    ],"item-editable":tenant => tenant.owner,"items":_vm.tenantSelection.tenants,"save-handler":_vm.handleSave,"selectionHandler":tenant => _vm.tenantSelection.selectTenant(tenant),"item-key":"id","item-text":"name","no-items-label":"message.tenantSelectionEmpty","subtitle":"message.tenantSelection","title":"label.tenants"}})
}
var staticRenderFns = []

export { render, staticRenderFns }