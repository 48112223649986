var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-btn',_vm._b({class:{
      't-button-selected': !!_vm.selected,
      't-button-primary': !!_vm.primary,
      't-button-warning': !!_vm.warning,
      't-button-error': !!_vm.error,
      't-button-align-left': !!_vm.alignLeft
    },attrs:{"icon":!!_vm.icon,"disabled":!_vm.enabled || !!_vm.disabled},on:{"click":function($event){return _vm.$emit('click', $event.target.value)}}},'v-btn',_vm.$attrs,false),[(_vm.label)?_c('span',[_vm._v(_vm._s(_vm.$t(_vm.label)))]):_vm._e(),(_vm.icon)?_c('v-icon',{domProps:{"textContent":_vm._s(_vm.icon)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }