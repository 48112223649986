var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-page',[_c('t-content-card',{attrs:{"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: () => _vm.$dialog.openDialog('user-membership-request')
      }
    ],"collapsible":true,"default-collapsed":true,"fetch":_vm.membershipRequestList.fetch,"title":"label.membershipRequests"}},[_c('user-membership-request-table',{attrs:{"membership-request-list":_vm.membershipRequestList}})],1),_c('t-content-card',{attrs:{"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: () => _vm.$dialog.openDialog('user-group')
      }
    ],"collapsible":true,"default-collapsed":true,"fetch":_vm.userGroupList.fetch,"title":"label.userGroups"}},[_c('user-group-table',{attrs:{"user-group-list":_vm.userGroupList}})],1),_c('t-content-card',{attrs:{"fetch":_vm.userSearch.fetch,"title":"label.users"}},[_c('user-table',{attrs:{"user-search":_vm.userSearch}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }