<template>
  <t-page>
    <t-content-card
      title="label.transactions"
      :fetch="transactionsFetch"
    >

      <t-table
        :items="transactionsFetch"
        item-key="id"
        :item-properties="[
          {
            key: 'context',
            header: 'label.type',
            type: 'icon',
            icon: transaction => {
              switch (transaction.context) {
                case 'ORDER': return {
                  image: 'mdi-shopping',
                  tooltip: 'label.order'
                }
                case 'TOPUP': return {
                  image: 'mdi-credit-card-plus',
                  tooltip: 'label.topup'
                }
                case 'REWARD': return {
                  image: 'mdi-gift',
                  tooltip: 'label.reward'
                }
                case 'MEMBERSHIP_CANCELLATION': return {
                  image: 'mdi-account-remove',
                  tooltip: 'label.membershipCancellation'
                }
              }
            },
            sortable: true
          },
          {
            key: 'dateTime',
            type: 'date-time',
            sortable: true,
          },
          {
            key: 'userId',
            header: 'label.username',
            sortable: true
          },
          {
            key: 'amount',
            type: 'amount',
            sortable: true,
            icon: transaction => transaction.amount > 0 ? { image: 'mdi-arrow-up-bold', color: 'success' } : { image: 'mdi-arrow-down-bold', color: 'error' }
          },
          {
            key: 'cardId',
            header: 'label.cardNumber',
            type: 'uuid',
            sortable: true
          },
        ]"
        :search="true"
        :item-filtering-handler="handleTransactionsFiltering"
        default-sort-property-key="dateTime"
        :default-sort-ascending="false"
        no-items-label="message.noTransactions"
      />
    </t-content-card>
  </t-page>
</template>

<script>
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'

export default {
  data () {
    return {
      tenantSelection: useTenantSelection(),
      transactionsFetch: null
    }
  },

  methods: {
    handleTransactionsFiltering (filter) {
      let url = `${process.env.VUE_APP_TOPUPZ_URL}/tenants/${this.tenantSelection.tenantId}/transactions`
      url += `?page=${filter.page}`
      url += `&sortProperty=${filter.sortPropertyKey}`
      url += `&sortAscending=${filter.sortAscending}`
      url += `&searchText=${filter.searchText}`
      url += `&searchTimezone=${this.$authorization.user.timezone}`
      this.transactionsFetch = this.$fetcher.fetch(url)
    }
  }
}
</script>
