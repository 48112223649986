<template>
  <v-form
      ref="form"
      v-model="formValid"
      autocomplete="off"
      lazy-validation
      @submit.prevent=""
  >
    <div
        v-for="formField in formFields"
        :key="formField.key">

      <t-text-field
          v-if="formField.type === 'text'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :alphanumeric="formField.alphanumeric"
          :autofocus="formField.autofocus"
          :availability-handler="formField.availabilityHandler"
          :disabled="formField.disabled"
          :label="formField.label"
          :lowercase="formField.lowercase"
          :multiline="formField.multiline"
          :numeric="formField.numeric"
          :required="formField.required"
          :regex="formField.regex"
          autocomplete="off"
      />

      <t-number-field
          v-if="formField.type === 'number'"
          :ref="`inputField.${formField.key}`"
          v-model.number="editFormData[formField.key]"
          :autofocus="formField.autofocus"
          :disabled="formField.disabled"
          :label="formField.label"
          :minValue="formField.minValue"
          :required="formField.required"
      />

      <t-auto-complete-text-field
          v-if="formField.type === 'auto-complete-text-field'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :autofocus="formField.autofocus"
          :change-handler="formField.changeHandler"
          :disabled="formField.disabled"
          :item-filtering-handler="formField.itemFilteringHandler"
          :item-key="formField.itemKey"
          :item-text="formField.itemText"
          :item-value="formField.itemValue"
          :items="formField.items"
          :label="formField.label"
          :required="formField.required"
          @input="event => { formField.changeHandler && formField.changeHandler(event) }"
      />

      <t-auto-complete-list-field
          v-if="formField.type === 'auto-complete-list'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :autofocus="formField.autofocus"
          :disabled="formField.disabled"
          :item-by-value-handler="formField.itemByValueHandler"
          :item-filtering-handler="formField.itemFilteringHandler"
          :item-key="formField.itemKey"
          :item-text="formField.itemText"
          :item-value="formField.itemValue"
          :items="formField.items"
          :label="formField.label"
          :required="formField.required"
          @input="event => { formField.changeHandler && formField.changeHandler(event) }"
      />

      <t-auto-complete-select
          v-if="formField.type === 'auto-complete-select'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :autofocus="formField.autofocus"
          :disabled="formField.disabled"
          :item-by-value-handler="formField.itemByValueHandler"
          :item-filtering-handler="formField.itemFilteringHandler"
          :item-key="formField.itemKey"
          :item-text="formField.itemText"
          :item-value="formField.itemValue"
          :items="formField.items"
          :label="formField.label"
          :required="formField.required"
          @input="event => { formField.changeHandler && formField.changeHandler(event) }"
      />

      <t-date-field
          v-if="formField.type === 'date'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :disabled="formField.disabled"
          :label="formField.label"
          :max-date="formField.maxDate"
          :min-date="formField.minDate"
          :picker="formField.picker"
          :required="formField.required"
      />

      <t-date-time-field
          v-if="formField.type === 'date-time'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :disabled="formField.disabled"
          :label="formField.label"
          :max-date="formField.maxDate"
          :min-date="formField.minDate"
          :picker="formField.picker"
          :required="formField.required"
      />

      <t-select-field
          v-if="formField.type === 'select'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :change-handler="formField.changeHandler"
          :disabled="formField.disabled"
          :item-key="formField.itemKey"
          :item-text="formField.itemText"
          :item-value="formField.itemValue"
          :items="formField.items"
          :label="formField.label"
          :required="formField.required"
      />

      <t-list-select-field
          v-if="formField.type === 'list-select'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :change-handler="formField.changeHandler"
          :disabled="formField.disabled"
          :item-description="formField.itemDescription"
          :item-disabled="formField.itemDisabled"
          :item-key="formField.itemKey"
          :item-text="formField.itemText"
          :item-value="formField.itemValue"
          :items="formField.items"
          :label="formField.label"
          :required="formField.required"
      />

      <t-amount-field
          v-if="formField.type === 'amount'"
          :ref="`inputField.${formField.key}`"
          v-model.number="editFormData[formField.key]"
          :autofocus="formField.autofocus"
          :disabled="formField.disabled"
          :label="formField.label"
          :min-value="formField.minValue"
          :required="formField.required"
      />

      <t-logo-choice-field
          v-if="formField.type === 'logo-choice'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :disabled="formField.disabled"
          :item-key="formField.itemKey"
          :item-text="formField.itemText"
          :item-value="formField.itemValue"
          :items="formField.items"
          :label="formField.label"
          :required="formField.required"
      />

      <t-switch-field
          v-if="formField.type === 'switch'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :disabled="formField.disabled"
          :label="formField.label"
          :required="formField.required"
      />

      <t-list-field
          v-if="formField.type === 'list'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :disabled="formField.disabled"
          :input-fields="formField.inputFields"
          :item-key="formField.itemKey"
          :item-text="formField.itemText"
          :label="formField.label"
          :required="formField.required"
          :uuid-item="formField.uuidItem"
          :edit-handler="formField.editHandler"
      />

      <v-file-input
          v-if="formField.type === 'file'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :accept="formField.accept"
          :clearable="true"
          :disabled="formField.disabled"
          :label="formField.label"
          :placeholder="formField.placeholder ? formField.placeholder : ''"
          :prepend-icon="formField.icon ? formField.icon : 'mdi-file-outline'"
          :rules="getInputFieldRules(formField)"
          :show-size="1024"
          autocomplete="off"
      />

      <t-uuid-field
          v-if="formField.type === 'uuid'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :autofocus="formField.autofocus"
          :availabilityHandler="formField.availabilityHandler"
          :disabled="formField.disabled"
          :label="formField.label"
          :required="formField.required"
          autocomplete="off"
      />

      <t-checkboxes-field
          v-if="formField.type === 'checkboxes'"
          :ref="`inputField.${formField.key}`"
          v-model="editFormData[formField.key]"
          :autofocus="formField.autofocus"
          :change-handler="formField.changeHandler"
          :disabled="formField.disabled"
          :item-key="formField.itemKey"
          :item-text="formField.itemText"
          :item-value="formField.itemValue"
          :items="formField.items"
          :label="formField.label"
          :no-items-label="formField.noItemsLabel"
          :required="formField.required"
      />
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    inputFields: {
      type: Array,
      required: true
    },
    formData: {
      type: Object
    },
    hasChangesHandler: {
      type: Function
    },
    validationHandler: {
      type: Function
    },
    editHandler: {
      type: Function
    }
  },

  data () {
    const editFormData = {}
    this.inputFields.forEach(inputField => {
      editFormData[inputField.key] = null
    })

    return {
      editFormData,
      formValid: null
    }
  },

  computed: {
    hasChanges () {
      return this.inputFields.some(inputField => {
        if (this.formData) {
          return !this.$lodash.isEqual(this.formData[inputField.key], this.editFormData[inputField.key])
        }
        return this.editFormData[inputField.key] !== null
      })
    },

    formFields () {
      return this.inputFields
        .filter(inputField => this.getInputFieldVisible(inputField))
        .map(inputField => {
          return {
            ...inputField,
            disabled: this.getInputFieldDisabled(inputField),
            autofocus: this.getInputFieldAutofocus(inputField),
            label: this.getInputFieldLabel(inputField)
          }
        })
    }
  },

  watch: {
    formData: {
      deep: true,
      handler () {
        this.initEditFormData()
      }
    },

    hasChanges (newHasChanges, oldHasChanges) {
      if (this.hasChangesHandler && newHasChanges !== oldHasChanges) {
        this.hasChangesHandler(newHasChanges)
      }
    }
  },

  created () {
    this.initEditFormData()

    this.$watch(() => this.getEditFormData(), () => {
      if (this.editHandler) {
        this.editHandler(this.editFormData)
      }
    })
  },

  methods: {
    initEditFormData () {
      this.inputFields.forEach(inputField => {
        if (this.formData) {
          this.editFormData[inputField.key] = this.$lodash.cloneDeep(this.formData[inputField.key])
        } else if (inputField.defaultValue) {
          this.editFormData[inputField.key] = this.$items.extractItem(inputField.defaultValue)
        } else if (inputField.items && inputField.itemKey && inputField.defaultItem) {
          this.editFormData[inputField.key] = this.$items.extractItem(inputField.defaultValue)[inputField.itemKey]
        } else {
          this.editFormData[inputField.key] = null
        }
      })
    },

    async validate () {
      this.inputFields.forEach(inputField => {
        const inputFieldRef = this.$refs[`inputField.${inputField.key}`]
        if (inputFieldRef && inputFieldRef.length && this.$refs[`inputField.${inputField.key}`][0].blur) {
          this.$refs[`inputField.${inputField.key}`][0].blur()
        }
      })

      let formValidationOk = this.$refs.form.validate()

      const validationChecks = []
      for (const inputField of this.inputFields) {
        const refElements = this.$refs[`inputField.${inputField.key}`]
        const refElement = refElements && refElements[0]
        if (refElement && refElement.validate) {
          const validationCheckResult = refElement.validate()
          if (validationCheckResult instanceof Promise) {
            validationChecks.push(await validationCheckResult)
          } else {
            validationChecks.push(validationCheckResult)
          }
        }
      }

      formValidationOk = formValidationOk && validationChecks.every(validationCheck => validationCheck)
      if (!formValidationOk) {
        return false
      }

      if (this.validationHandler) {
        let validationHandlerOk = true
        const validationHandlerResponse = await this.validationHandler(this.editFormData)
        if (validationHandlerResponse) {
          Object.keys(validationHandlerResponse).forEach(fieldKey => {
            const fieldValidationResponse = validationHandlerResponse[fieldKey]
            const refElement = this.$refs[`inputField.${fieldKey}`][0]

            if (typeof fieldValidationResponse === 'boolean' && !fieldValidationResponse) {
              refElement.errorMessages = [this.$t('validation.invalidValue')]
              validationHandlerOk = false
            } else if (typeof fieldValidationResponse === 'string') {
              refElement.errorMessages = [this.$t(fieldValidationResponse)]
              validationHandlerOk = false
            }
          })
        }

        if (!validationHandlerOk) {
          return false
        }
      }

      return true
    },

    getInputFieldRules (inputField) {
      const rules = []
      if (inputField.required || (inputField.rules && inputField.rules.includes('required'))) {
        rules.push((v => typeof v === 'boolean' || !!v) || this.$t('validation.fieldRequired'))
      }
      return rules
    },

    getInputFieldVisible (inputField) {
      if (inputField.visible != null) {
        if (typeof inputField.visible === 'boolean') {
          return inputField.visible
        }
        if (typeof inputField.visible === 'function') {
          return inputField.visible(this.editFormData)
        }
        return true
      }
      return true
    },

    getInputFieldDisabled (inputField) {
      if (inputField.readOnly) {
        return true
      }
      if (inputField.disabledHandler) {
        return inputField.disabledHandler(this.editFormData)
      }
      return false
    },

    getInputFieldAutofocus (inputField) {
      if (this.getInputFieldDisabled(inputField)) {
        return false
      }

      const firstEmptyInputField = this.inputFields
        .filter(inputField => !this.getInputFieldDisabled(inputField))
        .find(inputField => {
          const propertyValue = this.editFormData[inputField.key]
          return propertyValue === null || propertyValue === undefined
        })

      return firstEmptyInputField && inputField.key === firstEmptyInputField.key
    },

    getInputFieldLabel (inputField) {
      if (inputField.label) {
        return this.$t(inputField.label)
      }
      if (inputField.key) {
        return this.$t('label.' + inputField.key)
      }
    },

    resetValidation () {
      this.$refs.form && this.$refs.form.resetValidation()
      this.inputFields.forEach(inputField => {
        if (this.$refs[`inputField.${inputField.key}`]) {
          const refElement = this.$refs[`inputField.${inputField.key}`][0]
          if (refElement && refElement.resetValidation) {
            refElement.resetValidation()
          }
        }
      })
    },

    reset () {
      this.initEditFormData()
      this.resetValidation()
    },

    getEditFormData () {
      const formData = {}
      this.inputFields.forEach(inputField => {
        formData[inputField.key] = this.editFormData[inputField.key]
      })
      return formData
    }
  }
}
</script>
