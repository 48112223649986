<template>
  <t-process-dialog
    id="invoice-payment"
    title="label.payment"
    :statuses="[
        {
          code: 'WAITING_FOR_PAYMENT'
        },
        {
          code: 'CREATING_ONLINE_PAYMENT'
        },
        {
          code: 'WAITING_FOR_ONLINE_PAYMENT',
          action: true,
          actions: [
            {
              handler: invoice.checkout,
              label: 'label.toOnlinePayment'
            }
          ],
          image: invoice.process && invoice.process.qrCode
        },
        {
          code: 'PAID',
          success: true
        },
        {
          code: 'ONLINE_PAYMENT_FAILED',
          actions: [
            {
              handler: invoice.pay,
              label: 'label.pay'
            }
          ],
          image: 'mdi-close-circle'
        },
        {
          code: 'ONLINE_PAYMENT_TIMEOUT',
          actions: [
            {
              handler: invoice.pay,
              label: 'label.pay'
            }
          ],
          image: 'mdi-close-circle'
        },
        {
          code: 'ONLINE_PAYMENT_CANCELED',
          actions: [
            {
              handler: invoice.pay,
              label: 'label.pay'
            }
          ],
          image: 'mdi-close-circle'
        }
      ]"
    :status-code="invoice.process && invoice.process.status"
    :open-handler="handleDialogOpen"
    :close-handler="handleDialogClose"
  />
</template>

<script>
import useInvoice from '@/app/composables/invoice/useInvoice'

export default {
  data () {
    return {
      invoice: useInvoice()
    }
  },

  methods: {
    async handleDialogOpen () {
      this.invoice.setData(this.$dialog.getDialogData('invoice-payment'))
      this.invoice.startProcessSse()
    },

    handleDialogClose () {
      this.invoice.stopProcessSse()
    }
  }
}
</script>
