<template>
  <t-page>
    <t-content-card
      title="label.orders"
      :fetch="orderSearch.fetch"
    >
      <order-table :order-search="orderSearch" />
    </t-content-card>
  </t-page>
</template>

<script>
import useRefresh from '@/lib/composables/useRefresh'
import useOrderSearch from '@/app/composables/order/useOrderSearch'

export default {
  data () {
    return {
      orderSearch: useOrderSearch()
    }
  },

  created () {
    useRefresh().registerListener('order-page', () => this.orderSearch.loader.backgroundRefresh())
  },

  destroyed () {
    useRefresh().unregisterListener('order-page')
  }
}
</script>
