export default {
  props: {
    value: {
    },
    label: {
      type: String
    },
    required: {
      type: Boolean
    },
    disabled: {
      type: Boolean,
      default: false
    },
    changeHandler: {
      type: Function
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    availabilityHandler: {
      type: Function
    },
    minValue: {
    }
  },

  data () {
    return {
      internalValue: null,
      errorMessages: [],
      loading: false
    }
  },

  computed: {
    error () {
      return !this.disabled && this.errorMessages.length > 0
    }
  },

  watch: {
    value () {
      this.initInternalValue()
      this.notifyChangeHandler()
    }
  },

  created () {
    this.initInternalValue()
  },

  methods: {
    initInternalValue () {
      this.internalValue = this.value
    },

    emitValue () {
      this.$emit('input', this.internalValue)
    },

    notifyChangeHandler () {
      if (this.changeHandler) {
        this.changeHandler(this.value)
      }
    },

    async validate () {
      if (this.disabled) {
        this.errorMessages = []
        return true
      }

      if (this.required && (this.value === null || this.value === undefined || this.value === '' || (Array.isArray(this.value) && this.value.length === 0))) {
        this.errorMessages = [this.$t('validation.fieldRequired')]
        return false
      }

      if (this.minValue && this.value != null) {
        const minValue = typeof this.minValue === 'function' ? await this.minValue() : this.minValue
        if (this.value < minValue) {
          this.errorMessages = [this.$t('validation.minValue', { minValue })]
          return false
        }
      }

      if (!this.validateValue(this.value)) {
        return false
      }

      if (this.availabilityHandler) {
        const available = await this.availabilityHandler(this.value)
        if (typeof available === 'boolean' && !available) {
          this.errorMessages = [this.$t('validation.valueNotAvailable')]
          return false
        }
        if (typeof available === 'string' && available) {
          this.errorMessages = [this.$t(available)]
          return false
        }
      }

      this.errorMessages = []
      return true
    },

    validateValue (value) {
      return true
    },

    resetValidation () {
      this.errorMessages = []
    }
  }
}
