import { render, staticRenderFns } from "./TMessage.vue?vue&type=template&id=fd7f2ace&scoped=true&"
import script from "./TMessage.vue?vue&type=script&lang=js&"
export * from "./TMessage.vue?vue&type=script&lang=js&"
import style0 from "./TMessage.vue?vue&type=style&index=0&id=fd7f2ace&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd7f2ace",
  null
  
)

export default component.exports