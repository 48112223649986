<template>
  <t-table
    :item-properties="[
          {
            key: 'name',
            sortable: true
          },
          {
            key: 'numberOfUsers',
            type: 'number',
            sortable: true
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: userGroup => $dialog.openDialog('user-group', userGroup)
          }
        ]"
    :items="userGroupList.userGroups"
    default-sort-property-key="name"
    item-key="id"
    no-items-label="message.noUserGroups"
    :search="true"
  />
</template>

<script>
export default {
  props: {
    userGroupList: {
      required: true
    }
  }
}
</script>
