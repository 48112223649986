<template>
  <t-form
    ref="form"
    :form-data="settings.onlinePaymentSettings"
    :has-changes-handler="hasChanges => $emit('hasChanges', hasChanges)"
    :input-fields="[
          {
            key: 'mollieApiKey',
            type: 'text'
          },
          {
            key: 'allowedForOrders',
            type: 'switch'
          },
          {
            key: 'allowedForTopups',
            type: 'switch'
          },
          {
            key: 'minimumOrderAmount',
            type: 'amount'
          },
          {
            key: 'minimumTopupAmount',
            type: 'amount'
          }
        ]"
    :validation-handler="handleOnlinePaymentSettingsValidation"
  />
</template>

<script>
export default {
  props: {
    settings: {
      required: true
    }
  },

  computed: {
    editFormData () {
      return this.$refs.form && this.$refs.form.editFormData
    }
  },

  methods: {
    validate () {
      return this.$refs.form.validate()
    },

    reset () {
      return this.$refs.form.reset()
    },

    handleOnlinePaymentSettingsValidation (onlinePaymentSettings) {
      if ((onlinePaymentSettings.allowedForOrders || onlinePaymentSettings.allowedForTopups) && !onlinePaymentSettings.mollieApiKey) {
        return {
          mollieApiKey: 'validation.mollieApiKeyRequired'
        }
      }
    }
  }
}
</script>
