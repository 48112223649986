<template>
  <v-dialog
      :max-width="maxWidth"
      persistent
      :value="$dialog.isDialogOpen(id)"
      @keydown.esc="$dialog.closeDialog(id)"
      @click:outside="() => !persistent && $dialog.closeDialog(id)"
  >
    <v-card v-show="$dialog.isDialogOnTop(id)">
      <v-card-title>
        <span>{{ $t(title) }}</span>
      </v-card-title>

      <div style="height: 4px">
        <v-divider v-if="!loader || !loader.loading"/>
        <v-progress-linear v-else :height="2" color="primary" indeterminate/>
      </div>

      <slot/>

    </v-card>
  </v-dialog>
</template>

<script>
import dialog from '../../mixins/dialog'

export default {
  mixins: [
    dialog
  ],

  props: {
    title: {
      type: String,
      required: true
    },
    loader: {
      type: Object
    },
    persistent: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: String,
      default: '600px'
    }
  }
}
</script>

<style scoped>
.word-wrap {
  word-break: break-word;
}
</style>
