<template>
  <v-dialog :value="$dialog.isDialogOpen(id)" persistent max-width="600px">
    <v-card v-show="$dialog.isDialogOnTop(id)">
      <v-card-title>
        <span>{{ $t(title) }}</span>
      </v-card-title>

      <v-divider class="mb-4"/>

      <v-card-text>
        <span>{{ $t(message) }}</span>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>

        <v-btn
            v-for="(actionButton, index) in actionButtons"
            :key="index"
            text
            @click="actionButtonClicked(actionButton)"
            :color="actionButton.color ? actionButton.color : ''"
        >{{ $t(actionButton.label) }}</v-btn>

        <v-btn
            text
            @click="handleCloseClick"
            color="primary"
        >{{ $t(closeLabel) }}</v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialog from '@/lib/mixins/dialog'

export default {
  mixins: [
    dialog
  ],

  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    actionButtons: {
      type: Array,
      default: () => []
    },
    closeLabel: {
      type: String,
      default: 'label.close'
    },
    closeHandler: {
      type: Function,
      default: () => {}
    }
  },

  methods: {
    actionButtonClicked (actionButton) {
      actionButton.handler && actionButton.handler()
      this.$dialog.closeDialog(this.id)
    },

    handleCloseClick () {
      this.closeHandler && this.closeHandler()
      this.$dialog.closeDialog(this.id)
    }
  }
}
</script>

<style scoped>
.word-wrap {
  word-break: break-word;
}
</style>
