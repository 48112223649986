import Vue from 'vue'

const useEntity = function (template, dataEnricher) {
  const entity = Vue.observable(Object.assign({}, template))

  entity.setData = data => {
    Object.assign(entity, template)
    if (data) {
      if (typeof data === 'string') {
        console.warn('received string data for entity')
        console.log(data)
        data = JSON.parse(data)
      }
      Object.keys(template).forEach(templateKey => {
        if (!Array.isArray(entity[templateKey]) || !!data[templateKey]) {
          entity[templateKey] = data[templateKey]
        }
      })
    }
    if (dataEnricher) {
      dataEnricher(entity)
    }
  }

  return entity
}

export default useEntity
