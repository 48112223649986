<template>
  <div>
    <v-chip
        v-for="containerItem in containerItems"
        :key="containerItem.key"
        @click="handleContainerItemClick(containerItem)"
        :draggable="containerItem.draggable"
        @dragover="handleContainerItemDragOver($event, containerItem)"
        @dragstart="handleContainerItemDragStart($event, containerItem)"
        @drop="handleContainerItemDrop(containerItem)"
        class="mr-2 mb-2"
        :class="{
          'background-color-primary-dark': containerItem.selected,
          'color-primary': containerItem.selected
        }"
    >

      <span>{{ containerItem.text }}</span>
      <v-icon v-if="containerItem.icon">{{ containerItem.icon.image }}</v-icon>

      <v-divider class="ml-2" v-if="itemActions(containerItem.item).length" vertical></v-divider>

      <div v-if="itemActions(containerItem.item)">
        <v-btn
            v-for="(action, index) in itemActions(containerItem.item)"
            :key="index"
            @click.stop="action.handler(containerItem.item)"
            @mousedown.stop
            class="ml-1"
            icon x-small>
          <v-icon small>{{ action.icon }}</v-icon>
        </v-btn>
      </div>
    </v-chip>
  </div>
</template>

<script>
import itemContainer from '../../mixins/itemContainer'

export default {
  mixins: [
    itemContainer
  ],

  props: {
    actions: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    itemActions (item) {
      return this.actions.filter(action => {
        if (action.visibleHandler && !action.visibleHandler(item)) {
          return false
        }
        return true
      })
    }
  }
}
</script>
