<template>
  <v-select
      :disabled="disabled"
      item-text="text"
      item-value="value"
      :items="containerItems"
      :label="label"
      @change="handleOnChange"
      :value="internalValue"
      :error-messages="errorMessages"
      no-data-text=""
  />
</template>

<script>
import inputField from '../../mixins/inputField'
import itemContainer from '../../mixins/itemContainer'

export default {
  mixins: [
    inputField,
    itemContainer
  ],

  methods: {
    handleOnChange (selectedValue) {
      this.internalValue = selectedValue
      this.emitValue()
    }
  }
}
</script>
