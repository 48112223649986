import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useEntity from '@/lib/composables/useEntity'

const tenantSelection = useTenantSelection()

export default function () {
  const deviceTemplate = {
    loader: null,
    id: null,
    serial: null,
    name: null,
    type: null,
    model: null,
    applicationVersion: null,
    pincode: null,
    tenantShortName: null,
    locale: null,
    online: null,
    paymentQrCodeId: null,
    formattedPaymentQrCodeId: null
  }

  const device = useEntity(deviceTemplate, device => {
    if (device.serial && !device.id) {
      device.id = device.serial
    } else if (device.id && !device.serial) {
      device.serial = device.id
    }

    if (device.paymentQrCodeId) {
      const part1 = device.paymentQrCodeId.substring(0, 2)
      const part2 = device.paymentQrCodeId.substring(2, 4)
      const part3 = device.paymentQrCodeId.substring(4, 6)
      const part4 = device.paymentQrCodeId.substring(6, 8)
      device.formattedPaymentQrCodeId = `${part1} ${part2} ${part3} ${part4}`.toUpperCase()
    }
  })

  device.save = async editDevice => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/devices/${editDevice.id}`, {
      body: {
        name: editDevice.name,
        pincode: editDevice.pincode,
        tenantShortName: editDevice.tenantShortName,
        locale: editDevice.locale,
        paymentQrCodeId: editDevice.paymentQrCodeId
      }
    })
    if (await fetch.promise) {
      device.setData(fetch.data)
      useEvent().fire('device-save', device)
    }
  }

  device.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/devices/${device.id}`)
    if (await fetch.promise) {
      useEvent().fire('device-delete', device)
    }
  }

  return device
}
