import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useTenant from '@/app/composables/tenant/useTenant'
import $authorization from '@/lib/authorization'
import useLoader from '@/lib/composables/useLoader'
import useEvent from '@/lib/composables/useEvent'

const tenantSelection = Vue.observable({
  loader: useLoader(),
  selectedTenant: null,
  tenants: [],
  tenantId: null,
  tenantName: null
})

tenantSelection.load = async () => {
  await tenantSelection.loader.load(async () => {
    const tenants = []

    const userTenantsFetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/users/${$authorization.user.userId}/tenants`)
    await userTenantsFetch.promise

    userTenantsFetch.data.forEach(userTenantData => {
      const tenant = useTenant()
      tenant.setData(userTenantData)
      tenants.push(tenant)
    })

    const adminForTenants = $authorization.user.attributes['admin-for-tenants'] || []
    const adminTenantFetches = adminForTenants
      .filter(tenantId => !tenants.find(tenant => tenant.id === tenantId))
      .map(tenantId => $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantId}`))

    for (const adminTenantFetch of adminTenantFetches) {
      await adminTenantFetch.promise
    }

    adminTenantFetches.forEach(adminTenantFetch => {
      const tenant = useTenant()
      tenant.setData(adminTenantFetch.data)
      tenants.push(tenant)
    })

    tenantSelection.tenants = tenants
  })
}

tenantSelection.selectTenant = async tenant => {
  if (tenant) {
    await $authorization.refreshUser()
    tenantSelection.selectedTenant = tenant
    tenantSelection.tenantId = tenant.id
    tenantSelection.tenantName = tenant.name
    useEvent().fire('tenant-select', tenant)
  } else {
    tenantSelection.selectedTenant = null
    tenantSelection.tenantId = null
    tenantSelection.tenantName = null
  }
}

tenantSelection.cancelSelection = () => {
  if (!tenantSelection.selectedTenant) {
    $authorization.logout()
  }
}

export default function () {
  return tenantSelection
}
