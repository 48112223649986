import Vue from 'vue'

const event = Vue.observable({
  listeners: []
})

event.registerListener = (listenerId, eventId, listener) => {
  event.listeners.push({
    listenerId,
    eventId,
    listener
  })
}

event.unregisterListener = listenerId => {
  event.listeners = event.listeners.filter(listener => listener.listenerId !== listenerId)
}

event.fire = (eventId, data) => {
  event.listeners
    .filter(listener => {
      if (!listener.eventId) {
        return true
      }
      if (Array.isArray(listener.eventId)) {
        return listener.eventId.some(i => i === eventId)
      }
      return listener.eventId === eventId
    })
    .forEach(listener => listener.listener(data))
}

export default function () {
  return event
}
