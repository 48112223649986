import $fetcher from '@/lib/fetcher'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useUser from '@/app/composables/user/useUser'
import useTranslate from '@/lib/composables/useTranslate'
import useSearch from '@/lib/composables/useSearch'

const tenantSelection = useTenantSelection()
const translate = useTranslate()

export default function () {
  const userSearch = useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/users`, data => {
    const user = useUser()
    user.setData(data)
    return user
  })

  userSearch.findById = async userId => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/users/${userId}`)
    if (await fetch.promise) {
      const user = useUser()
      user.setData(fetch.data)
      return user
    }
  }

  userSearch.findByIdWithDeletionPlaceholder = async userId => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/users?searchText=${userId}`)
    if (await fetch.promise) {
      const userData = fetch.data.data.find(user => user.id === userId) || {
        id: userId,
        firstName: `${userId} (${translate.label.deletedUser})`,
        lastName: ''
      }
      const user = useUser()
      user.setData(userData)
      return user
    }
  }

  return userSearch
}
