import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useLoader from '@/lib/composables/useLoader'
import useDevice from '@/app/composables/device/useDevice'

const tenantSelection = useTenantSelection()

export default function () {
  const deviceList = Vue.observable({
    loader: useLoader(),
    items: [],
    fetch: null
  })

  deviceList.setData = data => {
    deviceList.items = data.map(deviceData => {
      const device = useDevice()
      device.setData(deviceData)
      return device
    })
  }

  deviceList.load = async (query) => {
    await deviceList.loader.load(async background => {
      if (background) {
        await deviceList.fetch.executeFetch()
      } else {
        deviceList.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/devices${query ? `?${query}` : ''}`)
        await deviceList.fetch.promise
      }
      deviceList.setData(deviceList.fetch.data)
    })
  }

  return deviceList
}
