<template>
  <t-edit-dialog
    id="user-group"
    :delete-handler="userGroup.delete"
    :input-fields="[
          {
            key: 'name',
            type: 'text',
            label: 'label.name',
            required: true
          },
          {
            key: 'userIds',
            type: 'auto-complete-list',
            label: 'label.users',
            items: userSearch.items,
            itemKey: 'id',
            itemText: 'fullName',
            itemFilteringHandler: userSearch.search,
            itemByValueHandler: userSearch.findById,
            required: true
          }
        ]"
    :item="userGroup"
    :save-handler="userGroup.save"
    :uuid-item="true"
    :open-handler="onOpen"
  />
</template>

<script>
import useUserGroup from '@/app/composables/user/useUserGroup'
import useUserSearch from '@/app/composables/user/useUserSearch'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      userGroup: useUserGroup(),
      userSearch: useUserSearch()
    }
  },

  created () {
    useEvent().registerListener('user-group-dialog', ['user-group-save', 'user-group-delete'], () => this.$dialog.closeDialog('user-group'))
  },

  destroyed () {
    useEvent().unregisterListener('user-group-dialog')
  },

  methods: {
    onOpen () {
      this.userSearch = useUserSearch()
      this.userGroup.setData(this.$dialog.getDialogData('user-group'))
    }
  }
}
</script>

<style scoped>

</style>
