<template>
  <t-selection-dialog
    id="tenant-selection"
    :cancel-handler="tenantSelection.cancelSelection"
    :input-fields="[
        {
          key: 'id',
          type: 'text',
          label: 'label.tenantId',
          availabilityHandler: handleTenantIdAvailability,
          required: true,
          readOnlyForEdit: true,
          alphanumeric: true,
          lowercase: true
        },
        {
          key: 'name',
          type: 'text',
          label: 'label.name',
          required: true
        }
      ]"
    :item-editable="tenant => tenant.owner"
    :items="tenantSelection.tenants"
    :save-handler="handleSave"
    :selectionHandler="tenant => tenantSelection.selectTenant(tenant)"
    item-key="id"
    item-text="name"
    no-items-label="message.tenantSelectionEmpty"
    subtitle="message.tenantSelection"
    title="label.tenants"
  />
</template>

<script>
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useTenant from '@/app/composables/tenant/useTenant'

export default {
  data () {
    return {
      tenantSelection: useTenantSelection()
    }
  },

  methods: {
    async handleTenantIdAvailability (id) {
      const tenant = useTenant()
      return tenant.getTenantIdAvailability(id)
    },

    async handleSave (editTenant) {
      const tenant = useTenant()
      await tenant.save(editTenant)
      return true
    }
  }
}
</script>
