<template>
  <v-input
      :error-messages="errorMessages"
      :label="label"
      class="t-list-select-field"
  >
    <v-list-item-group
        :value="selectedValueIndex"
        @change="handleOnChange"
        :mandatory="true"
    >
      <v-list-item
          v-for="listItem in listItems"
          :key="listItem.key"
          :disabled="disabled || listItem.disabled"
          :color="disabled || listItem.disabled ? '' : 'primary'"
      >
        <v-list-item-content>
          <v-list-item-title v-text="listItem.text"/>
          <v-list-item-subtitle
              v-for="description in listItem.descriptions"
              :key="description"
              v-text="description"
              :class="disabled || listItem.disabled ? 'color-grey' : ''"
          />
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-input>
</template>

<script>
import inputField from '../../mixins/inputField'
import itemContainer from '../../mixins/itemContainer'

export default {
  mixins: [
    inputField,
    itemContainer
  ],

  props: {
    itemDescription: {
      type: [String, Function]
    }
  },

  data () {
    return {
      selectedValueIndex: null
    }
  },

  computed: {
    listItems () {
      return this.containerItems.map(containerItem => {
        const description = this.itemDescription && this.$items.getItemProperty(containerItem.item, this.itemDescription)
        return {
          ...containerItem,
          descriptions: description.split('\n')
        }
      })
    }
  },

  watch: {
    containerItems () {
      this.initInternalValue()
    }
  },

  methods: {
    initInternalValue () {
      this.internalValue = this.value
      const selectedValueIndexSearch = this.containerItems && this.containerItems.findIndex(item => item.value === this.value)
      this.selectedValueIndex = selectedValueIndexSearch >= 0 ? selectedValueIndexSearch : null
    },

    handleOnChange (selectedValueIndex) {
      this.selectedValueIndex = selectedValueIndex
      if (selectedValueIndex == null) {
        this.internalValue = null
      } else {
        this.internalValue = this.listItems[selectedValueIndex].value
      }
      this.emitValue()
    }
  }
}
</script>

<style>
.t-list-select-field .v-input__slot {
  display: block;
  margin: 0;
}
</style>
