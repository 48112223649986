<template>
  <div>
    <t-table
      :items="[
          {
            sum: $t('label.totalAmount'),
            amount: report.totalAmount
          }
        ]"
      item-key="key"
      :item-properties="[
          {
            key: 'sum'
          },
          {
            key: 'amount',
            type: 'amount'
          }
        ]"
      style="display: inline-block; width: 30%; vertical-align: top; min-width: 350px; margin-right: 16px"
    />

    <t-table
      :items="[
          {
            sum: $t('label.numberOfAssignments'),
            count: report.numberOfAssignments
          },
          {
            sum: $t('label.numberOfRewards'),
            count: report.numberOfRewards
          },
          {
            sum: $t('label.numberOfGrantees'),
            count: report.numberOfGrantees
          }
        ]"
      item-key="key"
      :item-properties="[
          {
            key: 'sum'
          },
          {
            key: 'count',
            type: 'number'
          }
        ]"
      style="display: inline-block; width: 30%; vertical-align: top; min-width: 350px"
    />

    <t-table
      class="mt-8"
      :items="report.assignments"
      item-key="id"
      :item-properties="[
          {
            key: 'dateTime',
            type: 'date-time',
            sortable: true
          },
          {
            key: 'reason',
            sortable: true
          },
          {
            key: 'userId',
            sortable: true
          },
          {
            key: 'amount',
            type: 'amount',
            sortable: true
          }
        ]"
      default-sort-property-key="dateTime"
    />
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Object
    }
  }
}
</script>
