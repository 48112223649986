<template>
  <t-table
    :items="deviceList.items"
    item-key-property="id"
    :item-properties="[
          {
            key: 'type',
            type: 'icon',
            icon: device => {
              switch (device.type) {
                case 'CASHIER': return {
                  image: 'mdi-cash-register',
                  tooltip: 'label.cashier'
                }
                case 'SCANNER': return {
                  image: 'mdi-credit-card-scan',
                  tooltip: 'label.scanner'
                }
              }
            },
            sortable: true
          },
          {
            key: 'id',
            header: 'label.serialNumber',
            sortable: true
          },
          {
            key: 'name',
            sortable: true
          },
          {
            key: 'model',
            header: 'label.model',
            sortable: true
          },
          {
            key: 'formattedPaymentQrCodeId',
            header: 'label.paymentQrCode',
            sortable: true
          },
          {
            key: 'online',
            type: 'icon',
            icon: device => device.online ? { image: 'mdi-check-circle', color: 'primary' } : null,
            sortable: true,
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: device => $dialog.openDialog('device', device)
          }
        ]"
    :search="true"
    default-sort-property-key="online"
    no-items-label="message.noDevices"
  />
</template>

<script>
export default {
  props: {
    deviceList: {
      required: true
    }
  }
}
</script>
