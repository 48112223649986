<template>
  <t-table
    :item-filtering-handler="userSearch.search"
    :item-properties="[
          {
            key: 'id',
            header: 'label.username',
            sortable: true
          },
          {
            key: 'firstName',
            sortable: true
          },
          {
            key: 'lastName',
            sortable: true
            },
          {
            key: 'email',
            sortable: true
          },
          {
            key: 'disabled',
            type: 'icon',
            icon: user => user.disabled || user.disabledForTenant ? { image: 'mdi-account-off', color: 'error' } : null,
            sortable: true
          },
          {
            key: 'admin',
            header: 'label.administrator',
            type: 'icon',
            icon: user => user.admin ? { image: 'mdi-check-circle', color: 'primary' } : null,
            sortable: true
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: user =>$dialog.openDialog('user', user)
          }
        ]"
    :items="userSearch"
    :search="true"
    default-sort-property-key="id"
    item-key="id"
    no-items-label="message.noUsers"
  />
</template>

<script>
export default {
  props: {
    userSearch: {
      required: true
    }
  }
}
</script>
