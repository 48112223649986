import Vue from 'vue'
import useLoader from '@/lib/composables/useLoader'
import useFetch from '@/lib/composables/useFetch'

export default function (url, dataMapper) {
  const list = Vue.observable({
    loader: useLoader(),
    items: []
  })

  list.list = async () => {
    await list.loader.load(async () => {
      const responseItems = await useFetch().get(url instanceof Function ? await url() : url)
      list.items = await Promise.all(responseItems.map(async responseItem => {
        return await dataMapper(responseItem)
      }))
    })
    return list.items
  }

  list.refresh = async () => {
    return list.loader.refresh()
  }

  list.backgroundRefresh = async () => {
    return list.loader.backgroundRefresh()
  }

  return list
}
