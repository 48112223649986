var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"reward","action-chips":[
      {
        label: 'label.userGroup',
        icon: 'mdi-plus-circle',
        handler: () => _vm.event.fire('user-group-select', _vm.addUserGroup),
        hideHandler: reward => reward.assigned,
        disabledHandler: () => _vm.userGroupList.userGroups.length === 0
      },
      {
        label: 'label.assignNow',
        icon: 'mdi-gift',
        handler: _vm.reward.assign,
        hideForNew: true,
        hideHandler: reward => reward.assigned,
        closeDialog: true
      }
    ],"copy-properties":['assigned'],"delete-handler":!_vm.reward.assigned ? _vm.reward.delete : undefined,"input-fields":[
      {
        key: 'reason',
        type: 'text',
        label: 'label.reason',
        required: true
      },
      {
        key: 'amount',
        type: 'amount',
        label: 'label.amount',
        required: true
      },
      {
        key: 'userIds',
        type: 'auto-complete-list',
        label: 'label.users',
        items: _vm.userSearch.items,
        itemKey: 'id',
        itemFilteringHandler: _vm.userSearch.search,
        itemByValueHandler: _vm.userSearch.findByIdWithDeletionPlaceholder,
        itemText: 'fullName',
        required: true
      }
    ],"item":_vm.reward,"open-handler":_vm.onOpen,"read-only-for-edit":_vm.reward.assigned,"save-handler":_vm.reward.save,"uuid-item":true,"edit-subtitle":"message.editReward","new-subtitle":"message.newReward","view-subtitle":"message.viewReward"}})
}
var staticRenderFns = []

export { render, staticRenderFns }