import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useLoader from '@/lib/composables/useLoader'
import useEntity from '@/lib/composables/useEntity'

const tenantSelection = useTenantSelection()

export default function () {
  const subscriptionTemplate = {
    tenantId: null,
    subscriptionDate: null,
    plan: null,
    nextPlan: null,
    currentInvoicingBeginDate: null,
    currentInvoicingEndDate: null,
    currentInvoicingOrders: null,
    remainingFreeOrders: null,
    editPlan: null
  }

  const subscription = useEntity(subscriptionTemplate, subscription => {
    subscription.editPlan = subscription.nextPlan || subscription.plan
  })

  subscription.loader = useLoader()
  subscription.fetch = null

  subscription.load = async () => {
    await subscription.loader.load(async () => {
      subscription.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/subscription`)
      if (await subscription.fetch.promise) {
        subscription.setData(subscription.fetch.data)
      }
    })
  }

  subscription.save = async (editSubscription = subscription) => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/subscription`, {
      body: {
        nextPlan: editSubscription.editPlan
      }
    })

    if (await fetch.promise) {
      subscription.setData(fetch.data)
      useEvent().fire('subscription-save', subscription)
    }
  }

  return subscription
}
