import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useCard from '@/app/composables/card/useCard'
import useSearch from '@/lib/composables/useSearch'

const tenantSelection = useTenantSelection()

export default function (userId) {
  let url = `${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/cards`
  if (userId) {
    url += `?userId=${userId}`
  }

  return useSearch(url, data => {
    const card = useCard()
    card.setData(data)
    return card
  })
}
