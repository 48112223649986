<template>
  <v-text-field
    ref="textField"
    :label="label"
    :value="internalValue"
    @input="handleOnInput"
    @blur="handleOnBlur"
    :prefix="internalValue || internalValue === '0' ? '€' : ''"
    autocomplete="off"
    :disabled="disabled"
    :error-messages="errorMessages"
  />
</template>

<script>
import inputField from '../../mixins/inputField'

export default {
  mixins: [
    inputField
  ],

  props: {
    value: {
      type: Number
    }
  },

  methods: {
    initInternalValue () {
      if (!this.value && this.value !== 0) {
        this.internalValue = null
        return
      }

      let stringValue = this.value.toString()
      stringValue = stringValue.replace(/\./g, ',')

      if (stringValue === this.internalValue) {
        return
      }

      const firstSeparatorIndex = stringValue.indexOf(',')
      if (firstSeparatorIndex > 0) {
        const decimals = stringValue.slice(firstSeparatorIndex + 1)
        if (decimals.length === 1) {
          stringValue = stringValue + '0'
        } else if (decimals.length === 1) {
          stringValue = stringValue.substr(0, stringValue.length - 1)
        }
      }

      this.internalValue = stringValue
    },

    emitValue () {
      if (!this.internalValue) {
        this.$emit('input', null)
      } else {
        this.$emit('input', Number(this.internalValue.replace(/,/g, '.')))
      }
    },

    handleOnInput (value) {
      let correctedValue = value.replace(/[^0-9.,]/g, '')

      correctedValue = correctedValue.replace(/\./g, ',')

      if (correctedValue.startsWith(',')) {
        correctedValue = correctedValue.slice(1)
      }

      const firstSeparatorIndex = correctedValue.indexOf(',')
      if (firstSeparatorIndex !== correctedValue.lastIndexOf(',')) {
        correctedValue = correctedValue.replace(/,/g, '')
        correctedValue = correctedValue.slice(0, firstSeparatorIndex) + ',' + correctedValue.slice(firstSeparatorIndex)
      }

      if (firstSeparatorIndex > -1) {
        correctedValue = correctedValue.slice(0, firstSeparatorIndex + 3)
      }

      this.internalValue = correctedValue
      this.$refs.textField.lazyValue = correctedValue

      this.emitValue()
    },

    handleOnBlur () {
      const firstSeparatorIndex = this.internalValue ? this.internalValue.indexOf(',') : -1
      if (firstSeparatorIndex > 0) {
        const decimals = this.internalValue.slice(firstSeparatorIndex + 1)
        if (decimals.length === 1) {
          this.internalValue = this.internalValue + '0'
          this.$refs.textField.lazyValue = this.internalValue
        } else if (decimals.length === 0) {
          this.internalValue = this.internalValue.substr(0, this.internalValue.length - 1)
          this.$refs.textField.lazyValue = this.internalValue
        }
      }

      this.emitValue()
    }
  }
}
</script>
