<template>
  <t-table
    :item="qrCodeList.items"
    :item-properties="[
          {
            header: 'label.id',
            key: 'formattedId',
            sortable: true
          },
          {
            key: 'name',
            sortable: true
          },
          {
            key: 'type',
            value: qrCode => qrCodeType.getLabel(qrCode.type),
            sortable: true
          },
          {
            header: 'label.cashier',
            key: 'cashierName',
            sortable: true
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: card => event.fire('qr-code-edit', card)
          }
        ]"
    :items="qrCodeList.items"
    :search="true"
    item-key="id"
    no-items-label="message.noDataFound"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useQrCodeType from '@/app/composables/qrcode/useQrCodeType'

export default {
  props: {
    qrCodeList: null
  },

  data () {
    return {
      qrCodeType: useQrCodeType(),
      event: useEvent()
    }
  }
}
</script>
