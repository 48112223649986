<template>
  <div class="my-2">
    <div class="t-button-selection d-inline-flex flex-row align-center">

      <span class="mr-4">{{ $t(label)}} </span>

      <v-divider/>

      <v-btn-toggle
          :value="selectedOptionValue"
          @change="selectionChanged"
          :mandatory="mandatory"
          dense>

        <v-btn
            v-for="option in options"
            :key="optionKey ? option[optionKey] : option"
            :value="optionKey ? option[optionKey] : option">

          {{ optionLabel ? option[optionLabel] : option}}
        </v-btn>

      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Object
    },
    options: {
      type: Array,
      required: true
    },
    optionKey: {
      type: String
    },
    optionLabel: {
      type: String
    },
    mandatory: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selectedOption: this.value
    }
  },

  computed: {
    selectedOptionValue () {
      if (this.selectedOption) {
        return this.optionKey ? this.selectedOption[this.optionKey] : this.selectedOption
      }
      return null
    }
  },

  watch: {
    value () {
      this.selectedOption = this.value
    }
  },

  methods: {
    selectionChanged (value) {
      const newSelectedOption = this.options.find(option => {
        if (this.optionKey) {
          return option[this.optionKey] === value
        }
        return option === value
      })
      this.$emit('input', newSelectedOption)
    }
  }
}
</script>

<style>
  .t-button-selection * .v-item--active {
    color: #FFC045 !important;
  }
</style>
