import Vue from 'vue'

const $items = Vue.observable({

  getItemProperty (item, key) {
    if (!item || !key) {
      return null
    }
    if (key instanceof Function) {
      return key(item)
    }
    if (item[key]) {
      return item[key]
    }
    if (key.split) {
      return key.split('.').reduce((i, p) => i ? i[p] : null, item)
    }
    return null
  },

  sortItems (items, key, sortAscending = true) {
    items.sort((i1, i2) => {
      let v1 = $items.getItemProperty(i1, key)
      let v2 = $items.getItemProperty(i2, key)

      if (v1 && v1.toLowerCase) {
        v1 = v1.toLowerCase()
      }
      if (v2 && v2.toLowerCase) {
        v2 = v2.toLowerCase()
      }

      if (v1 === v2) {
        return 0
      }
      if (v1 == null) {
        return sortAscending ? 1 : -1
      }
      if (v2 == null) {
        return sortAscending ? -1 : 1
      }
      if (typeof v1 === 'boolean' && v1) {
        return sortAscending ? -1 : 1
      }
      if (typeof v1 === 'boolean' && !v1) {
        return sortAscending ? 1 : -1
      }
      if (v1 > v2) {
        return sortAscending ? 1 : -1
      }
      return sortAscending ? -1 : 1
    })
  },

  extractItem (item) {
    if (item === null || item === undefined) {
      return null
    }
    if (typeof item === 'function') {
      item = item()
    }
    return item
  },

  filterSearchText (itemText, searchText) {
    if (searchText == null || searchText === '') {
      return true
    }

    if (itemText == null || !itemText.toString()) {
      return false
    }

    itemText = itemText.toString().toLowerCase()
    return searchText.toLowerCase().split(' ').every(searchTextPart => {
      return searchTextPart === '' || itemText.indexOf(searchTextPart) > -1
    })
  }
})

export default $items
