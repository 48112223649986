var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-form',{ref:"form",attrs:{"form-data":_vm.settings.onlineOrderSettings,"has-changes-handler":hasChanges => _vm.$emit('hasChanges', hasChanges),"input-fields":[
    {
      key: 'enabled',
      type: 'switch',
      required: true
    }
  ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }