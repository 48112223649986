import Vue from 'vue'

import $authorization from './authorization'
import $canvas from './canvas.js'
import $date from './date'
import $dialog from './dialog'
import $dragger from './dragger'
import $fetcher from './fetcher'
import $items from './items'
import $logger from './logger'
import $notification from './notification'
import $sse from './sse'
import $router from './router'
import $vuetify from './vuetify'
import $i18n from './i18n'
import $formatter from './formatter'
import _ from 'lodash'

import './components'

const VueBase = {
  init (config) {
    Vue.mixin({
      beforeCreate () {
        this.$authorization = $authorization
        this.$canvas = $canvas
        this.$date = $date
        this.$dialog = $dialog
        this.$dragger = $dragger
        this.$fetcher = $fetcher
        this.$items = $items
        this.$logger = $logger
        this.$notification = $notification
        this.$sse = $sse
        this.$formatter = $formatter
        this.$lodash = _
      }
    })

    Vue.prototype.$super = function (options) {
      return new Proxy(options, {
        get: (options, name) => {
          if (options.methods && name in options.methods) {
            return options.methods[name].bind(this)
          }
        }
      })
    }

    new Vue({
      router: $router.create(config.routes),
      vuetify: $vuetify.create(),
      i18n: $i18n.create(config.messages),
      render: h => h(config.app)
    }).$mount('#app')
  }
}

export default VueBase
