<template>
  <v-dialog :value="open" persistent max-width="600px">
    <v-card v-show="onTop">
      <v-card-title>
        <span>{{ $t(title) }}</span>
      </v-card-title>

      <v-card-text>
        <span>{{ $t(message) }}</span>
      </v-card-text>

      <v-card-text
        v-if="inputFields.length"
      >
        <t-form
          ref="inputForm"
          :input-fields="inputFields"
        />
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text color="warning" @click="$dialog.closeDialog(id, closeHandler)">{{ $t('label.cancel') }}</v-btn>
        <v-btn text :color="confirmDanger ? 'error' : 'primary'" @click="confirmButtonClicked">{{ $t('label.confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialog from '../../mixins/dialog'

export default {
  mixins: [
    dialog
  ],

  props: {
    title: {
      type: String
    },
    message: {
      type: String
    },
    confirmHandler: {
      type: Function,
      required: true
    },
    confirmDanger: {
      type: Boolean,
      default: false
    },
    inputFields: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    onOpen () {
      this.$refs.inputForm && this.$refs.inputForm.reset()
      this.openHandler && this.openHandler()
    },

    async confirmButtonClicked () {
      if (this.inputFields.length) {
        const validInput = await this.$refs.inputForm.validate()
        if (validInput) {
          this.confirmHandler(this.$refs.inputForm.editFormData)
          this.$dialog.closeDialog(this.id, this.closeHandler)
        }
      } else {
        this.confirmHandler()
        this.$dialog.closeDialog(this.id, this.closeHandler)
      }
    }
  }
}
</script>

<style scoped>
  .word-wrap {
    word-break: break-word;
  }
</style>
