<template>
  <t-edit-dialog
    id="subscription"
    :action-chips="[
      {
        icon: 'mdi-lead-pencil',
        label: 'label.invoicingSettings',
        handler: () => $dialog.openDialog('invoicing-settings'),
        hideHandler: () =>! this.subscriptionPlanList.invoicingSettingsNeeded
      }
    ]"
    :input-fields="[
        {
          key: 'editPlan',
          label: 'label.plan',
          type: 'list-select',
          items: subscriptionPlanList.subscriptionPlans,
          itemKey: 'id',
          itemText: 'label',
          itemDescription: 'description',
          itemDisabled: subscriptionPlan => subscriptionPlan.disabled
        }
      ]"
    :item="subscription"
    :open-handler="handleOnOpen"
    :save-handler="handleSave"
    :subtitle="subtitle"
  />
</template>

<script>
import useSubscription from '@/app/composables/subscription/useSubscription'
import useEvent from '@/lib/composables/useEvent'
import useSubscriptionPlanList from '@/app/composables/subscription/useSubscriptionPlanList'

export default {
  data () {
    return {
      subscription: useSubscription(),
      subscriptionPlanList: useSubscriptionPlanList()
    }
  },

  computed: {
    subtitle () {
      if (this.subscriptionPlanList.invoicingSettingsNeeded) {
        return this.$t('message.invoicingInformationRequiredForPaidServices')
      }
      return null
    }
  },

  created () {
    useEvent().registerListener('subscription-dialog', 'subscription-save', () => {
      this.$dialog.closeDialog('subscription')
    })
    useEvent().registerListener('subscription-dialog', 'tenant-settings-save', () => {
      this.subscriptionPlanList.load()
    })
  },

  destroyed () {
    useEvent().unregisterListener('subscription-dialog')
  },

  methods: {
    handleOnOpen () {
      this.subscription.load()
      this.subscriptionPlanList.load()
    },

    async handleSave (editSubscription) {
      await this.subscription.loader.promise
      if (this.subscription.plan === 'FREE' && editSubscription.nextPlan !== 'FREE') {
        this.$dialog.openDialog('paid-subscription-confirm', null, confirm => {
          if (confirm) {
            this.subscription.save(editSubscription)
          }
        })
      } else {
        this.subscription.save(editSubscription)
      }
    }
  }
}
</script>
