<template>
  <div
    class="d-flex flex-row align-center"
    :class="{
      'pa-1': selectable,
      'selected': selectable && selected,
      'elevation-4': selectable && selected
    }"
    :style="`width: ${displaySize + 8}px;`">

    <img
      :src="image"
      :height="displaySize + 'px'"
      :width="displaySize + 'px'"
      ref="image"
      v-if="image"
      @click="onClick"
    />
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    selected: Boolean,
    selectable: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      imageSource: null,
      displaySize: 64
    }
  },

  methods: {
    onClick () {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped>
  .selected {
    border-radius: 5px;
    background: #FFC045;
  }
</style>
