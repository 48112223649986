import $logger from './logger'
import $authorization from './authorization'
import { EventSourcePolyfill } from 'event-source-polyfill'

export class Sse {
  url = null
  initOptions = null
  status = null
  data = null
  eventSource = null

  constructor (url, initOptions = {}) {
    this.url = encodeURI(url)
    this.initOptions = initOptions
    this.status = 'pending'
    this.data = null
  }

  isPending () {
    return this.status === 'pending'
  }

  isOpen () {
    return this.status === 'open'
  }

  isClosed () {
    return this.status === 'closed'
  }

  isError () {
    return this.status === 'error'
  }

  close () {
    $logger.debug(`closing sse to url ${this.url}`)
    this.eventSource && this.eventSource.close()
    this.status = 'closed'
  }

  start () {
    this.close()
    this.status = 'pending'

    $logger.debug(`opening sse connection to url ${this.url}`)

    $authorization.getAuthorizationHeader().then(authorizationHeader => {
      if (!this.initOptions.headers) {
        this.initOptions.headers = {}
      }
      if (authorizationHeader) {
        this.initOptions.headers.Authorization = authorizationHeader
      }
      this.initOptions.heartbeatTimeout = 7000

      this.eventSource = new EventSourcePolyfill(this.url, this.initOptions)

      this.eventSource.onopen = () => {
        if (this.status === 'closed') {
          return
        }

        this.status = 'open'
        if (this.initOptions.onOpen) {
          this.initOptions.onOpen()
        }
      }

      this.eventSource.onmessage = event => {
        if (this.status === 'closed') {
          return
        }

        if (this.initOptions.onMessage) {
          this.initOptions.onMessage(JSON.parse(event.data))
        }
      }

      this.eventSource.onerror = error => {
        if (this.status === 'closed') {
          return
        }

        this.status = 'error'
        if (this.initOptions.onError) {
          this.initOptions.onError(error)
        }

        if (error.status === 401 || error.status === 403) {
          $authorization.handleUnauthorized()
        }

        setTimeout(() => {
          if (this.status === 'error') {
            this.start()
          }
        }, 1000)
      }

      if (this.initOptions.eventListeners) {
        Object.keys(this.initOptions.eventListeners).forEach(name => {
          this.eventSource.addEventListener(name, event => {
            if (this.status !== 'closed') {
              this.initOptions.eventListeners[name](JSON.parse(event.data))
            }
          })
        })
      }
    })
  }

  restart () {
    this.close()
    this.start()
  }
}

const $sse = {
  sse (url, initOptions = {}) {
    const sse = new Sse(url, initOptions)
    sse.start()
    return sse
  }
}

export default $sse
