var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-chips',{attrs:{"items":_vm.productCategoryList.productCategories,"item-key":"id","item-text":category => !category.favorites ? category.name : null,"item-icon":category => category.favorites ? 'mdi-heart' : null,"enforce-selection":true,"selection-handler":category => _vm.event.fire('product-category-select', category),"actions":[
        {
          icon: 'mdi-lead-pencil',
          handler: category => _vm.$dialog.openDialog('product-category', category),
          visibleHandler: category => !category.favorites
        }
      ],"drag-data-type":category => !category.favorites ? 'category' : null,"drop-data-types":category => !category.favorites ? ['category', 'product'] : null,"drop-handler":_vm.categoryDropHandler}})
}
var staticRenderFns = []

export { render, staticRenderFns }