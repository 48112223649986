<template>
  <v-dialog :value="show" persistent max-width="600px">
    <v-card v-show="$dialog.isDialogOnTop(id)">
      <v-card-title>
        <span>{{ $t(title) }}</span>
      </v-card-title>
      <div>
        <div class="d-flex justify-center mt-6">
          <v-progress-circular
            v-if="loading === true"
            :size="150"
            :width="2"
            indeterminate
            color="primary"
          ><div class="title">{{ loadingText }}</div></v-progress-circular>
          <v-icon v-else :color="iconColor" size="150">{{ iconName }}</v-icon>
        </div>
        <div
          class="d-flex flex-column text-center mt-6 title"
        >
          {{ message1 }}
        </div>
        <div
          class="d-flex flex-column text-center mt-1 mb-1 subtitle-1"
          :class="message2Color"
        >
          {{ message2 }}
        </div>
      </div>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
            v-if="cancelProcessEnabledHandler"
            @click="cancelProcess"
            color="warning"
            text
            :disabled="cancelProcessDisabled"
        >{{ $t(cancelProcessButtonLabel) }}</v-btn>
        <v-btn text @click="close">{{ $t('label.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialog from '@/lib/mixins/dialog'

export default {
  mixins: [
    dialog
  ],

  props: {
    title: {
      type: String,
      required: true
    },
    processUrl: {
      type: String,
      required: true
    },
    statusListKeepPolling: {
      type: Array,
      default: () => []
    },
    statusMessageMapping: {
      type: Array,
      default: () => []
    },
    message2Handler: {
      type: Function,
      default: () => { return '' }
    },
    message2ColorHandler: {
      type: Function,
      default: () => { return '' }
    },
    loadingTextHandler: {
      type: Function,
      default: () => { return '' }
    },
    cancelProcessEnabledHandler: {
      type: Function
    },
    cancelProcessButtonLabel: {
      type: String,
      default: 'label.cancelProcess'
    },
    closeHandler: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      processFetch: null,
      success: false,
      message1: '',
      message2: '',
      message2Color: '',
      loadingText: '',
      loading: true
    }
  },

  methods: {
    close () {
      this.$dialog.closeDialog(this.id, this.closeHandler)
    },
    onProcessData () {
      const data = this.processFetch.data
      if (data.accepted === true) {
        const mapping = this.statusMessageMapping.find(m => m.status === 'DONE')
        this.message1 = mapping ? this.$t(mapping.key) : ''
      } else {
        if (data.status) {
          const mapping = this.statusMessageMapping.find(m => m.status === data.status)
          this.message1 = mapping ? this.$t(mapping.key) : data.status
        }
      }
      this.message2 = this.message2Handler(data)
      this.message2Color = this.message2ColorHandler(data)
      this.loadingText = this.loadingTextHandler(data)
      if (data.accepted === true || (data.status && !this.statusListKeepPolling.includes(data.status))) {
        if (data.status === 'DONE' || data.accepted === true) {
          this.success = true
        } else {
          this.success = false
        }
        this.loading = false
        this.processFetch.stopPolling()
      }
    },
    onProcessError (error) {
      this.message1 = error.message
      this.message2 = ''
      this.loadingText = ''
      this.success = false
      this.loading = false
      this.processFetch.stopPolling()
    },
    cancelProcess () {
      this.$fetcher.post(`${this.processUrl}/cancel`, { onError: this.onProcessError })
    }
  },
  computed: {
    show () {
      return this.$dialog.isDialogOpen(this.id)
    },
    iconColor () {
      return this.success === true ? 'light-green accent-3' : 'red'
    },
    iconName () {
      return this.success === true
        ? 'mdi-checkbox-marked-circle'
        : 'mdi-close-circle'
    },
    cancelProcessDisabled () {
      const data = this.processFetch ? this.processFetch.data : undefined
      return this.cancelProcessEnabledHandler && !this.cancelProcessEnabledHandler(data)
    }
  },
  watch: {
    show (value) {
      if (value) {
        this.success = false
        this.message1 = ''
        this.message2 = ''
        this.loadingText = ''
        this.loading = true
        this.processFetch = this.$fetcher.fetch(this.processUrl, { pollIntervalTimeout: 500, afterSuccess: this.onProcessData, onError: this.onProcessError })
        this.processFetch.poll()
      } else {
        this.processFetch.stopPolling()
      }
    }
  }
}
</script>

<style scoped>
.placeholder {
  visibility: hidden;
}
</style>
