<template>
  <v-input :error-messages="errorMessages">
    <v-list>
      <v-subheader
          style="padding-left: 0; height: auto"
          :class="{
          'color-grey': disabled
        }">
        <span
            style="font-size: 16px;"
            :class="{
              'color-error': error
            }"
            v-text="$t(label)"
        />
        <t-icon
            v-if="!disabled"
            :click-handler="handleAddItemClick"
            class="ml-1"
            image="mdi-plus-circle"
        />
      </v-subheader>

      <v-list-item-group>
        <v-list-item
            v-for="item in listItems"
            :key="item.key"
            :disabled="inputFields.length === 0 && disabled"
            dense
        >
          <v-list-item-content @click.stop="handleEditItemClick(item.item)">
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <t-edit-dialog
        :id="editDialogId"
        :item="editItem"
        :uuid-item="uuidItem"
        :input-fields="inputFields"
        :save-handler="handleItemSave"
        :delete-handler="handleItemDelete"
        :edit-handler="editHandler"
        :read-only-for-edit="disabled"
        save-new-button-label="label.add"
        save-edit-button-label="label.modify"
    />
  </v-input>
</template>

<script>
import { v4 as uuid } from 'uuid'
import inputField from '../../mixins/inputField'

export default {
  mixins: [
    inputField
  ],

  props: {
    itemText: {
      type: [String, Function]
    },
    itemKey: {
      type: [String, Function]
    },
    uuidItem: {
      type: Boolean
    },
    inputFields: {
      type: Array,
      default: () => []
    },
    editHandler: {
      type: Function
    }
  },

  data () {
    return {
      editDialogId: uuid().toString(),
      editItem: null
    }
  },

  computed: {
    listItems () {
      if (!this.internalValue) {
        return []
      }

      const listItems = this.internalValue.map(item => {
        return {
          item: item,
          key: this.$items.getItemProperty(item, this.itemKey),
          text: this.$items.getItemProperty(item, this.itemText)
        }
      })

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$items.sortItems(listItems, 'text')
      return listItems
    }
  },

  methods: {
    initInternalValue () {
      this.internalValue = this.value || []
      this.editItem = null
    },

    handleAddItemClick () {
      this.editItem = null
      this.$dialog.openDialog(this.editDialogId)
    },

    handleEditItemClick (item) {
      this.editItem = this.$lodash.cloneDeep(item)
      this.$dialog.openDialog(this.editDialogId)
    },

    handleItemSave (item) {
      const itemKey = this.$items.getItemProperty(item, this.itemKey)
      const itemIndex = this.internalValue.findIndex(internalValueItem => {
        const internalValueKey = this.$items.getItemProperty(internalValueItem, this.itemKey)
        return itemKey === internalValueKey
      })

      if (itemIndex !== -1) {
        this.internalValue.splice(itemIndex, 1, item)
      } else {
        this.internalValue.push(item)
      }

      this.emitValue()
      this.$dialog.closeDialog(this.editDialogId)
    },

    handleItemDelete (item) {
      const itemKey = this.$items.getItemProperty(item, this.itemKey)
      const itemIndex = this.internalValue.findIndex(internalValueItem => {
        const internalValueKey = this.$items.getItemProperty(internalValueItem, this.itemKey)
        return itemKey === internalValueKey
      })

      this.internalValue.splice(itemIndex, 1)
      this.emitValue()
    }
  }
}
</script>

<style scoped>

</style>
