<template>
  <t-page>
    <t-content-card title="label.reports">

      <div class="report-input">
        <v-list
          :two-line="true"
          class="mr-4"
        >
          <v-subheader v-text="$t('label.report')"/>
          <v-divider class="mb-1"/>

          <v-list-item-group
            class="report-input-items"
            v-model="selectedReport"
          >
            <v-list-item
              v-for="report in reports"
              :key="report.id"
              :value="report"
            >
              <v-list-item-content>
                <v-list-item-title v-text="$t(report.title)"/>
                <v-list-item-subtitle v-text="$t(report.subtitle)"/>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <div class="flex-grow-1 pt-2">
          <div class="v-subheader theme--dark" v-text="$t('label.period')"/>
          <v-divider/>

          <div class="mt-2" style="display: inline-grid; grid-template-columns: 1fr 1fr;">
            <t-date-field class="px-4" label="label.fromDate" v-model="selectedFromDate"/>
            <t-date-field class="px-4" label="label.toDate" v-model="selectedToDate"/>
            <t-button
              v-for="defaultPeriod in defaultPeriods"
              :key="defaultPeriod.label"
              :label="defaultPeriod.label"
              @click="handleDefaultPeriodClick(defaultPeriod)"
              :selected="isDefaultPeriodSelected(defaultPeriod)"
              align-left tile text
            />
          </div>
        </div>

        <div style="flex-grow: 5"/>
      </div>

      <div>
        <t-button
          ref="requestButton"
          class="mt-4"
          label="label.request"
          @click="handleRequestClick"
          :enabled="!!selectedReport && !!selectedFromDate && !!selectedToDate"
          primary depressed
        />
      </div>
    </t-content-card>

    <t-content-card
      ref="report"
      v-if="report"
      :title="report ? report.title : null"
      :subtitle="$t('parametrized.fromTo', { from:reportFromDate, to:reportToDate })"
      :fetch="reportFetch"
      :actions="[
        {
          icon: 'mdi-printer',
          handler: handleReportPrintClick
        }
      ]"
    >
      <order-report
        v-if="report && report.id === 'orderReport' && reportFetch.isSuccess()"
        :report="reportFetch.data"
      />

      <topup-report
        v-if="report && report.id === 'topupReport' && reportFetch.isSuccess()"
        :report="reportFetch.data"
      />

      <reward-report
        v-if="report && report.id === 'rewardReport' && reportFetch.isSuccess()"
        :report="reportFetch.data"
      />

      <product-sales-per-weekday-report
        v-if="report && report.id === 'productSalesPerWeekdayReport' && reportFetch.isSuccess()"
        :report="reportFetch.data"
      />

      <product-sales-per-month-report
        v-if="report && report.id === 'productSalesPerMonthReport' && reportFetch.isSuccess()"
        :report="reportFetch.data"
      />

      <revenue-per-weekday-report
        v-if="report && report.id === 'revenuePerWeekdayReport' && reportFetch.isSuccess()"
        :report="reportFetch.data"
      />

      <revenue-per-month-report
        v-if="report && report.id === 'revenuePerMonthReport' && reportFetch.isSuccess()"
        :report="reportFetch.data"
      />

      <correction-report
        v-if="report && report.id === 'correctionReport' && reportFetch.isSuccess()"
        :report="reportFetch.data"
      />

      <membership-cancellation-report
        v-if="report && report.id === 'membershipCancellationReport' && reportFetch.isSuccess()"
        :report="reportFetch.data"
      />
    </t-content-card>
  </t-page>
</template>

<script>
import moment from 'moment'
import Printd from 'printd'
import OrderReport from './OrderReport'
import TopupReport from './TopupReport'
import RewardReport from './RewardReport'
import ProductSalesPerWeekdayReport from './ProductSalesPerWeekdayReport'
import ProductSalesPerMonthReport from './ProductSalesPerMonthReport'
import RevenuePerMonthReport from './RevenuePerMonthReport'
import RevenuePerWeekdayReport from './RevenuePerWeekdayReport'
import CorrectionReport from './CorrectionReport'
import MembershipCancellationReport from './MembershipCancellationReport'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'

export default {
  components: {
    CorrectionReport,
    RevenuePerWeekdayReport,
    RevenuePerMonthReport,
    ProductSalesPerMonthReport,
    ProductSalesPerWeekdayReport,
    RewardReport,
    TopupReport,
    OrderReport,
    MembershipCancellationReport
  },

  data () {
    return {
      tenantSelection: useTenantSelection(),
      reports: null,
      defaultPeriods: null,
      selectedReport: null,
      selectedFromDate: null,
      selectedToDate: null,
      report: null,
      reportFromDate: null,
      reportToDate: null,
      reportFetch: null
    }
  },

  watch: {
    selectedFromDate () {
      if (this.selectedFromDate && this.selectedToDate && moment(this.selectedFromDate).isAfter(moment(this.selectedToDate))) {
        this.selectedToDate = this.selectedFromDate
      }
    },

    selectedToDate () {
      if (this.selectedFromDate && this.selectedToDate && moment(this.selectedToDate).isBefore(moment(this.selectedFromDate))) {
        this.selectedFromDate = this.selectedToDate
      }
    }
  },

  created () {
    this.reports = [
      {
        id: 'orderReport',
        title: this.$t('label.orders'),
        subtitle: this.$t('message.orderReport'),
        path: 'order'
      },
      {
        id: 'topupReport',
        title: this.$t('label.topups'),
        subtitle: this.$t('message.topupReport'),
        path: 'topup'
      },
      {
        id: 'rewardReport',
        title: this.$t('label.rewards'),
        subtitle: this.$t('message.rewardReport'),
        path: 'reward'
      },
      {
        id: 'productSalesPerWeekdayReport',
        title: this.$t('label.productSalesPerWeekday'),
        subtitle: this.$t('message.productSalesPerWeekdayReport'),
        path: 'product-sales-per-weekday'
      },
      {
        id: 'productSalesPerMonthReport',
        title: this.$t('label.productSalesPerMonth'),
        subtitle: this.$t('message.productSalesPerMonthReport'),
        path: 'product-sales-per-month'
      },
      {
        id: 'revenuePerWeekdayReport',
        title: this.$t('label.revenuePerWeekday'),
        subtitle: this.$t('message.revenuePerWeekdayReport'),
        path: 'revenue-per-weekday'
      },
      {
        id: 'revenuePerMonthReport',
        title: this.$t('label.revenuePerMonth'),
        subtitle: this.$t('message.revenuePerMonthReport'),
        path: 'revenue-per-month'
      },
      {
        id: 'correctionReport',
        title: this.$t('label.corrections'),
        subtitle: this.$t('message.correctionReport'),
        path: 'correction'
      },
      {
        id: 'membershipCancellationReport',
        title: this.$t('label.membershipCancellations'),
        subtitle: this.$t('message.membershipCancellationReport'),
        path: 'membership-cancellation'
      }
    ]
    this.$items.sortItems(this.reports, 'title')

    this.defaultPeriods = [
      {
        label: 'label.previousYear',
        unit: 'years',
        unitSubtraction: 1,
        unitOfTime: 'year'
      },
      {
        label: 'label.currentYear',
        unit: 'years',
        unitSubtraction: 0,
        unitOfTime: 'year'
      },
      {
        label: 'label.previousQuarter',
        unit: 'quarters',
        unitSubtraction: 1,
        unitOfTime: 'quarter'
      },
      {
        label: 'label.currentQuarter',
        unit: 'quarters',
        unitSubtraction: 0,
        unitOfTime: 'quarter'
      },
      {
        label: 'label.previousMonth',
        unit: 'months',
        unitSubtraction: 1,
        unitOfTime: 'month'
      },
      {
        label: 'label.currentMonth',
        unit: 'months',
        unitSubtraction: 0,
        unitOfTime: 'month'
      },
      {
        label: 'label.previousWeek',
        unit: 'isoWeeks',
        unitSubtraction: 1,
        unitOfTime: 'isoWeek'
      },
      {
        label: 'label.currentWeek',
        unit: 'isoWeeks',
        unitSubtraction: 0,
        unitOfTime: 'isoWeek'
      },
      {
        label: 'label.yesterday',
        unit: 'days',
        unitSubtraction: 1,
        unitOfTime: 'day'
      },
      {
        label: 'label.today',
        unit: 'days',
        unitSubtraction: 0,
        unitOfTime: 'day'
      }
    ]
  },

  methods: {
    handleRequestClick () {
      this.report = this.selectedReport
      this.reportFromDate = this.selectedFromDate
      this.reportToDate = this.selectedToDate

      let reportUrl = `${process.env.VUE_APP_TOPUPZ_URL}/tenants/${this.tenantSelection.tenantId}/reports/${this.selectedReport.path}`
      reportUrl += `?fromDateTime=${moment(this.selectedFromDate).startOf('day').toISOString()}`
      reportUrl += `&toDateTime=${moment(this.selectedToDate).endOf('day').toISOString()}`
      reportUrl += `&timezone=${this.$authorization.user.timezone}`

      this.reportFetch = this.$fetcher.fetch(reportUrl)

      const requestButtonMargin = window.innerHeight - this.$refs.requestButton.$el.getBoundingClientRect().bottom
      if (requestButtonMargin < 160) {
        setTimeout(() => {
          window.scrollBy({
            top: 160 - requestButtonMargin,
            behavior: 'smooth'
          })
        }, 0)
      }
    },

    handleDefaultPeriodClick (defaultPeriod) {
      this.selectedFromDate = moment().subtract(defaultPeriod.unitSubtraction, defaultPeriod.unit).startOf(defaultPeriod.unitOfTime).format('YYYY-MM-DD')
      this.selectedToDate = moment().subtract(defaultPeriod.unitSubtraction, defaultPeriod.unit).endOf(defaultPeriod.unitOfTime).format('YYYY-MM-DD')
    },

    isDefaultPeriodSelected (defaultPeriod) {
      const defaultPeriodFromDate = moment().subtract(defaultPeriod.unitSubtraction, defaultPeriod.unit).startOf(defaultPeriod.unitOfTime).format('YYYY-MM-DD')
      const defaultPeriodToDate = moment().subtract(defaultPeriod.unitSubtraction, defaultPeriod.unit).endOf(defaultPeriod.unitOfTime).format('YYYY-MM-DD')
      return this.selectedFromDate === defaultPeriodFromDate && this.selectedToDate === defaultPeriodToDate
    },

    handleReportPrintClick () {
      const printer = new Printd()
      printer.print(this.$refs.report.$el, [`
        table {
          border-collapse:collapse;
          width:100%;
          margin-top: 32px;
        }
        table * {
          text-align: left !important;
        }
        tbody tr {
          border-top: 1px solid black;
        }
        tbody tr:first-of-type {
          border-top: none;
        }
        thead {
          border-bottom: 2px solid black;
        }
        button {
          display: none !important;
        }
        i {
          display: none !important;
        }
        .v-card__title {
          margin-top: 2rem;
        }
        .v-card__title span {
          font-weight: bold;
          font-size: 1.5rem;
        }
      `])
    }
  }
}
</script>

<style>
  .report-input {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .report-input-items {
    max-height: 15rem;
    overflow-y: auto;
    color: var(--color-primary);
  }

  .report-input-items .v-list-item__subtitle {
    overflow-wrap: break-word;
    white-space: normal;
  }

  @media (min-width: 1024px) {
    .report-input {
      flex-direction: row;
    }
  }

  @media (min-height: 1024px) {
    .report-input-items {
      max-height: 20rem;
    }
  }

  @media (min-height: 1152px) {
    .report-input-items {
      max-height: 30rem;
    }
  }
</style>
