<template>
  <div>
    <t-table
      :item-properties="[
          {
            key: 'sum'
          },
          {
            key: 'amount',
            type: 'amount'
          },
          {
            key: 'numberOfOrders',
            type: 'number'
          },
          {
            key: 'numberOfProducts',
            type: 'number'
          }
        ]"
      :items="[
          {
            sum: $t('label.cashPayment'),
            amount: report.cashPaymentSummary.amount,
            numberOfOrders: report.cashPaymentSummary.numberOfOrders,
            numberOfProducts: report.cashPaymentSummary.numberOfProducts
          },
          {
            sum: $t('label.topupzPayment'),
            amount: report.topupzPaymentSummary.amount,
            numberOfOrders: report.topupzPaymentSummary.numberOfOrders,
            numberOfProducts: report.topupzPaymentSummary.numberOfProducts
          },
          {
            sum: $t('label.bancontactPayment'),
            amount: report.bancontactPaymentSummary.amount,
            numberOfOrders: report.bancontactPaymentSummary.numberOfOrders,
            numberOfProducts: report.bancontactPaymentSummary.numberOfProducts
          },
          {
            sum: $t('label.total'),
            amount: report.totalSummary.amount,
            numberOfOrders: report.totalSummary.numberOfOrders,
            numberOfProducts: report.totalSummary.numberOfProducts
          }
        ]"
    />

    <t-table
      :default-sort-ascending="false"
      :item-properties="[
          {
            key: 'productName',
            header: 'label.product',
            sortable: true
          },
          {
            key: 'count',
            type: 'number',
            sortable: true
          },
          {
            key: 'totalAmount',
            type: 'amount',
            sortable: true
          }
        ]"
      :items="report.products"
      class="mt-8"
      default-sort-property-key="totalAmount"
      item-key="productName"
    />
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Object
    }
  }
}
</script>
