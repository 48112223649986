import $formatter from '@/lib/formatter'
import useEntity from '@/lib/composables/useEntity'

export default function () {
  const orderTemplate = {
    loader: null,
    id: null,
    dateTime: null,
    totalAmount: null,
    paymentMethod: null,
    numberOfProducts: null,
    items: []
  }

  return useEntity(orderTemplate, order => {
    order.items.forEach(item => {
      item.description = `${item.quantity} X ${item.productName} (${$formatter.formatAmount(item.unitPrice)})`
    })
  })
}
