import Vue from 'vue'

import TDesktopApp from './components/app/TDesktopApp'
import TWebApp from './components/app/TWebApp'
import TLineChart from './components/chart/TLineChart'
import TButton from './components/control/TButton'
import TChips from './components/control/TChips'
import TIcon from './components/control/TIcon'
import TConfirmDialog from './components/dialog/TConfirmDialog'
import TDialog from './components/dialog/TDialog'
import TEditDialog from './components/dialog/TEditDialog'
import TMessageDialog from './components/dialog/TMessageDialog'
import TProcessDialog from './components/dialog/TProcessDialog'
import TProcessStatusDialog from './components/dialog/TProcessStatusDialog'
import TScanQRDialog from './components/dialog/TScanQRDialog'
import TSelectionDialog from './components/dialog/TSelectionDialog'
import TAmountField from './components/form/TAmountField'
import TAutoCompleteSelect from './components/form/TAutoCompleteSelect'
import TAutoCompleteListField from './components/form/TAutoCompleteListField'
import TAutoCompleteTextField from './components/form/TAutoCompleteTextField'
import TButtonSelectionField from './components/form/TButtonSelectionField'
import TCheckboxesField from './components/form/TCheckboxesField'
import TDateField from './components/form/TDateField'
import TDateTimeField from './components/form/TDateTimeField'
import TForm from './components/form/TForm'
import TListField from './components/form/TListField'
import TListSelectField from './components/form/TListSelectField'
import TLogoChoiceField from './components/form/TLogoChoiceField'
import TNumberField from './components/form/TNumberField'
import TSelectField from './components/form/TSelectField'
import TSwitchField from './components/form/TSwitchField'
import TTextField from './components/form/TTextField'
import TUuidField from './components/form/TUuidField'
import TContentCard from './components/layout/TContentCard'
import TPage from './components/layout/TPage'
import TLogo from './components/logo/TLogo'
import TMessage from './components/notification/TMessage'
import TNotificationStack from './components/notification/TNotificationStack'
import TTable from './components/table/TTable'

const $components = {
  TDesktopApp,
  TWebApp,
  TLineChart,
  TButton,
  TChips,
  TIcon,
  TConfirmDialog,
  TDialog,
  TEditDialog,
  TMessageDialog,
  TProcessDialog,
  TProcessStatusDialog,
  TScanQRDialog,
  TSelectionDialog,
  TAmountField,
  TAutoCompleteSelect,
  TAutoCompleteListField,
  TAutoCompleteTextField,
  TButtonSelectionField,
  TCheckboxesField,
  TDateField,
  TDateTimeField,
  TForm,
  TListField,
  TListSelectField,
  TLogoChoiceField,
  TNumberField,
  TSelectField,
  TSwitchField,
  TTextField,
  TUuidField,
  TContentCard,
  TPage,
  TLogo,
  TMessage,
  TNotificationStack,
  TTable
}

Object.keys($components).forEach(name => {
  Vue.component(name, $components[name])
})

export default $components
