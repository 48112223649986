import $logger from './logger'
import $authorization from './authorization'
import $notification from './notification'
import Vue from 'vue'

export class Fetch {
  url = null
  initOptions = null
  status = null
  data = null
  pollInterval = null
  promise = null
  promiseResolve = null
  fetching = null

  constructor (url, initOptions = {}) {
    this.url = encodeURI(url)
    this.initOptions = initOptions
    this.status = 'pending'
    this.data = null
    this.pollInterval = null
    this.promise = null
    this.promiseResolve = null
    this.fetching = false
  }

  isPending () {
    return this.status === 'pending'
  }

  isSuccess () {
    return this.status === 'success'
  }

  isError () {
    return this.status === 'error'
  }

  hasPagedData () {
    return !!this.data && this.data.page != null && this.data.pageCount != null && Array.isArray(this.data.data)
  }

  hasArrayData () {
    return Array.isArray(this.data) || this.hasPagedData()
  }

  getArrayData () {
    if (Array.isArray(this.data)) {
      return this.data
    }
    if (this.hasPagedData()) {
      return this.data.data
    }
  }

  fetch () {
    this.promise = new Promise(resolve => {
      this.promiseResolve = resolve
    })

    this.fetching = true
    this.executeFetch().then(success => {
      this.fetching = false
      this.status = success ? 'success' : 'error'
      this.promiseResolve(success)
    }).catch(() => {
      this.fetching = false
      this.status = 'error'
      this.promiseResolve(false)
    })

    return fetch
  }

  stopPolling () {
    if (this.pollInterval) {
      clearTimeout(this.pollInterval)
      this.pollInterval = null
    }
  }

  poll () {
    if (!this.pollInterval) {
      const pollIntervalTimeout = this.initOptions.pollIntervalTimeout ? this.initOptions.pollIntervalTimeout : 5000
      this.pollInterval = setInterval(() => this.executeFetch(), pollIntervalTimeout)
    }
  }

  async executeFetch () {
    if (!this.initOptions.headers) {
      this.initOptions.headers = {}
    }

    const authorizationHeader = await $authorization.getAuthorizationHeader()
    if (authorizationHeader && !this.url.includes('/public/')) {
      this.initOptions.headers.Authorization = authorizationHeader
    }

    if (this.initOptions.formData || this.initOptions.body instanceof FormData) {
      delete this.initOptions.headers['Content-Type']
    } else {
      if (!this.initOptions.headers['Content-Type']) {
        this.initOptions.headers['Content-Type'] = 'application/json'
      }

      if (this.initOptions.body && typeof this.initOptions.body === 'object') {
        this.initOptions.body = JSON.stringify(this.initOptions.body)
      }
    }

    $logger.debug(`fetching from url ${this.url}`)

    let response
    try {
      response = await window.fetch(this.url, this.initOptions)
      if (!response.ok) {
        if (this.initOptions.errorMessages && this.initOptions.errorMessages[response.status]) {
          throw new Error(this.initOptions.errorMessages[response.status])
        } else if (response.headers.get('x-exception-code')) {
          throw new Error(response.headers.get('x-exception-code'))
        } else {
          throw new Error(response.statusText)
        }
      }

      let data
      if (this.initOptions.bodyType === 'file') {
        this.initOptions.filename = response.headers.get('Content-Disposition').split('filename=')[1].trim()
        data = await response.blob()
        downloadBlob(data, this.initOptions.filename)
      } else if (this.initOptions.bodyType === 'json') {
        data = await response.json()
      } else if (this.initOptions.bodyType === 'text') {
        data = await response.text()
      } else if (this.initOptions.bodyType === 'blob') {
        data = await response.blob()
      } else {
        data = await response.json()
      }

      this.data = data
      if (data) {
        $logger.debug(JSON.stringify(data))
      }

      if (this.initOptions.afterSuccess) {
        this.initOptions.afterSuccess(this.data)
      }

      return true
    } catch (error) {
      $logger.error(error)

      if (response && response.status === 401) {
        $authorization.handleUnauthorized()
      }

      if (this.initOptions.onError) {
        this.initOptions.onError(error.message)
      } else {
        $notification.error(error.message)
      }

      return false
    }
  }
}

const $fetcher = {
  fetch (url, initOptions = {}) {
    const fetch = Vue.observable(new Fetch(url, initOptions))

    fetch.fetch()
    if (initOptions.poll === true) {
      fetch.poll()
    }

    return fetch
  },

  post (url, init = {}) {
    init.method = 'POST'
    init.bodyType = init.bodyType ? init.bodyType : 'text'
    return $fetcher.fetch(url, init)
  },

  put (url, init = {}) {
    init.method = 'PUT'
    init.bodyType = init.bodyType ? init.bodyType : 'text'
    return $fetcher.fetch(url, init)
  },

  delete (url, init = {}) {
    init.method = 'DELETE'
    init.bodyType = init.bodyType ? init.bodyType : 'text'
    return $fetcher.fetch(url, init)
  },

  poll (url, init = {}) {
    init.poll = true
    return $fetcher.fetch(url, init)
  }
}

function downloadBlob (blob, filename) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename || 'download'

  // Click handler that releases the object URL after the element has been clicked
  // This is required for one-off downloads of the blob content
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url)
      window.removeEventListener('click', clickHandler)
    }, 150)
  }
  a.addEventListener('click', clickHandler, false)
  a.click()
  return a
}

export default $fetcher
