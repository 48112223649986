import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useLoader from '@/lib/composables/useLoader'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import $authorization from '@/lib/authorization'

const tenantSelection = useTenantSelection()

const dashboard = Vue.observable({
  loader: useLoader(),
  fetch: null,
  runningOrder: {
    total: null,
    active: null,
    toPrepare: null,
    toServe: null
  },
  order: {
    today: null,
    thisWeek: null,
    thisMonth: null,
    thisYear: null
  },
  topup: {
    today: null,
    thisWeek: null,
    thisMonth: null,
    thisYear: null
  },
  device: {
    devices: null,
    scanners: null,
    cashiers: null,
    onlineDevices: null,
    onlineScanners: null,
    onlineCashiers: null
  },
  invoice: {
    toApprove: null,
    toPay: null
  },
  user: {
    users: null,
    disabled: null,
    unverified: null
  },
  card: {
    cards: null,
    unassigned: null
  }
})

dashboard.load = async () => {
  await dashboard.loader.load(async background => {
    if (background) {
      await dashboard.fetch.executeFetch()
    } else {
      dashboard.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/dashboard?timezone=${$authorization.user.timezone}`)
    }
    if (await dashboard.fetch.promise) {
      dashboard.setData(dashboard.fetch.data)
    }
  })
}

dashboard.setData = data => {
  Object.assign(dashboard, data)
}

export default function () {
  dashboard.load()
  return dashboard
}
