var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"qr-code","input-fields":[
        {
          label: 'label.id',
          key: 'formattedId',
          type: 'text',
          readOnly: true
        },
        {
          key: 'name',
          type: 'text',
        },
        {
          key: 'type',
          type: 'select',
          items: _vm.qrCodeType.items,
          itemKey: 'key',
          itemText: 'label',
          changeHandler: _vm.handleTypeChange
        },
        {
          label: 'label.cashier',
          key: 'cashierId',
          type: 'auto-complete-select',
          items: _vm.cashierList.items,
          itemKey: 'id',
          itemText: 'name',
          visible: _vm.cashierVisible
        }
      ],"item":_vm.qrCode,"save-handler":_vm.qrCode.save}})
}
var staticRenderFns = []

export { render, staticRenderFns }