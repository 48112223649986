<template>
  <t-form
    ref="form"
    :form-data="settings.invoicingSettings"
    :has-changes-handler="hasChanges => $emit('hasChanges', hasChanges)"
    :input-fields="[
          {
            key: 'companyName',
            type: 'text'
          },
          {
            key: 'vatNumber',
            type: 'text'
          },
          {
            key: 'street',
            type: 'text'
          },
          {
            key: 'houseNumber',
            type: 'text'
          },
          {
            key: 'postalCode',
            type: 'text'
          },
          {
            key: 'municipality',
            type: 'text'
          }
        ]"
    :validation-handler="handleInvoicingSettingsValidation"
  />
</template>

<script>
import useSubscription from '@/app/composables/subscription/useSubscription'

export default {
  data () {
    return {
      subscription: useSubscription()
    }
  },

  props: {
    settings: {
      required: true
    }
  },

  computed: {
    editFormData () {
      return this.$refs.form && this.$refs.form.editFormData
    }
  },

  methods: {
    validate () {
      return this.$refs.form.validate()
    },

    reset () {
      return this.$refs.form.reset()
    },

    async handleInvoicingSettingsValidation (invoiceSettings) {
      await this.subscription.load()

      const empty = !invoiceSettings.companyName &&
        !invoiceSettings.vatNumber &&
        !invoiceSettings.street &&
        !invoiceSettings.houseNumber &&
        !invoiceSettings.postalCode &&
        !invoiceSettings.municipality

      const checkCompleted = this.subscription.plan !== 'FREE' || !empty
      if (checkCompleted) {
        return {
          companyName: !invoiceSettings.companyName ? 'validation.fieldRequired' : true,
          street: !invoiceSettings.street ? 'validation.fieldRequired' : true,
          houseNumber: !invoiceSettings.houseNumber ? 'validation.fieldRequired' : true,
          postalCode: !invoiceSettings.postalCode ? 'validation.fieldRequired' : true,
          municipality: !invoiceSettings.municipality ? 'validation.fieldRequired' : true
        }
      }
    }
  }
}
</script>
