import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useLoader from '@/lib/composables/useLoader'

const tenantSelection = useTenantSelection()

export default function () {
  const subscriptionPeriodList = Vue.observable({
    loader: useLoader(),
    fetch: null,
    subscriptionPeriods: []
  })

  subscriptionPeriodList.setData = data => {
    subscriptionPeriodList.subscriptionPeriods = data
  }

  subscriptionPeriodList.load = async () => {
    await subscriptionPeriodList.loader.load(async background => {
      if (background) {
        await subscriptionPeriodList.fetch.executeFetch()
      } else {
        subscriptionPeriodList.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/subscription/periods`)
        await subscriptionPeriodList.fetch.promise
      }
      subscriptionPeriodList.setData(subscriptionPeriodList.fetch.data)
    })
  }

  return subscriptionPeriodList
}
