import Vue from 'vue'
import { v4 as uuid } from 'uuid'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import $fetcher from '@/lib/fetcher'

const tenantSelection = useTenantSelection()

const deviceLink = Vue.observable({
  statusMessageMapping: [
    {
      status: 'DEVICE_UNKNOWN',
      key: 'message.unknownDevice'
    },
    {
      status: 'DEVICE_ALREADY_LINKED',
      key: 'message.deviceLinkedToOtherTenant'
    },
    {
      status: 'DEVICE_GONE',
      key: 'message.deviceNotAdoptable'
    },
    {
      status: 'DEVICE_BUSY',
      key: 'message.deviceBusy'
    },
    {
      status: 'CONFIRM_LINKING_TIMEOUT',
      key: 'message.deviceLinkingTimeout'
    },
    {
      status: 'LINKING_DENIED',
      key: 'message.deviceLinkingDenied'
    },
    {
      status: 'WAITING_FOR_CONFIRM_LINKING',
      key: 'message.deviceLinkingWaiting'
    },
    {
      status: 'DONE',
      key: 'message.deviceLinkingDone'
    }
  ],
  statusListKeepPolling: ['WAITING_FOR_CONFIRM_LINKING'],
  processInstanceId: null,
  processUrl: null,
  device: null
})

deviceLink.startProcess = async device => {
  deviceLink.device = device
  deviceLink.processInstanceId = uuid()
  deviceLink.processUrl = `${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/devices/${device.serial}/processes/link-device/instances/${deviceLink.processInstanceId}`

  const fetch = $fetcher.put(deviceLink.processUrl, {
    body: {
      name: device.name,
      pincode: device.pincode,
      tenantShortName: device.tenantShortName,
      locale: device.locale
    }
  })

  await fetch.promise
}

export default function () {
  return deviceLink
}
