<template>
  <v-input
      :error-messages="errorMessages"
      :value="internalValue"
      :disabled="disabled"
  >
    <div
        class="mt-2 d-inline-flex row align-center"
        style="margin-left: 0px"
        @mouseover="hover = true"
        @mouseleave="hover = false"
    >

      <div class="color-grey-lighten-1">{{ $t(label) }}</div>

      <div
          class="fill-height background-color-grey mx-2"
          style="height: 64px; width: 1px"
          :class="{ 'background-color-grey-lighten-1': hover }"/>

      <t-logo
          v-for="containerItem in containerItems"
          :key="containerItem.key"
          :image="containerItem.value"
          :selectable="true"
          :selected="internalValue === containerItem.value"
          @click="handleContainerItemClick(containerItem)"
          class="ms-1"
          :class="{ 'cursor-pointer': hover }"
          :disabled="disabled"
      />

      <t-logo
          v-if="addedLogo"
          :image="addedLogo"
          :selectable="true"
          :selected="internalValue === addedLogo"
          @click="handleAddedLogoClick"
          class="ms-1"
          :class="{ 'cursor-pointer': hover }"
          :disabled="disabled"
      />

      <v-btn icon x-large class="ml-2" @click="handleAddButtonClick">
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>

      <input ref="fileInput" style="display: none" type="file" @change="handleFileSelected">

    </div>
  </v-input>
</template>

<script>
import inputField from '../../mixins/inputField'
import itemContainer from '../../mixins/itemContainer'

export default {
  mixins: [
    inputField,
    itemContainer
  ],

  data () {
    return {
      hover: false,
      addedLogo: null
    }
  },

  methods: {
    resetValidation () {
      this.errorMessages = []
      this.addedLogo = null
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = null
      }
    },

    handleContainerItemClick (containerItem) {
      this.internalValue = containerItem.value
      this.emitValue()
    },

    handleAddButtonClick () {
      this.$refs.fileInput.click()
    },

    handleFileSelected () {
      if (this.$refs.fileInput.files && this.$refs.fileInput.files.length) {
        const file = this.$refs.fileInput.files[0]

        const reader = new FileReader()
        reader.onload = () => {
          this.$canvas.createLogo(reader.result).then(logo => {
            this.addedLogo = logo
            this.handleAddedLogoClick()
          })
        }
        reader.readAsDataURL(file)
      }
    },

    handleAddedLogoClick () {
      this.internalValue = this.addedLogo
      this.emitValue()
    }
  }
}
</script>
