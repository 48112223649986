<template>
  <t-edit-dialog
    id="product-category"
    :item="productCategory"
    :uuid-item="true"
    :save-handler="productCategory.save"
    :delete-handler="productCategory.delete"
    :input-fields="[
        {
          key: 'name',
          type: 'text',
          label: 'label.name',
          required: true
        }
      ]"
    :open-handler="handleOnOpen"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useProductCategory from '@/app/composables/product/useProductCategory'

export default {
  data () {
    return {
      productCategory: useProductCategory()
    }
  },

  created () {
    useEvent().registerListener('product-category-dialog', ['product-category-save', 'product-category-delete'], () => {
      this.$dialog.closeDialog('product-category')
    })
  },

  destroyed () {
    useEvent().unregisterListener('product-category-dialog')
  },

  methods: {
    handleOnOpen () {
      this.productCategory.setData(this.$dialog.getDialogData('product-category'))
    }
  }
}
</script>
