var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"device","delete-handler":_vm.device.delete,"input-fields":[
        {
          key: 'name',
          type: 'text',
          label: 'label.name',
          required: true
        },
        {
          key: 'type',
          type: 'select',
          label: 'label.type',
          items: [
            {
              type: 'SCANNER',
              typeLabel: _vm.$t('label.scanner')
            },
            {
              type: 'CASHIER',
              typeLabel: _vm.$t('label.cashier')
            }
          ],
          itemValue: 'type',
          itemText: 'typeLabel',
          readOnly: true
        },
        {
          key: 'model',
          type: 'text',
          label: 'label.model',
          readOnly: true
        },
        {
          key: 'applicationVersion',
          type: 'text',
          label: 'label.applicationVersion',
          readOnly: true
        },
        {
          key: 'pincode',
          type: 'text',
          label: 'label.pincode',
          required: true,
          numeric: true
        },
        {
          key: 'tenantShortName',
          type: 'text',
          label: 'label.shortenedClubName',
          required: true
        },
        {
          key: 'locale',
          type: 'select',
          label: 'label.locale',
          items: [
            {
              name: _vm.$t('label.dutch'),
              locale: 'nl'
            },
            {
              name: _vm.$t('label.french'),
              locale: 'fr'
            },
            {
              name: _vm.$t('label.english'),
              locale: 'en'
            }
          ],
          itemValue: 'locale',
          itemText: 'name',
          required: true
        },
        {
          key: 'paymentQrCodeId',
          type: 'auto-complete-select',
          label: 'label.paymentQrCode',
          items: _vm.paymentQrCodeList.items,
          itemKey: 'id',
          itemText: 'description',
          visible: () => _vm.device.type === 'CASHIER'
        }
      ],"item":_vm.device,"open-handler":_vm.onOpen,"save-handler":_vm.device.save,"uuid-item":true}})
}
var staticRenderFns = []

export { render, staticRenderFns }