<template>
  <t-page>
    <t-content-card :fetch="cardSearch.fetch" title="label.cards">
      <card-table :card-search="cardSearch" />
    </t-content-card>
  </t-page>
</template>

<script>
import useCardSearch from '@/app/composables/card/useCardSearch'
import useEvent from '@/lib/composables/useEvent'
import useRefresh from '@/lib/composables/useRefresh'

export default {
  data () {
    return {
      cardSearch: useCardSearch()
    }
  },

  created () {
    useEvent().registerListener('card-page', 'card-save', () => {
      this.cardSearch.loader.refresh()
    })
    useRefresh().registerListener('card-page', () => {
      this.cardSearch.loader.backgroundRefresh()
    })
  },

  destroyed () {
    useEvent().unregisterListener('card-page')
    useRefresh().unregisterListener('card-page')
  }
}
</script>
