<template>
  <t-page>
    <t-content-card
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => $dialog.openDialog('user-membership-request')
        }
      ]"
      :collapsible="true"
      :default-collapsed="true"
      :fetch="membershipRequestList.fetch"
      title="label.membershipRequests"
    >
      <user-membership-request-table :membership-request-list="membershipRequestList" />
    </t-content-card>

    <t-content-card
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => $dialog.openDialog('user-group')
        }
      ]"
      :collapsible="true"
      :default-collapsed="true"
      :fetch="userGroupList.fetch"
      title="label.userGroups"
    >
      <user-group-table :user-group-list="userGroupList" />
    </t-content-card>

    <t-content-card :fetch="userSearch.fetch" title="label.users">
      <user-table :user-search="userSearch"/>
    </t-content-card>

  </t-page>
</template>

<script>
import useUserSearch from '@/app/composables/user/useUserSearch'
import useUserGroupList from '@/app/composables/user/useUserGroupList'
import useEvent from '@/lib/composables/useEvent'
import useRefresh from '@/lib/composables/useRefresh'
import useMembershipRequestList from '@/app/composables/membership/useMembershipRequestList'

export default {
  data () {
    return {
      userSearch: useUserSearch(),
      userGroupList: useUserGroupList(),
      membershipRequestList: useMembershipRequestList()
    }
  },

  created () {
    this.userGroupList.load()
    this.membershipRequestList.load()

    useEvent().registerListener('user-page', ['user-save', 'user-group-save', 'user-group-delete', 'user-membership-cancel'], () => {
      this.userSearch.loader.refresh()
      this.userGroupList.loader.refresh()
    })

    useEvent().registerListener('user-page', ['membership-request-save', 'membership-request-delete'], () => {
      this.membershipRequestList.loader.refresh()
    })

    useRefresh().registerListener('user-page', () => {
      this.userSearch.loader.backgroundRefresh()
      this.userGroupList.loader.backgroundRefresh()
      this.membershipRequestList.loader.backgroundRefresh()
    })
  },

  destroyed () {
    useEvent().unregisterListener('user-page')
    useRefresh().unregisterListener('user-page')
  }
}
</script>
