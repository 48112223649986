import useQrCode from '@/app/composables/qrcode/useQrCode'
import useList from '@/lib/composables/useList'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'

const tenantSelection = useTenantSelection()

export default function (filter) {
  return useList(() => `${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/qr-codes?${filter}`, data => {
    const qrCode = useQrCode()
    qrCode.setData(data)
    return qrCode
  })
}
