import $fetcher from '@/lib/fetcher'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useEntity from '@/lib/composables/useEntity'
import useEvent from '@/lib/composables/useEvent'

const tenantSelection = useTenantSelection()

export default function () {
  const tenantSettingsTemplate = {
    id: null,
    tenantId: null,
    generalSettings: {
      language: null
    },
    invoicingSettings: {
      companyName: null,
      vatNumber: null,
      street: null,
      houseNumber: null,
      postalCode: null,
      municipality: null,
      completed: null
    },
    onlinePaymentSettings: {
      mollieApiKey: null,
      allowedForOrders: null,
      allowedForTopups: null,
      minimumOrderAmount: null,
      minimumTopupAmount: null
    },
    onlineOrderSettings: {
      enabled: null
    }
  }

  const tenantSettings = useEntity(tenantSettingsTemplate)

  tenantSettings.find = async () => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/settings`)
    await fetch.promise
    tenantSettings.setData(fetch.data)
  }

  tenantSettings.save = async editTenantSettings => {
    const saveFetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/settings`, {
      body: editTenantSettings
    })
    if (await saveFetch.promise) {
      useEvent().fire('tenant-settings-save')
    }
  }

  return tenantSettings
}
