<template>
  <div class="t-date-field">
    <v-menu
        class="t-date-field"
        :close-on-content-click="false"
        :nudge-right="40"
        min-width="290px"
        offset-y
        transition="scale-transition"
        v-model="dateMenu"
        :disabled="disabled"
    >
      <template class="t-date-field" v-slot:activator="{ on }">
        <v-text-field
            :label="$t(label)"
            :value="dateTimeValue"
            readonly
            ref="dateField"
            v-bind="$attrs"
            v-on="on"
            :disabled="disabled"
        />
      </template>

      <v-date-picker
          v-if="showDatePicker"
          class="t-date-field"
          :max="maxDate"
          :min="minDate"
          :value="dateValue"
          @change="onDateChange"
          ref="datePicker"
          :disabled="disabled"
          :locale="$authorization.user.locale"
          :first-day-of-week="1"
      />

      <v-time-picker
          v-if="showTimePicker"
          :value="timeValue"
          @change="onTimeChange"
          :disabled="disabled"
          :use-seconds="true"
          format="24hr"
      />
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import $authorization from '../../authorization'
import inputField from '../../mixins/inputField'

export default {
  mixins: [
    inputField
  ],

  props: {
    minDate: {
      type: String,
      default: '2000-01-01'
    },
    maxDate: {
      type: String,
      default: moment().endOf('year').format('YYYY-MM-DD')
    },
    picker: {
      type: String,
      default: 'DATE'
    }
  },

  data () {
    return {
      dateMenu: false,
      showDatePicker: false,
      showTimePicker: false
    }
  },

  computed: {
    dateValue () {
      if (this.internalValue) {
        return this.$formatter.formatDate(this.internalValue)
      }
      return null
    },

    timeValue () {
      if (this.internalValue) {
        return this.$formatter.formatTime(this.internalValue)
      }
      return null
    },

    dateTimeValue () {
      if (this.internalValue) {
        return this.$formatter.formatDateTime(this.internalValue)
      }
      return null
    }
  },

  watch: {
    dateMenu (dateMenu) {
      dateMenu && setTimeout(() => (this.$refs.datePicker.activePicker = this.picker))
      if (dateMenu) {
        this.showDatePicker = true
        this.showTimePicker = false
      }
    }
  },

  methods: {
    onDateChange (date) {
      let newValue
      if (this.internalValue) {
        const time = moment.utc(this.internalValue).tz($authorization.user.timezone).format('HH:mm:ss')
        newValue = `${date} ${time}`
      } else {
        newValue = `${date} 00:00:00`
      }

      this.internalValue = moment(newValue).tz($authorization.user.timezone).utc().format('YYYY-MM-DD HH:mm:ss')
      this.emitValue()

      this.showDatePicker = false
      this.showTimePicker = true
    },

    onTimeChange (time) {
      const date = moment.utc(this.internalValue).tz($authorization.user.timezone).format('YYYY-MM-DD')
      this.internalValue = moment(`${date} ${time}`).tz($authorization.user.timezone).utc().format('YYYY-MM-DD HH:mm:ss')
      this.emitValue()

      this.showDatePicker = false
      this.showTimePicker = false
      this.dateMenu = false
    }
  }
}
</script>

<style>
  .t-date-field .v-date-picker-table .v-btn.v-btn--active {
    color: #212121;
    font-weight: bold;
  }

  .t-date-field .v-picker__title {
    background: #212121;
  }
</style>
