var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-table',{attrs:{"item":_vm.qrCodeList.items,"item-properties":[
        {
          header: 'label.id',
          key: 'formattedId',
          sortable: true
        },
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'type',
          value: qrCode => _vm.qrCodeType.getLabel(qrCode.type),
          sortable: true
        },
        {
          header: 'label.cashier',
          key: 'cashierName',
          sortable: true
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: card => _vm.event.fire('qr-code-edit', card)
        }
      ],"items":_vm.qrCodeList.items,"search":true,"item-key":"id","no-items-label":"message.noDataFound"}})
}
var staticRenderFns = []

export { render, staticRenderFns }