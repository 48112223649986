import Vue from 'vue'
import * as Keycloak from 'keycloak-js'
import $logger from './logger'
import moment from 'moment'
import $i18n from './i18n'

const $authorization = Vue.observable({
  keycloak: null,
  accessToken: null,
  keycloakUrl: null,
  realm: null,
  keycloakClientId: null,
  keycloakClientSecret: null,
  keycloakAuthorizationHandler: null,
  technicalUser: null,
  technicalPassword: null,

  user: {
    authenticated: false,
    authorized: false,
    attributes: null,
    roles: null,
    userId: null,
    firstName: null,
    lastName: null,
    name: null,
    initials: null,
    locale: null
  },

  async authenticateUser () {
    const options = {
      url: $authorization.keycloakUrl + '/auth',
      realm: $authorization.realm,
      clientId: $authorization.keycloakClientId
    }

    $authorization.keycloak = Keycloak(options)
    const auth = await $authorization.keycloak.init({ onLoad: 'login-required', promiseType: 'native' })

    $logger.log('authenticated', auth)
    if (!auth) {
      window.location.reload()
    }

    const userProfile = await $authorization.keycloak.loadUserProfile()

    $logger.debug('user profile', userProfile)
    $logger.debug('tokenParsed', $authorization.keycloak.tokenParsed)

    await $authorization.refreshUser(false)

    $authorization.user.authenticated = true
    if ($authorization.keycloakAuthorizationHandler) {
      $authorization.user.authorized = $authorization.keycloakAuthorizationHandler($authorization.user)
    } else {
      $authorization.user.authorized = true
    }

    $authorization.accessToken = $authorization.keycloak.token

    setInterval(() => {
      $authorization.keycloak.updateToken(70).then((refreshed) => {
        if (refreshed) {
          $logger.info('access token refreshed')
          $authorization.accessToken = $authorization.keycloak.token
        } else {
          $logger.info('access token valid for ' + Math.round($authorization.keycloak.tokenParsed.exp + $authorization.keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
        }
      }).catch(() => {
        console.error('failed to refresh access token')
        $authorization.logout()
      })
    }, 30000)
  },

  async refreshUser (refreshAccessToken = true) {
    const userProfile = await $authorization.keycloak.loadUserProfile()
    $authorization.user.userId = userProfile.username
    $authorization.user.firstName = userProfile.firstName
    $authorization.user.lastName = userProfile.lastName
    $authorization.user.name = userProfile.firstName + ' ' + userProfile.lastName
    $authorization.user.initials = userProfile.firstName.charAt(0).toUpperCase() + userProfile.lastName.charAt(0).toUpperCase()
    $authorization.user.attributes = userProfile.attributes || []
    $authorization.user.roles = $authorization.keycloak.tokenParsed.realm_access.roles || []
    $authorization.user.locale = $authorization.keycloak.tokenParsed.locale
    $authorization.user.timezone = $authorization.keycloak.tokenParsed.timezone

    $i18n.setLocale($authorization.user.locale || navigator.language)

    if (refreshAccessToken) {
      await $authorization.keycloak.updateToken(10000)
      $authorization.accessToken = $authorization.keycloak.token
    }
  },

  async getAuthorizationHeader () {
    if ($authorization.keycloak) {
      return `Bearer ${$authorization.accessToken}`
    }

    if ($authorization.technicalUser) {
      if ($authorization.accessToken && moment().isBefore(moment($authorization.accessTokenValidity))) {
        return `Bearer ${$authorization.accessToken}`
      } else {
        const refresh = $authorization.accessToken && moment().isBefore(moment($authorization.refreshTokenValidity))
        const fetchBody = refresh
          ? `grant_type=refresh_token&client_id=${$authorization.keycloakClientId}&client_secret=${$authorization.keycloakClientSecret}&refresh_token=${$authorization.refreshToken}`
          : `username=${$authorization.technicalUser}&password=${$authorization.technicalPassword}&grant_type=password&client_id=${$authorization.keycloakClientId}&client_secret=${$authorization.keycloakClientSecret}`

        try {
          const response = await fetch(
            `${$authorization.keycloakUrl}/auth/realms/${$authorization.realm}/protocol/openid-connect/token`,
            {
              method: 'post',
              body: fetchBody,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          )

          const responseJson = await response.json()
          $authorization.accessToken = responseJson.access_token
          $authorization.accessTokenValidity = moment().add(responseJson.expires_in - 30, 'seconds').toISOString()
          $authorization.refreshToken = responseJson.refresh_token
          $authorization.refreshTokenValidity = moment().add(responseJson.refresh_expires_in - 30, 'seconds').toISOString()
          return `Bearer ${$authorization.accessToken}`
        } catch (error) {
          $logger.error('error getting access token', error)
        }
      }
    }
  },

  account () {
    if ($authorization.keycloak) {
      $authorization.keycloak.accountManagement()
    }
  },

  logout () {
    if ($authorization.keycloak) {
      $authorization.keycloak.logout()
    }
  },

  handleUnauthorized () {
    if ($authorization.keycloak) {
      $authorization.keycloak.logout()
    }
    if ($authorization.technicalUser) {
      $authorization.accessToken = null
    }
  }
})

export default $authorization
