<template>
  <div class="chart">
    <div class="d-flex justify-center">
      <span>{{ $t(title) }}</span>
    </div>

    <svg
        :viewBox="`0 0 ${viewBoxWidth} ${viewBoxHeight}`"
        preserveAspectRatio="xMidYMid meet"
        ref="svg"
        v-resize="handleResize"
    >

      <circle
          v-for="point in points"
          :key="point.key"
          :cx="point.cx"
          :cy="point.cy"
          r="2"
          class="primary-shape"
      />

      <!-- add 0.0001 to fix chrome bug when printing -->
      <line
          v-for="line in lines"
          :key="line.key"
          :x1="line.x1"
          :y1="line.y1"
          :x2="line.x2"
          :y2="line.y2 + 0.0001"
          stroke-width="2"
          class="primary-shape"
      />

      <text
          v-for="labelText in labelTexts"
          :key="labelText.key"
          :x="labelText.x"
          :y="labelText.y"
          v-text="labelText.text"
      />

      <text
          v-for="valueText in valueTexts"
          :key="valueText.key"
          :x="valueText.x"
          :y="valueText.y"
          v-text="valueText.text"
          class="primary-text"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    labels: {
      type: Array,
      default: () => []
    },
    values: {
      type: Array,
      default: () => []
    },
    valueType: {
      type: String
    }
  },

  data () {
    return {
      viewBoxWidth: 800,
      viewBoxHeight: 200,
      chartWidth: 700,
      chartHeight: 100,
      xPadding: 50,
      yPadding: 50
    }
  },

  created () {
    setTimeout(() => {
      this.handleResize()
    }, 0)
  },

  computed: {
    points () {
      return this.values.map((value, index) => {
        return {
          key: `points.${index}`,
          cx: this.calculateX(index),
          cy: this.calculateY(value)
        }
      })
    },

    lines () {
      const lines = []
      let currentLine = null
      this.values.forEach((value, index) => {
        const x = this.calculateX(index)
        const y = this.calculateY(value)
        if (currentLine) {
          currentLine.x2 = x
          currentLine.y2 = y
          lines.push(currentLine)
        }
        currentLine = {
          key: `lines.${index}`,
          x1: x,
          y1: y
        }
      })
      return lines
    },

    labelTexts () {
      return this.labels.map((label, index) => {
        return {
          key: `labelTexts.${index}`,
          x: this.calculateX(index),
          y: this.chartHeight + this.yPadding + 25,
          text: this.$t(label)
        }
      })
    },

    valueTexts () {
      return this.values.map((value, index) => {
        return {
          key: `valueTexts.${index}`,
          x: this.calculateX(index),
          y: this.calculateY(value) - 20,
          text: this.formatValue(value)
        }
      })
    },

    valueRange () {
      const filteredValues = this.values.filter(value => !!value)
      if (filteredValues) {
        const maxValue = Math.max(0, ...filteredValues)
        const minValue = Math.min(0, ...filteredValues)
        return maxValue + Math.abs(minValue)
      }
      return 0
    },

    negativeValueOffset () {
      const filteredValues = this.values.filter(value => !!value)
      if (filteredValues) {
        const minValue = Math.min(0, ...filteredValues)
        return Math.abs(minValue)
      }
      return 0
    },

    maxLength () {
      return Math.max(this.labels.length, this.values.length)
    }
  },

  methods: {
    calculateX (index) {
      return (index * this.chartWidth / (this.maxLength - 1)) + this.xPadding
    },

    calculateY (value) {
      if (this.valueRange) {
        const negativeAdaptedValue = value + this.negativeValueOffset
        const chartOffsetValue = this.chartHeight * negativeAdaptedValue / this.valueRange
        return this.chartHeight - chartOffsetValue + this.yPadding
      }
      return this.chartHeight + this.yPadding
    },

    formatValue (value) {
      if (this.valueType === 'amount') {
        return this.$formatter.formatAmount(value)
      }
      return value
    },

    handleResize () {
      const minChartWidth = this.maxLength * 75
      this.chartWidth = this.$refs.svg.clientWidth < minChartWidth ? minChartWidth : this.$refs.svg.clientWidth
      this.chartHeight = this.chartWidth / 20

      this.viewBoxWidth = this.chartWidth + (this.xPadding * 2)
      this.viewBoxHeight = this.chartHeight + (this.yPadding * 2)
    }
  }
}
</script>

<style scoped>
  .chart {
    user-select: none;
  }

  text {
    text-anchor: middle;
    fill: #EEEEEE;
  }

  .primary-shape {
    stroke: #FFC045;
    fill: #FFC045;
  }

  .primary-text {
    fill: #FFC045;
  }
</style>
