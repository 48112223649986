var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-form',{ref:"form",attrs:{"form-data":_vm.settings.onlinePaymentSettings,"has-changes-handler":hasChanges => _vm.$emit('hasChanges', hasChanges),"input-fields":[
        {
          key: 'mollieApiKey',
          type: 'text'
        },
        {
          key: 'allowedForOrders',
          type: 'switch'
        },
        {
          key: 'allowedForTopups',
          type: 'switch'
        },
        {
          key: 'minimumOrderAmount',
          type: 'amount'
        },
        {
          key: 'minimumTopupAmount',
          type: 'amount'
        }
      ],"validation-handler":_vm.handleOnlinePaymentSettingsValidation}})
}
var staticRenderFns = []

export { render, staticRenderFns }