<template>
  <div>
    <t-table
      :items="[
        {
          numberOfCancellations: report.numberOfCancellations,
          totalCancelledBalance: report.totalCancelledBalance
        }
      ]"
      :item-properties="[
        {
          key: 'numberOfCancellations',
          type: 'number'
        },
        {
          key: 'totalCancelledBalance',
          header: 'label.cancelledBalance',
          type: 'amount'
        }
      ]"
      style="display: inline-block; width: 30%; vertical-align: top; min-width: 350px"
    />

    <t-table
      class="mt-8"
      :items="report.cancellations"
      :item-properties="[
        {
          key: 'dateTime',
          type: 'date-time',
          sortable: true
        },
        {
          key: 'userId',
          sortable: true
        },
        {
          key: 'cancelledBalance',
          type: 'amount',
          sortable: true
        }
      ]"
      default-sort-property-key="dateTime"
      :default-sort-ascending="false"
    />
  </div>
</template>

<script>
export default {
  props: {
    report: {
      type: Object
    }
  }
}
</script>
