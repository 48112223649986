var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-form',{ref:"form",attrs:{"form-data":_vm.settings.invoicingSettings,"has-changes-handler":hasChanges => _vm.$emit('hasChanges', hasChanges),"input-fields":[
        {
          key: 'companyName',
          type: 'text'
        },
        {
          key: 'vatNumber',
          type: 'text'
        },
        {
          key: 'street',
          type: 'text'
        },
        {
          key: 'houseNumber',
          type: 'text'
        },
        {
          key: 'postalCode',
          type: 'text'
        },
        {
          key: 'municipality',
          type: 'text'
        }
      ],"validation-handler":_vm.handleInvoicingSettingsValidation}})
}
var staticRenderFns = []

export { render, staticRenderFns }