var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"user","action-chips":[
     {
        label: 'label.cancelMembership',
        icon: 'mdi-account-remove',
        handler: user => _vm.$dialog.openDialog('user-membership-cancel', user),
        disabledHandler: user => user.tenantOwner
      }
    ],"input-fields":[
      {
        key: 'id',
        type: 'text',
        label: 'label.username',
        readOnly: true
      },
      {
        key: 'firstName',
        type: 'text',
        label: 'label.firstName',
        readOnly: true
      },
      {
        key: 'lastName',
        type: 'text',
        label: 'label.lastName',
        readOnly: true
      },
      {
        key: 'disabledForTenant',
        type: 'switch',
        label: 'label.disabled',
        required: true,
        disabledHandler: user => user.tenantOwner
      },
      {
        key: 'admin',
        type: 'switch',
        label: 'label.administrator',
        required: true,
        disabledHandler: user => user.tenantOwner
      }
    ],"item":_vm.user,"save-handler":_vm.user.save,"open-handler":_vm.onOpen,"messages":[
    {
      message: _vm.$t('message.userDisabledByTopupz'),
      color: 'error',
      image: 'mdi-account-off',
      show: _vm.user.disabled
    }
  ]}})
}
var staticRenderFns = []

export { render, staticRenderFns }