var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-edit-dialog',{attrs:{"id":"user-group","delete-handler":_vm.userGroup.delete,"input-fields":[
        {
          key: 'name',
          type: 'text',
          label: 'label.name',
          required: true
        },
        {
          key: 'userIds',
          type: 'auto-complete-list',
          label: 'label.users',
          items: _vm.userSearch.items,
          itemKey: 'id',
          itemText: 'fullName',
          itemFilteringHandler: _vm.userSearch.search,
          itemByValueHandler: _vm.userSearch.findById,
          required: true
        }
      ],"item":_vm.userGroup,"save-handler":_vm.userGroup.save,"uuid-item":true,"open-handler":_vm.onOpen}})
}
var staticRenderFns = []

export { render, staticRenderFns }