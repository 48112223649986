import Vue from 'vue'
import VueBase from './lib/vue-base'
import $authorization from './lib/authorization'

import App from './app/App'
import UserPage from './app/components/user/UserPage'
import CardPage from './app/components/card/CardPage'
import TransactionPage from './app/pages/TransactionPage'
import DevicePage from './app/components/device/DevicePage'
import ProductPage from './app/components/product/ProductPage'
import QrCodePage from './app/components/qrcode/QrCodePage'
import ReportPage from './app/pages/ReportPage'
import RewardPage from './app/components/reward/RewardPage'
import OrderPage from './app/components/order/OrderPage'
import TopupPage from './app/pages/TopupPage'
import SettingsPage from './app/components/settings/SettingsPage'
import SubscriptionPage from './app/components/subscription/SubscriptionPage'
import InvoicePage from '@/app/components/invoice/InvoicePage'
import DashboardPage from '@/app/components/dashboard/DashboardPage'

import CardDialog from '@/app/components/card/CardDialog'
import CardTable from '@/app/components/card/CardTable'
import CardDashboard from '@/app/components/dashboard/CardDashboard'
import DeviceDashboard from '@/app/components/dashboard/DeviceDashboard'
import OrderDashboard from '@/app/components/dashboard/OrderDashboard'
import RunningOrderDashboard from '@/app/components/dashboard/RunningOrderDashboard'
import TopupDashboard from '@/app/components/dashboard/TopupDashboard'
import UserDashboard from '@/app/components/dashboard/UserDashboard'
import DeviceDialog from '@/app/components/device/DeviceDialog'
import DeviceLinkDialog from '@/app/components/device/DeviceLinkDialog'
import DeviceLinkProcessDialog from '@/app/components/device/DeviceLinkProcessDialog'
import DeviceQrLinkDialog from '@/app/components/device/DeviceQrLinkDialog'
import DeviceTable from '@/app/components/device/DeviceTable'
import InvoiceDialog from '@/app/components/invoice/InvoiceDialog'
import InvoicePaymentDialog from '@/app/components/invoice/InvoicePaymentDialog'
import InvoiceTable from '@/app/components/invoice/InvoiceTable'
import OrderDialog from '@/app/components/order/OrderDialog'
import OrderTable from '@/app/components/order/OrderTable'
import ProductCategoryChips from '@/app/components/product/ProductCategoryChips'
import ProductCategoryDialog from '@/app/components/product/ProductCategoryDialog'
import ProductDialog from '@/app/components/product/ProductDialog'
import ProductTable from '@/app/components/product/ProductTable'
import QrCodeDialog from '@/app/components/qrcode/QrCodeDialog'
import QrCodeTable from '@/app/components/qrcode/QrCodeTable'
import RewardDefinitionDialog from '@/app/components/reward/RewardDefinitionDialog'
import RewardDefinitionTable from '@/app/components/reward/RewardDefinitionTable'
import RewardDialog from '@/app/components/reward/RewardDialog'
import RewardTable from '@/app/components/reward/RewardTable'
import GeneralSettingsForm from '@/app/components/settings/GeneralSettingsForm'
import InvoicingSettingsDialog from '@/app/components/settings/InvoicingSettingsDialog'
import InvoicingSettingsForm from '@/app/components/settings/InvoicingSettingsForm'
import OnlineOrderSettingsForm from '@/app/components/settings/OnlineOrderSettingsForm'
import OnlinePaymentSettingsForm from '@/app/components/settings/OnlinePaymentSettingsForm'
import PaidSubscriptionConfirmDialog from '@/app/components/subscription/PaidSubscriptionConfirmDialog'
import SubscriptionDialog from '@/app/components/subscription/SubscriptionDialog'
import SubscriptionPeriodTable from '@/app/components/subscription/SubscriptionPeriodTable'
import SubscriptionTable from '@/app/components/subscription/SubscriptionTable'
import TenantSelectionDialog from '@/app/components/tenant/TenantSelectionDialog'
import UserDialog from '@/app/components/user/UserDialog'
import UserGroupDialog from '@/app/components/user/UserGroupDialog'
import UserGroupSelectDialog from '@/app/components/user/UserGroupSelectDialog'
import UserGroupTable from '@/app/components/user/UserGroupTable'
import UserMembershipCancelDialog from '@/app/components/user/UserMembershipCancelDialog'
import UserMembershipRequestDialog from '@/app/components/user/UserMembershipRequestDialog'
import UserMembershipRequestTable from '@/app/components/user/UserMembershipRequestTable'
import UserTable from '@/app/components/user/UserTable'

Vue.component('CardDialog', CardDialog)
Vue.component('CardTable', CardTable)
Vue.component('CardDashboard', CardDashboard)
Vue.component('DeviceDashboard', DeviceDashboard)
Vue.component('OrderDashboard', OrderDashboard)
Vue.component('RunningOrderDashboard', RunningOrderDashboard)
Vue.component('TopupDashboard', TopupDashboard)
Vue.component('UserDashboard', UserDashboard)
Vue.component('DeviceDialog', DeviceDialog)
Vue.component('DeviceLinkDialog', DeviceLinkDialog)
Vue.component('DeviceLinkProcessDialog', DeviceLinkProcessDialog)
Vue.component('DeviceQrLinkDialog', DeviceQrLinkDialog)
Vue.component('DeviceTable', DeviceTable)
Vue.component('InvoiceDialog', InvoiceDialog)
Vue.component('InvoicePaymentDialog', InvoicePaymentDialog)
Vue.component('InvoiceTable', InvoiceTable)
Vue.component('OrderDialog', OrderDialog)
Vue.component('OrderTable', OrderTable)
Vue.component('ProductCategoryChips', ProductCategoryChips)
Vue.component('ProductCategoryDialog', ProductCategoryDialog)
Vue.component('ProductDialog', ProductDialog)
Vue.component('ProductTable', ProductTable)
Vue.component('QrCodeDialog', QrCodeDialog)
Vue.component('QrCodeTable', QrCodeTable)
Vue.component('RewardDefinitionDialog', RewardDefinitionDialog)
Vue.component('RewardDefinitionTable', RewardDefinitionTable)
Vue.component('RewardDialog', RewardDialog)
Vue.component('RewardTable', RewardTable)
Vue.component('GeneralSettingsForm', GeneralSettingsForm)
Vue.component('InvoicingSettingsDialog', InvoicingSettingsDialog)
Vue.component('InvoicingSettingsForm', InvoicingSettingsForm)
Vue.component('OnlineOrderSettingsForm', OnlineOrderSettingsForm)
Vue.component('OnlinePaymentSettingsForm', OnlinePaymentSettingsForm)
Vue.component('PaidSubscriptionConfirmDialog', PaidSubscriptionConfirmDialog)
Vue.component('SubscriptionDialog', SubscriptionDialog)
Vue.component('SubscriptionPeriodTable', SubscriptionPeriodTable)
Vue.component('SubscriptionTable', SubscriptionTable)
Vue.component('TenantSelectionDialog', TenantSelectionDialog)
Vue.component('UserDialog', UserDialog)
Vue.component('UserGroupDialog', UserGroupDialog)
Vue.component('UserGroupSelectDialog', UserGroupSelectDialog)
Vue.component('UserGroupTable', UserGroupTable)
Vue.component('UserMembershipCancelDialog', UserMembershipCancelDialog)
Vue.component('UserMembershipRequestDialog', UserMembershipRequestDialog)
Vue.component('UserMembershipRequestTable', UserMembershipRequestTable)
Vue.component('UserTable', UserTable)

$authorization.keycloakUrl = process.env.VUE_APP_KEYCLOAK_URL
$authorization.realm = 'topupz'
$authorization.keycloakClientId = 'tenant-webapp'

$authorization.authenticateUser().then(() => {
  VueBase.init({
    app: App,
    routes: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardPage
      },
      {
        path: '/users',
        name: 'users',
        component: UserPage
      },
      {
        path: '/cards',
        name: 'cards',
        component: CardPage
      },
      {
        path: '/transactions',
        name: 'transactions',
        component: TransactionPage
      },
      {
        path: '/devices',
        name: 'devices',
        component: DevicePage
      },
      {
        path: '/products',
        name: 'products',
        component: ProductPage
      },
      {
        path: '/reports',
        name: 'reports',
        component: ReportPage
      },
      {
        path: '/rewards',
        name: 'rewards',
        component: RewardPage
      },
      {
        path: '/orders',
        name: 'orders',
        component: OrderPage
      },
      {
        path: '/topups',
        name: 'topups',
        component: TopupPage
      },
      {
        path: '/settings',
        name: 'settings',
        component: SettingsPage
      },
      {
        path: '/subscription',
        name: 'subscription',
        component: SubscriptionPage
      },
      {
        path: '/invoices',
        name: 'invoices',
        component: InvoicePage
      },
      {
        path: '/qr-codes',
        name: 'qr-codes',
        component: QrCodePage
      }
    ]
  })
})
