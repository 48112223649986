import Vue from 'vue'
import $fetcher from '@/lib/fetcher'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useLoader from '@/lib/composables/useLoader'
import useRewardDefinition from '@/app/composables/reward/useRewardDefinition'

const tenantSelection = useTenantSelection()

export default function () {
  const rewardDefinitionList = Vue.observable({
    loader: useLoader(),
    rewardDefinitions: [],
    fetch: null
  })

  rewardDefinitionList.setData = data => {
    rewardDefinitionList.rewardDefinitions = data.map(cardData => {
      const rewardDefinition = useRewardDefinition()
      rewardDefinition.setData(cardData)
      return rewardDefinition
    })
  }

  rewardDefinitionList.load = async () => {
    await rewardDefinitionList.loader.load(async background => {
      if (background) {
        await rewardDefinitionList.fetch.executeFetch()
      } else {
        rewardDefinitionList.fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/reward-definitions`)
        await rewardDefinitionList.fetch.promise
      }
      rewardDefinitionList.setData(rewardDefinitionList.fetch.data)
    })
  }

  return rewardDefinitionList
}
