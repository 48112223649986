<template>
  <t-page>
    <t-content-card
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: () => $dialog.openDialog('reward-definition')
        }
      ]"
      :fetch="rewardDefinitionList.fetch"
      title="label.commonlyUsedDefinitions"
    >
      <reward-definition-table :reward-definition-list="rewardDefinitionList" />
    </t-content-card>

    <t-content-card
      :actions="[
        {
          icon: 'mdi-plus-circle',
          handler: reward => $dialog.openDialog('reward', reward)
        }
      ]"
      :fetch="rewardSearch.fetch"
      title="label.rewards"
    >
      <reward-table :reward-search="rewardSearch"/>
    </t-content-card>
  </t-page>
</template>

<script>
import useRewardSearch from '@/app/composables/reward/useRewardSearch'
import useRewardDefinitionList from '@/app/composables/reward/useRewardDefinitionList'
import useEvent from '@/lib/composables/useEvent'
import useRefresh from '@/lib/composables/useRefresh'

export default {
  data () {
    return {
      rewardSearch: useRewardSearch(),
      rewardDefinitionList: useRewardDefinitionList()
    }
  },

  created () {
    this.rewardDefinitionList.load()
    useEvent().registerListener('reward-page', ['reward-definition-save', 'reward-definition-delete'], () => {
      this.rewardDefinitionList.loader.refresh()
    })
    useEvent().registerListener('reward-page', ['reward-save', 'reward-assign', 'reward-delete'], () => {
      this.rewardSearch.loader.refresh()
    })
    useRefresh().registerListener('reward-page', () => {
      this.rewardSearch.loader.backgroundRefresh()
      this.rewardDefinitionList.loader.backgroundRefresh()
    })
  },

  destroyed () {
    useEvent().unregisterListener('reward-page')
    useRefresh().unregisterListener('reward-page')
  }
}
</script>
