import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import $sse from '@/lib/sse'
import useEntity from '@/lib/composables/useEntity'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'

const tenantSelection = useTenantSelection()

export default function () {
  const invoiceTemplate = {
    id: null,
    tenantId: null,
    number: null,
    date: null,
    description: null,
    paid: null,
    lines: [],
    processSse: null,
    process: null,
    amountIncludingVat: null,
    amountExcludingVat: null
  }

  const invoice = useEntity(invoiceTemplate)

  invoice.refresh = async () => {
    if (invoice.id) {
      return invoice.findById(invoice.id)
    }
  }

  invoice.findById = async id => {
    const fetch = $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/invoices/${id}`)
    await fetch.promise
    invoice.setData(fetch.data)
  }

  invoice.downloadDocument = () => {
    $fetcher.fetch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/invoices/${invoice.id}/document`, {
      bodyType: 'file'
    })
  }

  invoice.startProcessSse = () => {
    invoice.stopProcessSse()
    invoice.processSse = $sse.sse(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/invoices/${invoice.id}/events`, {
      onMessage (message) {
        invoice.process = message
        if (invoice.process.status === 'WAITING_FOR_PAYMENT') {
          invoice.pay()
        }
        if (invoice.process.status === 'PAID') {
          useEvent().fire('invoice-paid', invoice)
        }
      }
    })
    return invoice
  }

  invoice.pay = async () => {
    $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/invoices/${invoice.id}/pay`, {
      body: {
        redirectUrl: `${window.location.origin}/invoices?invoicePaymentId=${invoice.id}`
      }
    })
  }

  invoice.stopProcessSse = () => {
    invoice.processSse && invoice.processSse.close()
  }

  invoice.checkout = () => {
    window.location = invoice.process.checkoutUrl
  }

  return invoice
}
