var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-page',[_c('t-content-card',{attrs:{"title":"label.topups","fetch":_vm.topupFetch}},[_c('t-table',{attrs:{"items":_vm.topupFetch,"item-key":"id","item-properties":[
        {
          key: 'dateTime',
          type: 'date-time',
          sortable: true
        },
        {
          key: 'amount',
          type: 'amount',
          sortable: true
        },
        {
          key: 'paymentMethod',
          type: 'icon',
          icon: topup => {
            switch (topup.paymentMethod) {
              case 'CASH': return {
                image: 'mdi-account-cash',
                tooltip: 'label.cashPayment'
              }
              case 'BANCONTACT': return {
                image: 't-bancontact',
                tooltip: 'label.bancontactPayment'
              }
            }
          }
        },
        {
          key: 'userId',
          sortable: true
        },
        {
          key: 'initiator',
          type: 'icon',
          icon: topup => {
            switch (topup.initiator) {
              case 'CASHIER': return {
                image: 'mdi-cash-register',
                tooltip: 'label.cashier'
              }
              case 'SCANNER': return {
                image: 'mdi-credit-card-scan',
                tooltip: 'label.scanner'
              }
              case 'USER': return {
                image: 'mdi-account',
                tooltip: 'label.user'
              }
            }
          }
        },
      ],"search":true,"item-filtering-handler":_vm.handleTopupFiltering,"default-sort-property-key":"dateTime","default-sort-ascending":false,"no-items-label":"message.noTopups"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }