import Vue from 'vue'
import useLoader from '@/lib/composables/useLoader'
import $authorization from '@/lib/authorization'
import useFetch from '@/lib/composables/useFetch'

export default function (url, dataMapper) {
  const search = Vue.observable({
    loader: useLoader(),
    items: [],
    page: null,
    pageCount: null,
    totalItemCount: null
  })

  search.search = async (filter) => {
    await search.loader.load(async () => {
      let searchUrl = url
      if (filter) {
        searchUrl += `${searchUrl.includes('?') ? '&' : '?'}page=${filter.page}`
        searchUrl += `&sortProperty=${filter.sortPropertyKey}`
        searchUrl += `&sortAscending=${filter.sortAscending}`
        searchUrl += `&searchText=${filter.searchText}`
        searchUrl += `&searchTimezone=${$authorization.user.timezone}`
      }

      const pageResponse = await useFetch().get(searchUrl)
      if (pageResponse) {
        if (dataMapper) {
          search.items = await Promise.all(pageResponse.data.map(async item => {
            return await dataMapper(item)
          }))
        } else {
          search.items = pageResponse.data.map(item => dataMapper(item))
        }

        search.page = pageResponse.page
        search.pageCount = pageResponse.pageCount
        search.totalItemCount = pageResponse.totalItemCount
      } else {
        search.items = []
        search.page = 0
        search.pageCount = 0
        search.totalItemCount = 0
      }
    })
    return search.items
  }

  search.loadEmpty = async () => {
    await search.loader.load(() => {
      search.items = []
      search.page = 0
      search.pageCount = 0
      search.totalItemCount = 0
    })
  }

  search.refresh = async () => {
    return search.loader.refresh()
  }

  search.backgroundRefresh = async () => {
    return search.loader.backgroundRefresh()
  }

  return search
}
