<template>
  <t-form
    ref="form"
    :form-data="settings.generalSettings"
    :has-changes-handler="hasChanges => $emit('hasChanges', hasChanges)"
    :input-fields="[
          {
            key: 'language',
            type: 'select',
            items: [
              {
                value: 'nl',
                text: $t('label.dutch')
              },
              {
                value: 'fr',
                text: $t('label.french')
              },
              {
                value: 'en',
                text: $t('label.english')
              }
            ],
            itemKey: 'value',
            itemText: 'text'
          }
        ]"
  />
</template>

<script>
export default {
  props: {
    settings: {
      required: true
    }
  },

  computed: {
    editFormData () {
      return this.$refs.form && this.$refs.form.editFormData
    }
  },

  methods: {
    validate () {
      return this.$refs.form.validate()
    },

    reset () {
      return this.$refs.form.reset()
    }
  }
}
</script>
