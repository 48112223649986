import Vue from 'vue'

const logoSize = 128
const logoRadius = 10

const $canvas = Vue.observable({

  createTextLogo (text) {
    const canvas = $canvas.getLogoCanvas()
    const ctx = canvas.getContext('2d')

    ctx.fillStyle = 'black'
    ctx.fillRect(0, 0, logoSize, logoSize)

    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.font = 'bold ' + (logoSize / 5.5) + 'px arial'
    ctx.fillStyle = 'white'

    if (text.length > 9) {
      const spaceSplitNames = text.split(' ')

      let maxLength = 0
      spaceSplitNames.forEach((spaceSplitName) => {
        maxLength = spaceSplitName.length > maxLength ? spaceSplitName.length : maxLength
      })

      if (maxLength > 9) {
        ctx.font = 'bold ' + (logoSize / 7) + 'px arial'
      }

      if (spaceSplitNames.length === 1) {
        ctx.fillText(text, logoSize / 2, logoSize / 2)
      } else if (spaceSplitNames.length === 2) {
        ctx.fillText(spaceSplitNames[0], logoSize / 2, logoSize / 3)
        ctx.fillText(spaceSplitNames[1], logoSize / 2, logoSize / 3 * 2)
      } else {
        ctx.fillText(spaceSplitNames[0], logoSize / 2, logoSize / 4)
        ctx.fillText(spaceSplitNames[1], logoSize / 2, logoSize / 2)
        ctx.fillText(spaceSplitNames[2], logoSize / 2, logoSize / 4 * 3)
      }
    } else {
      ctx.fillText(text, logoSize / 2, logoSize / 2)
    }

    return canvas.toDataURL()
  },

  createLogo (data) {
    return new Promise(resolve => {
      const canvas = $canvas.getLogoCanvas()
      const ctx = canvas.getContext('2d')

      const image = new Image()
      image.onload = () => {
        ctx.drawImage(image, 10, 10, image.width - 20, image.height - 20, 0, 0, logoSize, logoSize)
        resolve(canvas.toDataURL())
      }
      image.onerror = () => {
        resolve(null)
      }
      image.src = data
    })
  },

  getLogoCanvas () {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    canvas.width = logoSize
    canvas.height = logoSize

    ctx.beginPath()
    ctx.moveTo(logoRadius, 0)
    ctx.lineTo(logoSize - logoRadius, 0)
    ctx.quadraticCurveTo(logoSize, 0, logoSize, logoRadius)
    ctx.lineTo(logoSize, logoSize - logoRadius)
    ctx.quadraticCurveTo(logoSize, logoSize, logoSize - logoRadius, logoSize)
    ctx.lineTo(logoRadius, logoSize)
    ctx.quadraticCurveTo(0, logoSize, 0, logoSize - logoRadius)
    ctx.lineTo(0, logoRadius)
    ctx.quadraticCurveTo(0, 0, logoRadius, 0)
    ctx.closePath()
    ctx.clip()

    return canvas
  }
})

export default $canvas
