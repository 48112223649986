<template>
  <v-card flat class="mb-2">
    <v-card-title
        style="margin-bottom: 0; padding-bottom: 0"
    >
      <span
          class="mr-2 color-primary"
          @click="toggleCollapsed"
          v-text="$t(title)"
          :class="{ 'cursor-pointer': collapsible }"
          style="user-select: none"
      />

      <v-menu
          v-model="retryFetchMenu"
          :open-on-hover="true"
          v-if="showFetchError"
      >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="my-2" color="warning">mdi-alert</v-icon>
        </template>

        <v-sheet color="warning" class="d-flex flex-column pa-4">
          <span class="mb-2">An error occurred while retrieving data</span>
          <v-btn @click="handleErrorFetchRetry">Retry</v-btn>
        </v-sheet>
      </v-menu>

      <t-icon
          v-if="collapsible"
          color="primary"
          image="mdi-chevron-up"
          :click-handler="toggleCollapsed"
          :style="collapsed ? 'transform: rotate(180deg);' : ''"
          style="transition: all 250ms ease-in-out;"
      />

      <div
          v-if="!collapsed"
      >
        <t-icon
            v-for="action in actions"
            :key="action.icon"
            :image="action.icon"
            :disabled="action.disabledHandler ? action.disabledHandler() : false"
            :color="action.color"
            v-show="action.showHandler ? action.showHandler() : true"
            :click-handler="action.handler"
            class="ml-1"
        />
      </div>
    </v-card-title>

    <v-card-subtitle
        v-if="subtitle"
        class="mt-2"
    >
      <div style="white-space: pre-wrap">{{$t(subtitle)}}</div>
    </v-card-subtitle>

    <div class="py-2" style="height: 2px">
      <v-progress-linear v-if="showRefreshBar" :height="2" indeterminate color="primary"/>
    </div>

    <v-card-text
        style="padding-top: 0"
        v-if="!collapsed && showLoading"
    >
      <div class="fill-height d-flex justify-center align-center">
        <div class="d-flex flex-column justify-center align-center">
          <v-progress-circular class="my-10" color="primary" :size="56" indeterminate/>
        </div>
      </div>
    </v-card-text>

    <transition name="t-content-card-slide">
      <div v-show="showContent && !collapsed">
        <div class="py-2 px-4">
          <slot/>
        </div>
      </div>
    </transition>

  </v-card>
</template>

<script>
import fetchContainer from '../../mixins/fetchContainer'

export default {
  mixins: [
    fetchContainer
  ],

  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    actions: {
      type: Array,
      default: () => []
    },
    collapsible: {
      type: Boolean,
      default: false
    },
    defaultCollapsed: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      retryFetchMenu: null,
      collapsed: this.collapsible ? this.defaultCollapsed : false
    }
  },

  methods: {
    toggleCollapsed () {
      if (this.collapsible) {
        this.collapsed = !this.collapsed
      }
    }
  }
}
</script>

<style>
/*!* Enter and leave animations can use different *!*/
/*!* durations and timing functions.              *!*/
/*.t-content-card-slide-enter-active {*/
/*  transition: all 250ms ease;*/
/*}*/
/*.t-content-card-slide-leave-active {*/
/*  transition: all 250ms ease;*/
/*}*/
/*.t-content-card-slide-enter,*/
/*.t-content-card-slide-leave-to {*/
/*  opacity: 0;*/
/*  height: 0;*/
/*  overflow: hidden;*/
/*}*/

.t-content-card-slide-enter-active {
  animation: t-content-card-slide-animation 250ms;
}
.t-content-card-slide-leave-active {
  animation: t-content-card-slide-animation 100ms reverse;
}
@keyframes t-content-card-slide-animation {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 100%;
    opacity: 1;
  }
}

/*.t-content-card-slide-enter-active {*/
/*  !*height: 50px;*!*/
/*  overflow: hidden;*/
/*}*/

/*.t-content-card-slide-leave-active {*/
/*  !*height: 100px;*!*/
/*  overflow: hidden;*/
/*}*/

/*.t-content-card-slide-enter-active,*/
/*.t-content-card-slide-leave-active {*/
/*  transition: all 200ms ease-in-out;*/
/*}*/

/*.t-content-card-slide-enter,*/
/*.t-content-card-slide-leave-to {*/
/*  !*height: 0;*!*/
/*  opacity: 0;*/
/*}*/

/*.details-expanded {*/
/*  max-height: 300px; !* try to guess a max-height for your content *!*/
/*}*/

/*.details-collapsed {*/
/*  height: auto;*/
/*  max-height: 0;*/
/*  transition: max-height 500ms linear; !* pick a proportional duration *!*/
/*}*/
</style>
