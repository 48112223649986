<template>
  <t-edit-dialog
    id="device"
    :delete-handler="device.delete"
    :input-fields="[
          {
            key: 'name',
            type: 'text',
            label: 'label.name',
            required: true
          },
          {
            key: 'type',
            type: 'select',
            label: 'label.type',
            items: [
              {
                type: 'SCANNER',
                typeLabel: $t('label.scanner')
              },
              {
                type: 'CASHIER',
                typeLabel: $t('label.cashier')
              }
            ],
            itemValue: 'type',
            itemText: 'typeLabel',
            readOnly: true
          },
          {
            key: 'model',
            type: 'text',
            label: 'label.model',
            readOnly: true
          },
          {
            key: 'applicationVersion',
            type: 'text',
            label: 'label.applicationVersion',
            readOnly: true
          },
          {
            key: 'pincode',
            type: 'text',
            label: 'label.pincode',
            required: true,
            numeric: true
          },
          {
            key: 'tenantShortName',
            type: 'text',
            label: 'label.shortenedClubName',
            required: true
          },
          {
            key: 'locale',
            type: 'select',
            label: 'label.locale',
            items: [
              {
                name: $t('label.dutch'),
                locale: 'nl'
              },
              {
                name: $t('label.french'),
                locale: 'fr'
              },
              {
                name: $t('label.english'),
                locale: 'en'
              }
            ],
            itemValue: 'locale',
            itemText: 'name',
            required: true
          },
          {
            key: 'paymentQrCodeId',
            type: 'auto-complete-select',
            label: 'label.paymentQrCode',
            items: paymentQrCodeList.items,
            itemKey: 'id',
            itemText: 'description',
            visible: () => device.type === 'CASHIER'
          }
        ]"
    :item="device"
    :open-handler="onOpen"
    :save-handler="device.save"
    :uuid-item="true"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useDevice from '@/app/composables/device/useDevice'
import useQrCodeList from '@/app/composables/qrcode/useQrCodeList'

export default {
  data () {
    return {
      device: useDevice(),
      paymentQrCodeList: useQrCodeList('type=PAYMENT')
    }
  },

  created () {
    useEvent().registerListener('device-dialog', ['device-save', 'device-delete'], () => this.$dialog.closeDialog('device'))
  },

  destroyed () {
    useEvent().unregisterListener('device-dialog')
  },

  methods: {
    onOpen () {
      this.device.setData(this.$dialog.getDialogData('device'))
      this.paymentQrCodeList.list()
    }
  }
}
</script>
