var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-page',[_c('t-content-card',{attrs:{"actions":[
      {
        icon: 'mdi-lead-pencil',
        handler: () => _vm.$dialog.openDialog('subscription')
      }
    ],"fetch":_vm.subscription.fetch,"subtitle":_vm.$t('message.subscriptionPlan_' + _vm.subscription.plan),"title":"label.subscription"}},[(_vm.subscription.nextPlan)?_c('t-message',{staticClass:"mb-6",attrs:{"message":_vm.$t('message.nextPlan', {
        nextPlan: _vm.$t('label.subscriptionPlan_' + _vm.subscription.nextPlan),
        nextPlanDate: _vm.subscription.currentInvoicingEndDate
      })}}):_vm._e(),_c('subscription-table',{attrs:{"subscription":_vm.subscription}})],1),_c('t-content-card',{attrs:{"fetch":_vm.subscriptionPeriodList.fetch,"title":"label.periods"}},[_c('subscription-period-table',{attrs:{"subscriptionPeriodList":_vm.subscriptionPeriodList}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }