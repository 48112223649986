<template>
  <t-edit-dialog
    id="user"
    :action-chips="[
       {
          label: 'label.cancelMembership',
          icon: 'mdi-account-remove',
          handler: user => $dialog.openDialog('user-membership-cancel', user),
          disabledHandler: user => user.tenantOwner
        }
      ]"
    :input-fields="[
        {
          key: 'id',
          type: 'text',
          label: 'label.username',
          readOnly: true
        },
        {
          key: 'firstName',
          type: 'text',
          label: 'label.firstName',
          readOnly: true
        },
        {
          key: 'lastName',
          type: 'text',
          label: 'label.lastName',
          readOnly: true
        },
        {
          key: 'disabledForTenant',
          type: 'switch',
          label: 'label.disabled',
          required: true,
          disabledHandler: user => user.tenantOwner
        },
        {
          key: 'admin',
          type: 'switch',
          label: 'label.administrator',
          required: true,
          disabledHandler: user => user.tenantOwner
        }
      ]"
    :item="user"
    :save-handler="user.save"
    :open-handler="onOpen"
    :messages="[
      {
        message: $t('message.userDisabledByTopupz'),
        color: 'error',
        image: 'mdi-account-off',
        show: user.disabled
      }
    ]"
  />
</template>

<script>
import useUser from '@/app/composables/user/useUser'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      user: useUser()
    }
  },

  created () {
    useEvent().registerListener('user-dialog', 'user-save', () => this.$dialog.closeDialog('user'))
  },

  destroyed () {
    useEvent().unregisterListener('user-dialog')
  },

  methods: {
    onOpen () {
      this.user.setData(this.$dialog.getDialogData('user'))
    }
  }
}
</script>
