<template>
  <div class="message">
    <t-icon class="icon" :color="color" :image="image"/>
    <div class="text" v-text="message"/>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: 'mdi-information-outline'
    },
    message: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.message {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid var(--color-grey-darken-3);
  padding: 8px;
}
.message .icon {
  margin-right: 8px;
}
.message .text {
  word-break: break-word;
  font-size: 0.9rem;
}
</style>
