import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useEntity from '@/lib/composables/useEntity'

const tenantSelection = useTenantSelection()

export default function () {
  const userGroupTemplate = {
    loader: null,
    id: null,
    name: null,
    userIds: [],
    numberOfUsers: null
  }

  const userGroup = useEntity(userGroupTemplate, userGroup => {
    userGroup.numberOfUsers = userGroup.userIds.length
  })

  userGroup.save = async editUserGroup => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/user-groups/${editUserGroup.id}`, {
      body: {
        name: editUserGroup.name,
        userIds: editUserGroup.userIds
      }
    })
    if (await fetch.promise) {
      userGroup.setData(fetch.data)
      useEvent().fire('user-group-save', userGroup)
    }
  }

  userGroup.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/user-groups/${userGroup.id}`)
    if (await fetch.promise) {
      useEvent().fire('user-group-delete', userGroup)
    }
  }

  return userGroup
}
