<template>
  <div class="d-inline">
    <v-tooltip
        v-if="tooltip && !clickHandler"
        right
        nudge-top="24"
        nudge-left="8"
        color="black"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
            v-if="!imageTag"
            v-bind="attrs"
            v-on="on"
            :class="'color-' + color"
            v-text="image"
            :size="size"
        />
        <img
            v-if="imageTag"
            v-bind="attrs"
            v-on="on"
            :class="'color-' + color"
            :src="imageTagSrc"
            style="object-fit: contain; vertical-align: middle;"
            :width="size"
            :height="size"
        />
      </template>
      <span v-text="$t(tooltip)"/>
    </v-tooltip>

    <v-tooltip
        v-if="tooltip && clickHandler"
        right
        nudge-top="24"
        nudge-left="8"
        color="black"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            @click.stop="clickHandler"
            icon
            :disabled="disabled"
        >
          <v-icon
              v-if="!imageTag"
              :class="'color-' + color"
              v-text="image"
              :size="size"
          />
          <img
              v-if="imageTag"
              :class="'color-' + color"
              :src="imageTagSrc"
              style="object-fit: contain; vertical-align: middle;"
              :width="size"
              :height="size"
          />
        </v-btn>
      </template>
      <span v-text="$t(tooltip)"/>
    </v-tooltip>

    <v-icon
        v-if="!tooltip && !clickHandler && !imageTag"
        :class="'color-' + color"
        v-text="image"
        :size="size"
    />

    <img
        v-if="!tooltip && !clickHandler && imageTag"
        :class="'color-' + color"
        :src="imageTagSrc"
        style="object-fit: contain; vertical-align: middle;"
        :width="size"
        :height="size"
    />

    <v-btn
        v-if="!tooltip && clickHandler"
        @click.stop="clickHandler"
        icon
        :disabled="disabled"
    >
      <v-icon
          v-if="!imageTag"
          :class="'color-' + color"
          v-text="image"
          :size="size"
      />
      <img
          v-if="imageTag"
          :class="'color-' + color"
          :src="imageTagSrc"
          style="object-fit: contain; vertical-align: middle;"
          :width="size"
          :height="size"
      />
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    },
    clickHandler: {
      type: Function
    },
    color: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String
    },
    size: {
      type: String,
      default: '24'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    imageTag () {
      return this.image.startsWith('t-') || this.image.startsWith('data:') || this.image.startsWith('http')
    },

    imageTagSrc () {
      if (this.imageTag && this.image.startsWith('t-')) {
        let optionalSvg
        try {
          optionalSvg = require('../../assets/icons/' + this.image + '.svg')
        } catch (e) {}

        if (optionalSvg) {
          return optionalSvg
        }

        return require('../../assets/icons/' + this.image + '.png')
      }
      return this.image
    }
  }
}
</script>
