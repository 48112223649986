import $fetcher from '@/lib/fetcher'
import useEvent from '@/lib/composables/useEvent'
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useEntity from '@/lib/composables/useEntity'

const tenantSelection = useTenantSelection()

export default function () {
  const productCategoryTemplate = {
    loader: null,
    id: null,
    name: null,
    sequenceNumber: null,
    favorites: null
  }

  const productCategory = useEntity(productCategoryTemplate)

  productCategory.save = async editProductCategory => {
    const fetch = $fetcher.put(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/product-categories/${editProductCategory.id}`, {
      body: {
        name: editProductCategory.name
      }
    })
    if (await fetch.promise) {
      productCategory.setData(fetch.data)
      useEvent().fire('product-category-save', productCategory)
    }
  }

  productCategory.delete = async () => {
    const fetch = $fetcher.delete(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/product-categories/${productCategory.id}`)
    if (await fetch.promise) {
      useEvent().fire('product-category-delete', productCategory)
    }
  }

  productCategory.move = async sequenceNumber => {
    const fetch = $fetcher.post(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/product-categories/${productCategory.id}/move`, {
      body: {
        sequenceNumber
      }
    })
    await fetch.promise
    useEvent().fire('product-category-save', productCategory)
  }

  return productCategory
}
