import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useReward from '@/app/composables/reward/useReward'
import useSearch from '@/lib/composables/useSearch'

const tenantSelection = useTenantSelection()

export default function () {
  return useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/rewards`, data => {
    const reward = useReward()
    reward.setData(data)
    return reward
  })
}
