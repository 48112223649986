<template>
  <t-table
    :items="productList.products"
    item-key="id"
    :item-properties="[
          {
            key: 'favorite',
            type: 'icon',
            icon: product => product.favorite ? { image: 'mdi-heart', color: 'primary' } : 'mdi-heart-outline',
            iconClickHandler: product => product.toggleFavorite()
          },
          {
            key: 'logo',
            type: 'logo'
          },
          {
            key: 'name'
          },
          {
            key: 'price',
            type: 'amount'
          },
          {
            key: 'disabled',
            type: 'icon',
            icon: product => product.disabled ? { image: 'mdi-food-off', color: 'error' } : null
          },
          {
            header: 'label.edit',
            icon: 'mdi-lead-pencil',
            iconClickHandler: product => event.fire('product-edit', product)
          }
        ]"
    drag-data-type="product"
    :drop-data-types="['product']"
    :drop-handler="productDropHandler"
    no-items-label="message.noProducts"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'

export default {
  props: {
    productList: {
      required: true
    }
  },

  data () {
    return {
      event: useEvent()
    }
  },

  methods: {
    async productDropHandler (sourceProduct, targetProduct) {
      sourceProduct = this.$lodash.cloneDeep(sourceProduct)
      if (this.productList.favorites) {
        await sourceProduct.moveFavorite(targetProduct.favoriteSequenceNumber)
      } else {
        await sourceProduct.move(targetProduct.sequenceNumber)
      }
    }
  }
}
</script>
