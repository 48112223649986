import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useSearch from '@/lib/composables/useSearch'
import useOrder from '@/app/composables/order/useOrder'

const tenantSelection = useTenantSelection()

export default function () {
  return useSearch(`${process.env.VUE_APP_TOPUPZ_URL}/tenants/${tenantSelection.tenantId}/orders`, data => {
    const order = useOrder()
    order.setData(data)
    return order
  })
}
