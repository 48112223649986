<template>
  <v-dialog :value="$dialog.isDialogOpen(id)" persistent max-width="600px">
    <v-card v-show="$dialog.isDialogOnTop(id)">
      <v-card-title class="d-flex align-center">
        <span>{{ $t(title) }}</span>

        <v-btn class="ml-1" v-if="saveHandler" icon small @click="handleAddClick">
          <v-icon small>mdi-plus-circle</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-subtitle v-if="subtitle" style="margin-top: -8px; padding-bottom: 0.5rem">
        <span>{{ $t(subtitle) }}</span>
      </v-card-subtitle>

      <v-divider class="mb-4"/>

      <v-card-text>
        <v-list-item-group>
          <v-list-item
              v-for="listItem in listItems"
              :key="listItem.key"
          >
            <v-list-item-content @click.stop="handleItemClick(listItem.item)">
              <v-list-item-title v-text="listItem.text"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action v-if="listItem.editable">
              <v-btn
                  @click.stop="handleItemEditClick(listItem.item)"
                  @mousedown.stop
                  icon
                  small>
                <v-icon small>mdi-lead-pencil</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>

        <span v-if="listItems.length === 0 && noItemsLabel" class="font-italic">{{ $t(noItemsLabel) }}</span>
      </v-card-text>

      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn text color="warning" @click="handleCancelClick">{{ $t('label.cancel') }}</v-btn>
      </v-card-actions>
    </v-card>

    <t-edit-dialog
        :id="editDialogId"
        :item="editItem"
        :uuid-item="uuidItem"
        :input-fields="inputFields"
        :save-handler="saveHandler"
        :delete-handler="deleteHandler"
    />
  </v-dialog>
</template>

<script>
import { v4 as uuid } from 'uuid'
import itemContainer from '../../mixins/itemContainer'

export default {
  mixins: [
    itemContainer
  ],

  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    },
    itemEditable: {
      type: [Boolean, String, Function]
    },
    uuidItem: {
      type: Boolean
    },
    selectionHandler: {
      type: Function,
      required: true
    },
    saveHandler: {
      type: Function
    },
    deleteHandler: {
      type: Function
    },
    cancelHandler: {
      type: Function
    },
    inputFields: {
      type: Array,
      default: () => []
    },
    noItemsLabel: {
      type: String
    }
  },

  data () {
    return {
      editDialogId: uuid().toString(),
      editItem: null
    }
  },

  computed: {
    listItems () {
      if (!this.containerItems) {
        return []
      }

      const listItems = this.containerItems.map(containerItem => {
        return {
          ...containerItem,
          editable: (typeof this.itemEditable === 'boolean' && this.itemEditable) || (this.itemEditable && this.$items.getItemProperty(containerItem.item, this.itemEditable))
        }
      })

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.$items.sortItems(listItems, 'text')
      return listItems
    }
  },

  methods: {
    handleItemClick (item) {
      this.selectionHandler(item)
      this.$dialog.closeDialog(this.id)
    },

    handleAddClick () {
      this.editItem = null
      this.$dialog.openDialog(this.editDialogId)
    },

    handleItemEditClick (item) {
      this.editItem = item
      this.$dialog.openDialog(this.editDialogId)
    },

    handleCancelClick () {
      if (this.cancelHandler) {
        this.cancelHandler()
      }
      this.$dialog.closeDialog(this.id)
    }
  }
}
</script>

<style scoped>
  .word-wrap {
    word-break: break-word;
  }
</style>
