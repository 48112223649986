<template>
  <t-page>
    <t-content-card
      :fetch="loader"
      title="label.settings"
      class="actions"
    >
      <t-button
        :enabled="hasChanges"
        class="mr-2" label="label.save"
        primary
        @click="handleSettingsSaveClick"
      />
      <t-button
        :enabled="hasChanges"
        class="mr-2" label="label.cancel"
        warning
        @click="handleSettingsCancelClick"
      />
    </t-content-card>

    <t-content-card
      :fetch="loader"
      title="label.general"
    >
      <general-settings-form
        ref="generalSettings"
        :settings="settings"
        @hasChanges="hasChanges => generalSettingsHasChanges = hasChanges"
        style="max-width: 500px"
      />
    </t-content-card>

    <t-content-card
      :fetch="loader"
      subtitle="message.invoicingInformationRequiredForPaidServices"
      title="label.invoicing"
    >
      <invoicing-settings-form
        ref="invoicingSettings"
        :settings="settings"
        @hasChanges="hasChanges => invoicingSettingsHasChanges = hasChanges"
        style="max-width: 500px"
      />
    </t-content-card>

    <t-content-card
      :fetch="loader"
      subtitle="message.onlinePayments"
      title="label.onlinePayments"
    >
      <online-payment-settings-form
        ref="onlinePaymentSettings"
        :settings="settings"
        @hasChanges="hasChanges => onlinePaymentSettingsHasChanges = hasChanges"
        style="max-width: 500px"
      />
    </t-content-card>

    <t-content-card
      :fetch="loader"
      subtitle="message.onlineOrders"
      title="label.onlineOrders"
    >
      <online-order-settings-form
        ref="onlineOrderSettings"
        :settings="settings"
        @hasChanges="hasChanges => onlineOrderSettingsHasChanges = hasChanges"
        style="max-width: 500px"
      />
    </t-content-card>
  </t-page>
</template>

<script>
import useTenantSettings from '@/app/composables/tenant/useTenantSettings'
import useLoader from '@/lib/composables/useLoader'
import useEvent from '@/lib/composables/useEvent'

export default {
  data () {
    return {
      loader: useLoader(),
      settings: useTenantSettings(),
      generalSettingsHasChanges: false,
      invoicingSettingsHasChanges: false,
      onlinePaymentSettingsHasChanges: false,
      onlineOrderSettingsHasChanges: false,
      event: useEvent()
    }
  },

  computed: {
    hasChanges () {
      return this.generalSettingsHasChanges ||
        this.invoicingSettingsHasChanges ||
        this.onlinePaymentSettingsHasChanges ||
        this.onlineOrderSettingsHasChanges
    }
  },

  created () {
    this.loader.load(async () => {
      return this.settings.find()
    })
  },

  methods: {
    async handleSettingsSaveClick () {
      const generalSettingsValid = await this.$refs.generalSettings.validate()
      const invoicingSettingsValid = await this.$refs.invoicingSettings.validate()
      const onlinePaymentSettingsValid = await this.$refs.onlinePaymentSettings.validate()
      if (!generalSettingsValid || !invoicingSettingsValid || !onlinePaymentSettingsValid) {
        return
      }

      await this.settings.save({
        generalSettings: this.$refs.generalSettings.editFormData,
        invoicingSettings: this.$refs.invoicingSettings.editFormData,
        onlinePaymentSettings: this.$refs.onlinePaymentSettings.editFormData,
        onlineOrderSettings: this.$refs.onlineOrderSettings.editFormData
      })

      await this.loader.refresh()
    },

    handleSettingsCancelClick () {
      this.$refs.generalSettings.reset()
      this.$refs.invoicingSettings.reset()
      this.$refs.onlinePaymentSettings.reset()
      this.$refs.onlineOrderSettings.reset()
    }
  }
}
</script>

<style scoped>
.actions {
  position: sticky;
  top: 56px;
  z-index: 1;
}
</style>
