var render = function render(){var _vm=this,_c=_vm._self._c;return _c('t-content-card',{attrs:{"title":"label.users","fetch":_vm.dashboard.fetch}},[_c('t-table',{attrs:{"items":[_vm.dashboard.user],"item-key":"id","item-properties":[
          {
            key: 'users',
            type: 'number'
          },
          {
            key: 'administrators',
            type: 'number'
          },
          {
            key: 'disabled',
            type: 'number'
          }
        ],"horizontal":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }