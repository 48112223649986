import Vue from 'vue'
import $authorization from '@/lib/authorization'
import $notification from '@/lib/notification'

export default function () {
  const fetch = Vue.observable({
    success: null,
    statusCode: null,
    data: null
  })

  fetch.fetch = async (url, init, responseMapper) => {
    if (!init) {
      init = {}
    }
    if (!init.headers) {
      init.headers = {}
    }
    if (!init.headers.Authorization) {
      init.headers.Authorization = await $authorization.getAuthorizationHeader()
    }

    try {
      const response = await window.fetch(url, init)
      fetch.statusCode = response.status

      if (!response.ok) {
        fetch.success = false
        fetch.data = null
        $notification.error(response.statusText)
      } else {
        const data = await responseMapper(response)
        fetch.success = true
        fetch.data = data
        return data
      }
    } catch (error) {
      fetch.success = false
      fetch.data = null
      $notification.error(error.message)
    }
  }

  fetch.get = async (url, init) => {
    return await fetch.fetch(url, init, response => response.json())
  }

  fetch.getText = async (url, init) => {
    return await fetch.fetch(url, init, response => response.text())
  }

  fetch.post = async (url, init) => {
    init = Object.assign(init || {}, {
      method: 'POST'
    })
    return await fetch.fetch(url, init, response => response.text())
  }

  return fetch
}
