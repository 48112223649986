<template>
  <t-edit-dialog
    id="qr-code"
    :input-fields="[
          {
            label: 'label.id',
            key: 'formattedId',
            type: 'text',
            readOnly: true
          },
          {
            key: 'name',
            type: 'text',
          },
          {
            key: 'type',
            type: 'select',
            items: qrCodeType.items,
            itemKey: 'key',
            itemText: 'label',
            changeHandler: handleTypeChange
          },
          {
            label: 'label.cashier',
            key: 'cashierId',
            type: 'auto-complete-select',
            items: cashierList.items,
            itemKey: 'id',
            itemText: 'name',
            visible: cashierVisible
          }
        ]"
    :item="qrCode"
    :save-handler="qrCode.save"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useQrCode from '@/app/composables/qrcode/useQrCode'
import useQrCodeType from '@/app/composables/qrcode/useQrCodeType'
import useDeviceList from '@/app/composables/device/useDeviceList'

export default {
  data () {
    return {
      qrCode: useQrCode(),
      qrCodeType: useQrCodeType(),
      cashierList: useDeviceList(),
      cashierVisible: false
    }
  },

  created () {
    useEvent().registerListener(this, 'qr-code-edit', qrCode => {
      this.qrCode.setData(qrCode)
      this.handleTypeChange(qrCode.type)
      this.cashierList.load('type=CASHIER')
      this.$dialog.openDialog('qr-code')
    })

    useEvent().registerListener(this, ['qr-code-delete', 'qr-code-save'], () => {
      this.$dialog.closeDialog('qr-code')
    })
  },

  methods: {
    handleTypeChange (type) {
      this.cashierVisible = type === 'PAYMENT'
    }
  }
}
</script>
