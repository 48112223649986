<template>
  <t-edit-dialog
    id="user-membership-request"
    :delete-handler="membershipRequest.delete"
    :input-fields="[
          {
            key: 'userEmail',
            type: 'text',
            label: 'label.email',
            required: true,
            readOnlyForEdit: true,
            regex: 'email'
          }
        ]"
    :item="membershipRequest"
    :save-handler="onSave"
    :read-only-for-edit="true"
    :uuid-item="true"
    :open-handler="onOpen"
    :messages="[
      {
        message: $t('message.membershipRequestAlreadySent'),
        color: 'warning',
        show: membershipRequestError === 'EXISTING_REQUEST'
      },
      {
        message: $t('message.membershipRequestForMember'),
        color: 'warning',
        show: membershipRequestError === 'EXISTING_MEMBER'
      }
    ]"
  />
</template>

<script>
import useEvent from '@/lib/composables/useEvent'
import useMembershipRequest from '@/app/composables/membership/useMembershipRequest'

export default {
  data () {
    return {
      membershipRequest: useMembershipRequest(),
      membershipRequestError: null
    }
  },

  created () {
    useEvent().registerListener('user-membership-request', ['membership-request-save', 'membership-request-delete'], () => this.$dialog.closeDialog('user-membership-request'))
  },

  destroyed () {
    useEvent().unregisterListener('user-membership-request')
  },

  methods: {
    onOpen () {
      this.membershipRequestError = null
      this.membershipRequest.setData(this.$dialog.getDialogData('user-membership-request'))
    },
    async onSave (editMembershipRequest) {
      await this.membershipRequest.save(editMembershipRequest, error => {
        this.membershipRequestError = error
      })
    }
  }
}
</script>

<style scoped>

</style>
