import Vue from 'vue'

const $notification = Vue.observable({
  currentNotification: null,
  notificationQueue: [],
  shiftTimeout: null,

  success (message) {
    $notification.message(message, 'success', 2000)
  },

  error (message) {
    $notification.message(message, 'error', 4000)
  },

  message (message, type, timeout) {
    const notification = {
      message,
      type,
      timeout
    }

    $notification.notificationQueue.push(notification)
    if (!$notification.currentNotification) {
      $notification.shiftNotifications()
    }
  },

  shiftNotifications () {
    $notification.currentNotification = null
    if ($notification.notificationQueue.length) {
      $notification.currentNotification = $notification.notificationQueue.shift()
      $notification.shiftTimeout && clearTimeout($notification.shiftTimeout)
      $notification.shiftTimeout = setTimeout(() => {
        $notification.shiftNotifications()
      }, $notification.currentNotification.timeout)
    }
  }
})

export default $notification
