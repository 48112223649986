import { Fetch } from '../fetcher'

export default {
  props: {
    fetch: {
      type: [Fetch, Array, Object]
    },
    showContentOnError: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      containerFetches: [],
      contentShown: false
    }
  },

  created () {
    this.initContainerFetches()
  },

  watch: {
    fetch () {
      this.initContainerFetches()
    },

    showContent () {
      if (this.showContent) {
        this.contentShown = true
      }
    }
  },

  computed: {
    showLoading () {
      if (this.contentShown) {
        return false
      }
      if (this.containerFetches) {
        return this.containerFetches.some(fetch => (fetch.isPending && fetch.isPending()) || (fetch.loading && !fetch.loaded))
      }
      return false
    },

    showContent () {
      if (this.contentShown) {
        return true
      }
      if (this.showLoading) {
        return false
      }
      if (this.showContentOnError) {
        return true
      }
      return this.containerFetches.every(fetch => (fetch.isSuccess && fetch.isSuccess()) || fetch.loaded)
    },

    showFetchError () {
      if (this.showLoading) {
        return false
      }
      return this.containerFetches.some(fetch => fetch.isError && fetch.isError())
    },

    showRefreshBar () {
      return this.showContent && this.containerFetches.some(fetch => fetch.fetching || fetch.loading)
    }
  },

  methods: {
    initContainerFetches () {
      this.containerFetches = []

      if (!this.fetch) {
        return
      }

      if (Array.isArray(this.fetch)) {
        this.fetch.forEach(fetch => {
          this.containerFetches.push(fetch)
        })
      } else {
        this.containerFetches.push(this.fetch)
      }
    },

    handleErrorFetchRetry () {
      return this.containerFetches
        .filter(fetch => fetch.isError())
        .forEach(fetch => fetch.fetch())
    }
  }
}
